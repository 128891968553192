import { isNumber } from 'lodash-es';

export function generateCssClassForString(input: string | null): string {
	if (input == null) return 'onbekend';
	return input
		.toLowerCase()
		.replace(/^(_?)([^a-z])/, 'd-$2')
		.replace(/\W+/g, '-')
		.replace(/-$/g, '');
}

export function generateCssClassForCijfer(cijfer: number | 'onvoldoende' | 'voldoende'): string {
	if (isNumber(cijfer)) return cijfer <= 3 ? 'd-0-t-m-3' : 'd-' + cijfer;
	return cijfer;
}
