import { Component } from '@angular/core';
import { AttrPath, DataOptions, DataResponse, DataService, FilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { ToastrService } from 'ngx-toastr';
import { FactTable } from '../../services/exportable';
import { Observable } from 'rxjs';
import { maxOver, sumOver } from '../../services/aggregation';
import { getLeafA, Path } from '../../services/data-tree';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { AlternatingMode, ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { createMeasureColumn, DataRow, DataTreeTableComponent, getAlternatingRowgroup } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { DataTreeTableConfig } from '../../shared/dashboard/data-tree-table/data-tree-table-config';
import { att } from '../../services/measures';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface CijferkolommenDetailsI extends Attributes {
	is_any_laatste_gemiddelde: number;
	count_cijfers_laatste_gemiddelde: number;
}

interface CijferkolommenDetailsA extends Attributes {
	is_any_laatste_gemiddelde: number;
	count_cijfers_laatste_gemiddelde: number;
}

@Component({
	selector: 'app-cijferkolommen-details',
	templateUrl: './cijferkolommen-details.component.html',
	styleUrls: ['./cijferkolommen-details.component.scss'],
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, DataTreeTableComponent],
})
export class CijferkolommenDetailsComponent extends DataTreeTableConfig<CijferkolommenDetailsI, CijferkolommenDetailsA> {
	selectedGroups: AttrPath[] = [['ck_nm_opleiding'], ['ck_nm_vak'], ['ck_nm_periode'], ['ck_co_kolom'], ['ck_abb_kolomkop']];

	fixedBeforeGroups = 5;

	defaultFilters: FilterName[] = ['ck_nm_schooljaar', 'ck_nm_vestiging', 'ck_abb_kolomkop', 'ck_nm_opleiding', 'ck_nm_vak'];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [];

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	factTable = FactTable.cijferkolommen;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const { f, having } = this.dataService.moveToHaving([['ck_abb_kolomkop']], options);
		return this.dataService.getCijferkolommenData({ ...options, f, having });
	}

	protected singleAggregators = {
		is_any_laatste_gemiddelde: maxOver('is_any_laatste_gemiddelde'),
		count_cijfers_laatste_gemiddelde: sumOver<'count_cijfers_laatste_gemiddelde', CijferkolommenDetailsI, number>(
			'count_cijfers_laatste_gemiddelde'
		),
	};

	createMeasureColumns(
		_context: DashboardContext<
			CijferkolommenDetailsI,
			CijferkolommenDetailsA,
			DataTreeTableConfig<CijferkolommenDetailsI, CijferkolommenDetailsA>
		>
	): ColumnDef<DataRow<CijferkolommenDetailsA>>[] {
		return [
			createMeasureColumn('Aantal keer gebruikt', (path) => {
				const aantal = att<'count_cijfers_laatste_gemiddelde', CijferkolommenDetailsA>('count_cijfers_laatste_gemiddelde')(path);
				return aantal > 0 ? aantal : null;
			}),
		];
	}

	enrichTableModel(tableModel: TableModel<DataRow<CijferkolommenDetailsA>>) {
		tableModel.showFooters = false;
		tableModel.rowsClickable = false;
		tableModel.alternating = AlternatingMode.LINE;
		tableModel.getRowgroup = (rowModel: DataRow<CijferkolommenDetailsA>) => ({
			class: this.getRowClass(rowModel._path),
			...getAlternatingRowgroup(rowModel, 4),
		});
	}

	getRowClass(path: Path<CijferkolommenDetailsA, number[]>): string {
		const { is_any_laatste_gemiddelde } = getLeafA(path);
		return is_any_laatste_gemiddelde == 1 ? 'hilite' : '';
	}
}
