import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@cumlaude/shared-authentication';

@Injectable({
	providedIn: 'root',
})
export class RolGuard {
	constructor(
		private userService: UserService,
		private authService: AuthService
	) {}

	canMatch(_route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
		if (!this.authService.isLoggedIn()) {
			return of(true); // auth-guard onthoudt route en doet de redirect
		}

		return combineLatest([this.userService.myAccount$, this.userService.rolChanged$, this.userService.rVestigingen$]).pipe(
			map(() => this.userService.checkUrlForRol(`/${segments.join('/')}`))
		);
	}
}
