@if (sortHeaders.length > 0) {
	<app-card-sort-headers [sortHeaders]="sortHeaders" data-princexml-ignore data-html2canvas-ignore></app-card-sort-headers>
}
@if (vm$ | async; as vm) {
	<div class="container">
		@if (!vm.error && vm.context!.dataRoot) {
			@for (row of vm.context!.dataRoot!.r; track row) {
				<div
					class="element"
					[class]="elementPageBreak ? 'element-page' : ''"
					(click)="onCardClick(row)"
					(contextmenu)="onContextMenu($event, row, vm.context!)"
				>
					<ng-container
						[ngTemplateOutlet]="template"
						[ngTemplateOutletContext]="{
							row: row,
							context: vm.context,
							group: getGroupKeys(row, vm.context!),
							variant: vm.variant
						}"
					></ng-container>
				</div>
			}
		}
		@switch (vm.error?.error) {
			@case (ErrorEnum.LIMIT_EXCEEDED) {
				<div>
					De huidige selectie levert te veel ({{ vm.error!.parameters!.leaves }}) resultaten op. Voeg filters toe om deze te beperken tot
					minder dan {{ vm.error!.parameters!.limit }}.
				</div>
			}
			@case (ErrorEnum.UNKNOWN) {
				<div>Er is een onverwachte fout opgetreden.</div>
			}
			@case (ErrorEnum.MISSING_MODULE) {
				<div>
					Deze school heeft module {{ vm.error!.parameters!.module }} niet afgenomen. Neem contact op met
					<a href="mailto:{{ envConfig.supportMail }}">CumLaude Support</a> om de mogelijkheden te bespreken.
				</div>
			}
			@case (ErrorEnum.MISSING_ROLE) {
				<div>
					Je kunt de gegevens niet opvragen als {{ vm.error!.parameters!.rol }} omdat je deze rol niet (meer) hebt. Neem contact op met de
					beheerder van CumLaude op je school.
				</div>
			}
			@case (ErrorEnum.NO_ACCESS) {
				<div>Je hebt geen toegang tot de gevraagde data. Neem contact op met de beheerder van CumLaude op je school.</div>
			}
		}
		@if (!vm.error && !vm.context!.dataRoot) {
			<div>De huidige selectie levert geen resultaten op.</div>
		}
	</div>
}
