import { Component, Input } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';

export interface TooltipElement {
	label: string;
	value: string;
	bold?: boolean;
}

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	standalone: true,
	imports: [OverlayModule, TooltipDirective],
})
export class TooltipComponent {
	@Input()
	singleColumnTooltip?: string[];

	@Input()
	multiColumnTooltip?: TooltipElement[];
}
