/* tslint:disable */
/* eslint-disable */
export enum Uitstroom {
  INTERN = 'Intern',
  INTERN_TUSSENTIJDS = 'Intern (tussentijds)',
  EXTERN = 'Extern',
  EXTERN_TUSSENTIJDS = 'Extern (tussentijds)',
  VAVO = 'VAVO',
  VAVO_TUSSENTIJDS = 'VAVO (tussentijds)',
  GEEN = 'Geen'
}
