@if (selected) {
	<div>
		<div class="top-detail">
			<app-pasfoto [pasfoto]="getData(selected).pasfoto" [alt]="getData(selected).alt" size="size-3"></app-pasfoto>
			<div class="top-detail__text">
				<span class="name">{{ getData(selected).title }}</span>
				<br />
				@for (subTitle of getData(selected).subTitle; track subTitle; let last = $last) {
					<span>
						<span class="detail">{{ subTitle }}</span>
						@if (!last) {
							<span class="bullit"> • </span>
						}
					</span>
				}
			</div>
			<div class="top-detail__buttons" data-princexml-ignore data-html2canvas-ignore>
				@if (showNext) {
					<div class="button left" title="Vorige {{ type }}" (click)="changeElement.emit(false)"></div>
				}
				@if (showPrevious) {
					<div class="button right" title="Volgende {{ type }}" (click)="changeElement.emit(true)"></div>
				} @else {
					<div class="placeholder"></div>
				}
			</div>
		</div>
		<div class="bottom-list">
			@for (detail of getData(selected).details; track detail.label) {
				<p class="title">{{ detail.label }}</p>
				<p [class.default]="detail.waarde == '-'">{{ detail.waarde }}</p>
			}
		</div>
	</div>
}
