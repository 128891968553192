import { Component, Inject } from '@angular/core';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { MoreOptionsDialogComponent } from '../more-options-dialog/more-options-dialog.component';
import { ExportOptions, ExportType } from '../../../services/export.service';
import { KolommenDialogComponent } from '../kolommen-dialog/kolommen-dialog.component';
import { Categorie, getExportAtts } from '../../../services/exportable';
import { categorieMap } from '../../../services/exportable-map';
import { intersection } from 'lodash-es';
import { UserService } from '../../../services/user.service';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Att } from '../../../services/data.service';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

@Component({
	selector: 'app-export-dialog',
	templateUrl: './export-dialog.component.html',
	styleUrls: ['./export-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, FormDropdownComponent, ButtonComponent],
})
export class ExportDialogComponent {
	exportTypeOptions: Option<ExportType>[];
	categorieen: Categorie[];

	constructor(
		@Inject(DIALOG_DATA) protected options: ExportOptions,
		private dialog: Dialog,
		private readonly overlay: Overlay,
		protected dialogRef: DialogRef<ExportOptions>,
		userService: UserService
	) {
		this.exportTypeOptions = options.exportTypes.map((val) => new Option<ExportType>(val));
		this.options.type = this.exportTypeOptions[0].value;
		this.categorieen = categorieMap[options.factTable!];
		this.options.selectedColumns = intersection(
			getExportAtts(...this.categorieen).filter((att) => userService.isAttAllowed(att)),
			this.options.selectedColumns
		);
	}

	findOption(exportType: ExportType): Option<ExportType> {
		return this.exportTypeOptions.find(({ value }) => exportType === value)!;
	}

	/**
	 * Muteert ons veld "options" en heeft geen annuleren.
	 * Dus geen return value nodig.
	 */
	openMoreOptionsDialog() {
		this.dialog.open(MoreOptionsDialogComponent, {
			data: this.options,
			positionStrategy: this.overlay.position().global().top('7rem').right('18.5rem'),
		});
	}

	openColumnDialog() {
		this.dialog
			.open<Att[]>(KolommenDialogComponent, {
				data: { selection: this.options.selectedColumns, categorieen: this.categorieen },
				positionStrategy: this.overlay.position().global().top('min(7rem, 12vh)').right('18.5rem'),
			})
			.closed.subscribe((selection) => {
				if (selection) this.options.selectedColumns = selection;
			});
	}
}
