import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppAuthenticationService, AuthInterceptor } from '@cumlaude/shared-authentication';
import { NewSessionComponent } from './core/new-session/new-session.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { DoorstroomComponent } from './dashboards/doorstroom/doorstroom.component';
import { InstroomComponent } from './dashboards/instroom/instroom.component';
import { KenmerkComponent } from './dashboards/kenmerk/kenmerk.component';
import { LeerlingComponent } from './dashboards/leerling/leerling.component';
import { UitstroomComponent } from './dashboards/uitstroom/uitstroom.component';
import { FilterContainerComponent } from './filter-panel/filter-container.component';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { SomtodayComponent } from './core/somtoday/somtoday.component';
import { GebruikersComponent } from './beheer/gebruikers/gebruikers.component';
import { EditAccountDialogComponent } from './dialogs/account/edit-account-dialog/edit-account-dialog.component';
import { OvergangComponent } from './dashboards/overgang/overgang.component';
import { AttributeSelectorComponent } from './attribute-selector/attribute-selector.component';
import { GeslaagdComponent } from './dashboards/geslaagd/geslaagd.component';
import { LijstComponent } from './details/lijst/lijst.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DetailPanelComponent } from './details/detail-panel/detail-panel.component';
import { FilterInputDirective } from './filter-panel/filter-input.directive';
import { OnderbouwsnelheidComponent } from './dashboards/onderbouwsnelheid/onderbouwsnelheid.component';
import { PlaatsingAdviesComponent } from './dashboards/plaatsing-advies/plaatsing-advies.component';
import { FilterLabelComponent } from './filter-label/filter-label.component';
import { CijfersOverzichtComponent } from './dashboards/cijfers-overzicht/cijfers-overzicht.component';
import { BovenbouwsuccesComponent } from './dashboards/bovenbouwsucces/bovenbouwsucces.component';
import { OnderwijspositieComponent } from './dashboards/onderwijspositie/onderwijspositie.component';
import { SchooljaarIndicatorOverComponent } from './schooljaar-indicator-over/schooljaar-indicator-over.component';
import { LegendaComponent } from './legenda/legenda.component';
import { ExamencijfersComponent } from './dashboards/examencijfers/examencijfers.component';
import { SchooljaarHistorieFilterComponent } from './schooljaar-historie-filter/schooljaar-historie-filter.component';
import { ToetsComponent } from './dashboards/toets/toets.component';
import { MatrixComponent } from './dashboards/matrix/matrix.component';
import { VerschilSeCeComponent } from './dashboards/verschil-se-ce/verschil-se-ce.component';
import { CijfersSeCeComponent } from './dashboards/cijfers-se-ce/cijfers-se-ce.component';
import { AfwezigheidOverzichtComponent } from './dashboards/afwezigheid-overzicht/afwezigheid-overzicht.component';
import { LeerlingDetailsPanelComponent } from './details/leerling/leerling-details-panel/leerling-details-panel.component';
import { DocentDetailsPanelComponent } from './details/docent/docent-details-panel/docent-details-panel.component';
import { DocentToetsDetailsPanelComponent } from './details/docent/docent-toets-details-panel/docent-toets-details-panel.component';
import { EinduitstroomComponent } from './dashboards/uitstroom-vso/einduitstroom.component';
import { CijferlijstComponent } from './dashboards/cijferlijst/cijferlijst.component';
import { LeerlingAfwezigheidDetailsPanelComponent } from './details/leerling/leerling-afwezigheid-details-panel/leerling-afwezigheid-details-panel.component';
import { CalendarComponent } from './calendar/calendar.component';
import { LeerlingAfwezigheidsredenenDetailsPanelComponent } from './details/leerling/leerling-afwezigheidsredenen-details-panel/leerling-afwezigheidsredenen-details-panel.component';
import { LeerlingCijferlijstDetailsPanelComponent } from './details/leerling/leerling-cijferlijst-details-panel/leerling-cijferlijst-details-panel.component';
import { LeerlingDoorstroomDetailsPanelComponent } from './details/leerling/leerling-doorstroom-details-panel/leerling-doorstroom-details-panel.component';
import { DoorstroomPathComponent } from './details/leerling/doorstroom-path/doorstroom-path.component';
import { LesregistratiesComponent } from './dashboards/lesregistraties/lesregistraties.component';
import { UitzonderingDoorstroomComponent } from './dashboards/uitzondering/uitzondering-doorstroom.component';
import { LeerlingLesregistratiesDetailsPanelComponent } from './details/leerling/leerling-lesregistraties-details-panel/leerling-lesregistraties-details-panel.component';
import { UitzonderingBovenbouwsuccesComponent } from './dashboards/uitzondering/uitzondering-bovenbouwsucces.component';
import { UitzonderingOnderbouwsnelheidComponent } from './dashboards/uitzondering/uitzondering-onderbouwsnelheid.component';
import { DocentCijfersDetailsPanelComponent } from './details/docent/docent-cijfers-details-panel/docent-cijfers-details-panel.component';
import { CijfersDocentComponent } from './dashboards/cijfers-docent/cijfers-docent.component';
import { ToetsDetailsComponent } from './dashboards/toets-details/toets-details.component';
import { OnderwijsresultatenComponent } from './dashboards/onderwijsresultaten/onderwijsresultaten.component';
import { CohortrendementComponent } from './dashboards/cohortrendement/cohortrendement.component';
import { UitstroomIqComponent } from './dashboards/uitstroom-iq/uitstroom-iq.component';
import { InterneAccountsToevoegenDialogComponent } from './dialogs/account/interne-accounts-toevoegen-dialog/interne-accounts-toevoegen-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { TussentijdseUitstroomComponent } from './dashboards/uitstroom-vso/tussentijdse-uitstroom.component';
import { ExportDialogComponent } from './dialogs/export/export-dialog/export-dialog.component';
import { MoreOptionsDialogComponent } from './dialogs/export/more-options-dialog/more-options-dialog.component';
import { KolommenDialogComponent } from './dialogs/export/kolommen-dialog/kolommen-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HelpDialogComponent } from './dialogs/overig/help-dialog/help-dialog.component';
import { VestigingenComponent } from './beheer/vestigingen/vestigingen.component';
import { SchoolbreedComponent } from './beheer/schoolbreed/schoolbreed.component';
import { RollenComponent } from './beheer/rollen/rollen.component';
import { TeamComponent } from './beheer/team/team.component';
import { SectieComponent } from './beheer/sectie/sectie.component';
import { DocentComponent } from './beheer/docent-mentor/docent.component';
import { MentorComponent } from './beheer/docent-mentor/mentor.component';
import { BestuurComponent } from './beheer/bestuur/bestuur.component';
import { RolToekennenDialogComponent } from './dialogs/account/rol-toekennen-dialog/rol-toekennen-dialog.component';
import { TeamToekennenDialogComponent } from './dialogs/account/team-toekennen-dialog/team-toekennen-dialog.component';
import { BeheerDropdownComponent } from './beheer/beheer-dropdown/beheer-dropdown.component';
import { BeheerMultiDropdownComponent } from './beheer/beheer-multi-dropdown/beheer-multi-dropdown.component';
import { SectieToekennenDialogComponent } from './dialogs/account/sectie-toekennen-dialog/sectie-toekennen-dialog.component';
import { LoadingInterceptor } from './core/loading-interceptor';
import { UitzonderingEinduitstroomComponent } from './dashboards/uitzondering/uitzondering-einduitstroom.component';
import { UitzonderingUitstroomIqComponent } from './dashboards/uitzondering/uitzondering-uitstroom-iq.component';
import { UitzonderingTussentijdseUitstroomComponent } from './dashboards/uitzondering/uitzondering-tussentijdse-uitstroom.component';
import { PrestatieanalyseComponent } from './dashboards/prestatieanalyse/prestatieanalyse.component';
import { CardPaCellComponent } from './dashboards/prestatieanalyse/card-pa-cell/card-pa-cell.component';
import { SinglePercentielHbarComponent } from './dashboards/prestatieanalyse/single-percentiel-hbar/single-percentiel-hbar.component';
import { CardOrCellComponent } from './dashboards/onderwijsresultaten/card-or-cell/card-or-cell.component';
import { CardOrRowCellComponent } from './dashboards/onderwijsresultaten/card-or-row-cell/card-or-row-cell.component';
import { CardOrSingleCellComponent } from './dashboards/onderwijsresultaten/card-or-single-cell/card-or-single-cell.component';
import { CardPaHistoryCellComponent } from './dashboards/prestatieanalyse/card-pa-history-cell/card-pa-history-cell.component';
import { CijfersExamensComponent } from './dashboards/cijfers-examens/cijfers-examens.component';
import { PercentielSchaalverdelingComponent } from './dashboards/prestatieanalyse/percentiel-schaalverdeling/percentiel-schaalverdeling.component';
import { NgClickOutsideDirective, NgClickOutsideExcludeDirective } from 'ng-click-outside2';
import { UitzonderingOnderwijspositieComponent } from './dashboards/uitzondering/uitzondering-onderwijspositie.component';
import { FavorietenDialogComponent } from './dialogs/favorieten/favorieten-dialog/favorieten-dialog.component';
import { FavorietAanmakenDialogComponent } from './dialogs/favorieten/favoriet-aanmaken-dialog/favoriet-aanmaken-dialog.component';
import { DashboardNameInterceptor } from './core/dashboard-name-interceptor';
import { MappenDropdownComponent } from './mappen-dropdown/mappen-dropdown.component';
import { ServerErrorInterceptor } from './core/server-error-interceptor';
import { HttpCancelInterceptor } from './core/http-cancel-interceptor';
import { FeedbackDialogComponent } from './dialogs/overig/feedback-dialog/feedback-dialog.component';
import { CijferkolommenComponent } from './beheer/cijferkolommen/cijferkolommen.component';
import { CijferkolommenDetailsComponent } from './beheer/cijferkolommen-details/cijferkolommen-details.component';
import { CohortdetailsComponent } from './dashboards/cohortdetails/cohortdetails.component';
import { OnderwijsresultatenUitsluitenFilterComponent } from './onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaginaDelenDialogComponent } from './dialogs/pagina-delen/pagina-delen-dialog/pagina-delen-dialog.component';
import { NoMatchedSharedRouteComponent } from './core/no-matched-shared-route/no-matched-shared-route.component';
import { PaginaOntvangenDialogComponent } from './dialogs/pagina-delen/pagina-ontvangen-dialog/pagina-ontvangen-dialog.component';
import { RechtenComponent } from './beheer/rechten/rechten.component';
import { DialogModule } from '@angular/cdk/dialog';
import { LeerlingselectieBewerkenDialogComponent } from './dialogs/leerlingselectie/leerlingselectie-bewerken-dialog/leerlingselectie-bewerken-dialog.component';
import { LeerlingLesregistratieredenenDetailsPanelComponent } from './details/leerling/leerling-lesregistratiesredenen-details-panel/leerling-lesregistratieredenen-details-panel.component';
import { VakkenComponent } from './dashboards/vakken/vakken.component';
import { LeerlingVakkenDetailsPanelComponent } from './details/leerling/leerling-vakken-details-panel/leerling-vakken-details-panel.component';
import { GedeeldePaginaPersoonComponent } from './dashboards/dashboard/gedeelde-pagina-persoon.component';
import { OnderwijsresultatenOptionsDialogComponent } from './dialogs/overig/onderwijsresultaten-options-dialog/onderwijsresultaten-options-dialog.component';
import { BugsnagService } from '@cumlaude/bugsnag';
import { UserBugsnagService } from '@cumlaude/user-bugsnag';
import { UserAuthenticationService } from './services/user-authentication.service';
import { HamburgerMenuComponent } from '@cumlaude/shared-components-menu';
import { ParamEncodingInterceptor } from '@cumlaude/shared-utils';
import { HeaderComponent } from './layout/header/header.component';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

registerLocaleData(localeNl);

@NgModule({
	declarations: [AppComponent],
	imports: [
		A11yModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		ClipboardModule,
		DialogModule,
		DragDropModule,
		FormsModule,
		NgClickOutsideDirective,
		NgClickOutsideExcludeDirective,
		OAuthModule.forRoot(),
		ScrollingModule,
		ToastrModule.forRoot({ closeButton: true }),
		DoorstroomComponent,
		DashboardComponent,
		LeerlingComponent,
		NewSessionComponent,
		SomtodayComponent,
		FilterPanelComponent,
		FilterInputDirective,
		FilterContainerComponent,
		KenmerkComponent,
		InstroomComponent,
		UitstroomComponent,
		GebruikersComponent,
		EditAccountDialogComponent,
		OvergangComponent,
		AttributeSelectorComponent,
		GeslaagdComponent,
		LijstComponent,
		DetailPanelComponent,
		OnderbouwsnelheidComponent,
		PlaatsingAdviesComponent,
		FilterLabelComponent,
		BovenbouwsuccesComponent,
		CijfersOverzichtComponent,
		OnderwijspositieComponent,
		SchooljaarIndicatorOverComponent,
		LegendaComponent,
		ExamencijfersComponent,
		SchooljaarHistorieFilterComponent,
		ToetsComponent,
		MatrixComponent,
		VerschilSeCeComponent,
		CijfersSeCeComponent,
		AfwezigheidOverzichtComponent,
		LeerlingDetailsPanelComponent,
		EinduitstroomComponent,
		DocentDetailsPanelComponent,
		DocentToetsDetailsPanelComponent,
		CijferlijstComponent,
		LeerlingAfwezigheidDetailsPanelComponent,
		CalendarComponent,
		LeerlingAfwezigheidsredenenDetailsPanelComponent,
		LeerlingCijferlijstDetailsPanelComponent,
		LeerlingDoorstroomDetailsPanelComponent,
		DoorstroomPathComponent,
		LesregistratiesComponent,
		UitzonderingDoorstroomComponent,
		LeerlingLesregistratiesDetailsPanelComponent,
		UitzonderingBovenbouwsuccesComponent,
		UitzonderingOnderwijspositieComponent,
		UitzonderingOnderbouwsnelheidComponent,
		ToetsDetailsComponent,
		InterneAccountsToevoegenDialogComponent,
		DocentCijfersDetailsPanelComponent,
		CijfersDocentComponent,
		CohortrendementComponent,
		UitstroomIqComponent,
		TussentijdseUitstroomComponent,
		OnderwijsresultatenComponent,
		ExportDialogComponent,
		MoreOptionsDialogComponent,
		KolommenDialogComponent,
		HelpDialogComponent,
		VestigingenComponent,
		SchoolbreedComponent,
		RollenComponent,
		TeamComponent,
		SectieComponent,
		DocentComponent,
		MentorComponent,
		BestuurComponent,
		RolToekennenDialogComponent,
		TeamToekennenDialogComponent,
		BeheerDropdownComponent,
		BeheerMultiDropdownComponent,
		SectieToekennenDialogComponent,
		UitzonderingEinduitstroomComponent,
		UitzonderingUitstroomIqComponent,
		UitzonderingTussentijdseUitstroomComponent,
		CardOrCellComponent,
		CardOrRowCellComponent,
		CardOrSingleCellComponent,
		PrestatieanalyseComponent,
		CardPaCellComponent,
		SinglePercentielHbarComponent,
		CardPaHistoryCellComponent,
		CijfersExamensComponent,
		PercentielSchaalverdelingComponent,
		FavorietenDialogComponent,
		FavorietAanmakenDialogComponent,
		MappenDropdownComponent,
		FeedbackDialogComponent,
		CijferkolommenComponent,
		CijferkolommenDetailsComponent,
		CohortdetailsComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		PaginaDelenDialogComponent,
		NoMatchedSharedRouteComponent,
		PaginaOntvangenDialogComponent,
		RechtenComponent,
		LeerlingselectieBewerkenDialogComponent,
		LeerlingLesregistratieredenenDetailsPanelComponent,
		VakkenComponent,
		LeerlingVakkenDetailsPanelComponent,
		GedeeldePaginaPersoonComponent,
		OnderwijsresultatenOptionsDialogComponent,
		HamburgerMenuComponent,
		HeaderComponent,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: AuthInterceptor,
		},
		{
			provide: BugsnagService,
			useClass: UserBugsnagService,
		},
		{
			provide: ErrorHandler,
			useFactory(envConfig: EnvConfiguration) {
				if (envConfig.bugsnagApiKey) {
					return new BugsnagErrorHandler();
				}
				return new ErrorHandler();
			},
			deps: [ENV_CONFIG],
		},
		{
			provide: AppAuthenticationService,
			useClass: UserAuthenticationService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: LoadingInterceptor,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: DashboardNameInterceptor,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: ServerErrorInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: HttpCancelInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: ParamEncodingInterceptor,
		},
		{
			provide: LOCALE_ID,
			useValue: 'nl-NL',
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
