/* tslint:disable */
/* eslint-disable */
export enum OnderwijspositieUitzondering {
  REGULIERE_ONDERWIJSPOSITIE = 'Reguliere onderwijspositie',
  ANDERE_SCHOOL_VESTIGING_IN_LEERJAAR_1 = 'Andere school/vestiging in leerjaar 1',
  DOUBLURE_IN_LJ_2 = 'Doublure in lj 2',
  GEEN_LEERJAAR_2 = 'Geen leerjaar 2',
  ADVIES_BREDER_DAN_3 = 'Advies breder dan 3',
  NIET_BEKOSTIGBAAR = 'Niet bekostigbaar',
  TUSSENTIJDSE_IN_UITSTROOM = 'Tussentijdse in/uitstroom',
  VSO_ADVIES = 'VSO advies',
  COMBINATIELEERWEG = 'Combinatieleerweg',
  INTERNATIONALE_LEERLING = 'Internationale leerling',
  PR_O_LEERLING = 'PrO-leerling',
  VAVO_LEERLING = 'VAVO leerling',
  EMIGRATIE = 'Emigratie',
  NIEUWKOMER = 'Nieuwkomer',
  UITSTROOM_GEEN_VO = 'Uitstroom/Geen VO'
}
