import { Categorie, getAtt, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling, leerling_vervolgopleiding, leerling_vooropleiding } from './exportable-leerling';
import {
	loopbaan_examen,
	loopbaan_instroom_uitstroom,
	loopbaan_kenmerk,
	loopbaan_plaatsing,
	opleiding_kenmerk,
	opleiding_opleiding,
} from './exportable-loopbaan';

const doorstroom_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'ds_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'ds_fk_ilt_naar.ilt_nm_niveau', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'ds_nm_opleiding_naar', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'ds_fk_ilt_naar.ilt_abb_profiel', example: 'NT, ZW, EM, ...' },
		{ att: 'ds_fk_ilt_naar.ilt_nm_profiel', example: 'Zorg en Welzijn, Economie en maatschappij, ...' },
		{ att: 'ds_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'ds_is_succesvol', example: 'Ja, Nee' },
		{ att: 'ds_fk_ll.ll_is_cohortstatus_tot_volledig', example: 'Ja, Nee' },
		{ att: 'ds_fun_cohortstatus_vanaf', example: 'Volledig cohort gevolgd, Nog bezig, Tussentijds uitgestroomd' },
		{ att: 'ds_fun_cohortstatus_examenopstroom', example: 'Volledig cohort gevolgd, Nog bezig, Tussentijds uitgestroomd' },
		{ att: 'ds_fun_cohortrendement_tot', example: 'Onder advies, Zonder vertraging, Boven advies, Gedoubleerd, ...' },
		{ att: 'ds_fun_cohortrendement_vanaf', example: 'Onder advies, Zonder vertraging, Boven advies, Gedoubleerd, ...' },
		{ att: 'ds_fun_opstroomrendement', example: 'Onder advies, Zonder vertraging, Boven advies, Gedoubleerd, ...' },
		{ att: 'ds_is_prognose', example: 'Ja, Nee' },
		{ att: 'ds_nr_weging', example: '1; 0,5' },
	],
};

const doorstroom_examen: Categorie = {
	label: 'Examen',
	atts: [
		{
			att: 'ds_map_examenstatus_met_prognose',
			example: 'Geslaagd, Afgewezen, Geslaagd (prognose), Afgewezen (prognose), ...',
		},
		...loopbaan_examen.atts.filter((att) => getAtt(att) !== 'lb_nm_examenstatus').map((att) => modifyAtt(att, 'ds_fk_lb_van')),
	],
};

const doorstroom_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'ds_fk_lb_van')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'ds_fk_ll')),
	],
};

const doorstroom_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [
		...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'ds_fk_lb_van')),
		...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'ds_fk_ilt_van')),
	],
};

const doorstroom_leerling: Categorie = {
	label: 'Leerling',
	atts: leerling_leerling.atts.map((att) => modifyAtt(att, 'ds_fk_ll')),
};

const doorstroom_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		{ att: 'ds_nm_opleiding_van', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'ds_nr_leerjaar_van', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'ds_nm_klas_van', example: '1V2, 2B3, 3H4, ...' },
		{ att: 'ds_fk_lb_van.lb_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'ds_fk_lb_van.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		{ att: 'ds_fk_lb_van.lb_nm_lichting', example: 'VWO 2020-2023, VMBO Basisberoepsgerichte leerweg 2021-2023, ...' },
		{ att: 'ds_fk_lb_van.lb_nm_onderwijssoort', example: 'Praktijkonderwijs, VMBO Theoretische leerweg tweetalig, Gymnasium, ...' },
		{ att: 'ds_fun_onderwijstype', example: 'Regulier, Speciaal' },
		{ att: 'ds_fk_mw_mentor1.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'ds_fk_mw_mentor2.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'ds_fk_mw_mentor_indiv.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'ds_fk_ilt_vorig_sj.ilt_nm_niveau', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nm_klas', example: '1V2, 2B3, 3H4, ...' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nm_leerfase', example: 'HAVO 3, VMBO b/k 1, OND 2' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'ds_fk_lb_vorig_sj.lb_abb_leerfase', example: 'h3, bk1, lj2' },
		...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'ds_fk_ilt_van')),
	],
};

const doorstroom_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'x_peildatum_optional', label: 'Peildatum', isExportable: false },
		{ att: 'ds_fun_is_plaatsing_actueel', example: 'Ja, Nee' },
		{ att: 'ds_fun_plaatsing_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'ds_fun_plaatsing_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'ds_fun_plaatsing_lj3_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'ds_fun_plaatsing_lj3_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'ds_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'ds_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'ds_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'ds_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'ds_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'ds_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'ds_fk_lb_van')),
	],
};

const doorstroom_prestatieanalyse: Categorie = {
	label: 'Prestatieanalyse',
	atts: [
		{ att: 'ds_nm_prestatieanalyse_vso_uitzondering', example: 'Telt mee voor prestatieanalyse, Minder dan 6 weken ingeschreven' },
		'ds_nm_einduitstroom_vso_uitzondering',
		{ att: 'ds_nm_einduitstroom_vso', example: 'Arbeid, Vervolgonderwijs, ...' },
		{ att: 'ds_nm_tussentijdse_uitstroom_vso', example: 'Regulier onderwijs, Speciaal onderwijs' },
		{ att: 'ds_fk_ll.ll_nr_uitstroompunt', example: '7,0 - 12,0' },
		{ att: 'ds_nm_uitstroomprofiel_vso_van', example: 'Vervolgonderwijs, Arbeidsmarkt, Dagbesteding' },
	],
};

const doorstroom_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'ds_fk_vs_van.vs_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'ds_fk_lb_vorig_sj.lb_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'ds_fk_br_vest_naar.br_co_brin', example: '01XX00' },
		{ att: 'ds_fk_br_vest_van.br_co_brin', example: '01XX00' },
		{ att: 'ds_fk_br_vest_van.br_nm_brin_kort', example: 'Openb SGM CumLaude College' },
		{ att: 'ds_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
	],
};

const doorstroom_vooropleiding: Categorie = {
	label: 'Vooropleiding',
	atts: [
		{ att: 'ds_co_brin_svh', example: '02AA' },
		{ att: 'ds_fun_basisschooladvies_duo', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		...leerling_vooropleiding.atts.map((att) => modifyAtt(att, 'ds_fk_ll')),
	],
};

const doorstroom_vervolgopleiding: Categorie = {
	label: 'Vervolgopleiding',
	atts: [
		{ att: 'ds_fk_lb_van.lb_nr_leerjaar_svb', example: '1, 2, 3, 4, 5, 6' },
		...leerling_vervolgopleiding.atts.map((att) => modifyAtt(att, 'ds_fk_ll')),
	],
};

const doorstroom_uitzondering: Categorie = {
	label: 'Uitzonderingen',
	atts: [
		{ att: 'ds_nm_op_status_van', example: 'Informatie volledig, Advies niet correct in systeem, ...' },
		{ att: 'ds_nm_status', example: 'Informatie volledig, Onbekende doorstroom, ...' },
		{ att: 'ds_nm_bbs_uitzondering_van', example: 'Niet bekostigbaar, Emigratie, Geen bovenbouw, ...' },
		{ att: 'ds_nm_obs_uitzondering_van', example: 'Nieuwkomer, Tussentijdse instroom, Uitstroom/Geen VO, ...' },
		{ att: 'ds_nm_op_uitzondering_van', example: 'Combinatieleerweg, Advies breder dan 3, Doublure in lj2, ...' },
		'ds_nm_plaatsing_uitzondering',
	],
};

export const doorstroom_all = [
	doorstroom_doorstroom,
	doorstroom_examen,
	doorstroom_instroom_uitstroom,
	doorstroom_kenmerk,
	doorstroom_leerling,
	doorstroom_opleiding,
	doorstroom_plaatsing,
	doorstroom_prestatieanalyse,
	doorstroom_school,
	doorstroom_uitzondering,
	doorstroom_vervolgopleiding,
	doorstroom_vooropleiding,
];
