export enum AfwijkingPercentielWeergave {
	PERCENTIEL = 'Percentiel',
	AFWIJKING = 'Afwijking',
}

export enum CijferColumn {
	Cijfer = 'Cijfer',
	GemAndereVakken = 'Gem. andere vakken',
	Sectiegemiddelde = 'Sectiegemiddelde',
	GemAantalToetsen = 'Gemiddeld aantal toetsen',
	CijferVorigSchooljaar = 'Cijfer vorig schooljaar',
	Tekortpunten = 'Tekortpunten',
	GemTekortpunten = 'Gem. tekortpunten',
	Leerlingen = 'Leerlingen',
	PercOnvoldoende = '% onvoldoende',
	Mediaan = 'Mediaan',
	PlaatsingAdvies = 'Plaatsing/advies',
	AantalCijfers = 'Aantal cijfers',
	PercAfwezigheidOngeoorloofd = '% afwezigheid ongeoorloofd',
	PercAfwezigheidGeoorloofd = '% afwezigheid geoorloofd',
}

export enum CijferkolomKeuze {
	ALLE = '<Alle>',
	ADVIES = 'Advies',
	TOETS = 'Toets',
	GEMIDDELDE = 'Gemiddelde',
}

export enum CijfersSeCeWeergave {
	VERSCHIL = 'SE-CE verschil',
	SE = 'SE-cijfer',
	CE = 'CE-cijfer',
}

export type CohortrendementType = 'Cohortrendement tot' | 'Cohortrendement vanaf' | 'Examenrendement' | 'Opstroomrendement';

export type CohortrendementWeergave = 'Cohortrendement' | 'Doublures' | 'Niveauverschil';

export enum DashboardVariant {
	ACTUEEL = 'Actueel',
	HISTORIE = 'Historie',
}

export enum DashboardAspect {
	GEMIDDELDE = 'Gemiddelde',
	VERDELING = 'Verdeling',
}

export enum DoorstroomWeergave {
	SUCCESVOL = 'Succesvol',
	NIET_SUCCESVOL = 'Niet succesvol',
	BEVORDERD = 'Bevorderd',
	NIET_BEVORDERD = 'Niet bevorderd',
	OPSTROOM = 'Opstroom',
	AFSTROOM = 'Afstroom',
}

export enum Dossier {
	VOORTGANG = 'Voortgang',
	EXAMEN = 'Examendossier',
}

export enum Eenheid {
	AANTAL = 'Aantal',
	PERCENTAGE = 'Percentage',
}
export enum Interval {
	SCHOOLJAAR = 'Schooljaar',
	MAAND = 'Maand',
	WEEK = 'Week',
}

export enum Tijdseenheid {
	UREN = 'Lesuren',
	DAGEN = 'Lesdagen',
	UREN_PERCENTAGE = 'Lesuren (percentage)',
}

export type UitstroomIqWeergave = 'IQ-punt' | 'Uitstroompunt' | 'Verschilpunt';
