import { Component } from '@angular/core';
import { AttrPath, DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '@cumlaude/shared-services';
import { FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { onderwijspositieFilters } from '../onderwijspositie/onderwijspositie.component';
import { UitzonderingOnderwijsresultatenComponent } from './uitzondering-onderwijsresultaten.component';
import { DRIE_SCHOOLJAREN_EXCL_2020, getSchooljaarRangeString } from '@cumlaude/shared-utils';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

@Component({
	selector: 'app-uitzondering-onderwijspositie',
	templateUrl: './uitzondering.component.html',
	styleUrls: ['./uitzondering.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		ButtonComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		BarchartTableComponent,
	],
})
export class UitzonderingOnderwijspositieComponent extends UitzonderingOnderwijsresultatenComponent {
	protected readonly hideExclusief = DRIE_SCHOOLJAREN_EXCL_2020;

	groups: AttrPath[] = [['ds_nm_schooljaar_van'], ['ds_nm_op_uitzondering_van'], ['ds_nm_op_status_van']];

	initialFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_nm_op_uitzondering_van',
		'ds_nm_op_status_van',
	];

	permanentFilterExpressions = onderwijspositieFilters;

	constructor(
		dataService: DataService,
		route: ActivatedRoute,
		historyService: HistoryService,
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(dataService, route, historyService, filterService, toastr);
	}

	getSchooljarenTooltip() {
		if (!this.schooljaar) return null;

		return `Let op: de onderwijsinspectie toont deze periode als ${getSchooljaarRangeString(this.schooljaar, this.aantalJaren!, true)}`;
	}
}
