/* tslint:disable */
/* eslint-disable */
export enum Niveau {
  TE_BREED = 'te breed',
  OND = 'OND',
  VSO = 'VSO',
  PR_O = 'PrO',
  VMBO_B = 'VMBO b',
  VMBO_B_K = 'VMBO b / k',
  VMBO_K = 'VMBO k',
  VMBO_K_G_T = 'VMBO k / (g)t',
  VMBO_G_T = 'VMBO (g)t',
  VMBO_G_T_HAVO = 'VMBO (g)t / HAVO',
  VMBO_G_T_HAVO_VWO = 'VMBO (g)t / HAVO / VWO',
  VMBO_HAVO_VWO = 'VMBO / HAVO / VWO',
  HAVO = 'HAVO',
  HAVO_VWO = 'HAVO / VWO',
  VWO = 'VWO'
}
