import { Component } from '@angular/core';
import { BaseDetailsPanelComponent, convert, convertDate } from '../../base-details-panel/base-details-panel.component';
import {
	Attr,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataService,
	FilterExpression,
	ListOptions,
	TableResponse,
} from '../../../services/data.service';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AsyncPipe, Location } from '@angular/common';
import { DetailsElementData, ElementDataDetail, Leerling, LeerlingDetail, LijstElementData } from '../../Details';
import { isNull } from 'lodash-es';
import { lastValueFrom, Observable } from 'rxjs';
import { HistoryService, RestService } from '@cumlaude/shared-services';
import { FilterService } from '../../../services/filter.service';
import { ExportService } from '../../../services/export.service';
import { LoadingService } from '../../../services/loading.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { PageStateService } from '../../../services/page-state.service';
import { take } from 'rxjs/operators';
import { LeerlingselectieBewerkenDialogComponent } from '../../../dialogs/leerlingselectie/leerlingselectie-bewerken-dialog/leerlingselectie-bewerken-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';
import { BugsnagService } from '@cumlaude/bugsnag';
import { NotifiableError } from '@bugsnag/js';
import { SvgSpinnerComponent } from '../../../layout/svg-spinner/svg-spinner.component';
import { DetailPanelComponent } from '../../detail-panel/detail-panel.component';
import { LijstComponent } from '../../lijst/lijst.component';
import { InstantSearchBoxComponent, CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { TabMenuComponent } from '../../../layout/tab-menu/tab-menu.component';
import { UserService } from '../../../services/user.service';
import { RLeerlingSelectie } from '@cumlaude/service-contract';

const detailsDataCol: Attr[][] = [
	['lb_d_plaatsing_va'],
	['lb_nr_leerling'],
	['lb_fk_ll', 'll_nm_roepnaam'],
	['lb_fk_ll', 'll_nm_voorvoegsel'],
	['lb_fk_ll', 'll_nm_achternaam'],
	['lb_nm_klas'],
	['lb_nm_opleiding'],
	['lb_fk_ll', 'll_d_geboortedatum'],
	['lb_fk_pc_leerling', 'pc_nm_plaats'],
	['lb_fk_ll', 'll_nm_nationaliteit'],
	['lb_fk_ll', 'll_nm_svh'],
	['lb_fk_ll', 'll_nm_basisschooladvies_duo'],
	['lb_fk_ll', 'll_nm_basisschooladvies_uni'],
	['lb_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
	['lb_d_inschrijving'],
	['lb_d_uitschrijving'],
	['lb_fk_ll', 'll_des_uitschrijving'],
	['lb_fk_ll', 'll_des_vertrekstatus'],
	['lb_fk_ll', 'll_pic_pasfoto'],
	['lb_nm_schooljaar'],
	['lb_nr_leerjaar'],
];

const lijstDataCol: Attr[][] = [['ll_nm_achternaam'], ['ll_nm_voorvoegsel'], ['ll_nm_roepnaam'], ['ll_nr_leerling'], ['ll_pic_pasfoto']];

type LeerlingRow = { ll_nr_leerling: number; ll_nm_roepnaam: string; ll_nm_voorvoegsel?: string; ll_nm_achternaam: string };

@Component({
	selector: 'app-leerling-details-panel',
	templateUrl: '../../base-details-panel/base-details-panel.component.html',
	styleUrls: ['../../base-details-panel/base-details-panel.component.scss'],
	standalone: true,
	imports: [
		TabMenuComponent,
		ButtonComponent,
		CheckboxComponent,
		InstantSearchBoxComponent,
		LijstComponent,
		DetailPanelComponent,
		SvgSpinnerComponent,
		RouterOutlet,
		AsyncPipe,
	],
})
export class LeerlingDetailsPanelComponent extends BaseDetailsPanelComponent<Leerling, LeerlingDetail> {
	filters: FilterExpression[] = [new BasicFilterExpression<number>(['ll_is_actueel'], 1)];

	constructor(
		public dataService: DataService,
		public route: ActivatedRoute,
		public location: Location,
		public historyService: HistoryService,
		public filterService: FilterService,
		public exportService: ExportService,
		public loadingService: LoadingService,
		public qp: QueryParamStateService,
		protected pageStateService: PageStateService,
		private restService: RestService,
		protected userService: UserService,
		private dialog: Dialog,
		private toastr: ToastrService,
		private bugsnag: BugsnagService
	) {
		super(dataService, route, location, historyService, filterService, exportService, loadingService, qp, pageStateService);
	}

	getUrlPart(): string {
		return '/details/leerling';
	}

	getTitle(): string {
		return 'Leerlingen';
	}

	getType(): string {
		return 'leerling';
	}

	getElementKey(element: Leerling) {
		return element.ll_nr_leerling;
	}

	async slaSelectieOp() {
		const { sf, sp } = this.route.snapshot.queryParams;
		const account = await lastValueFrom(this.userService.myAccount$.pipe(take(1)));
		const leerlingen = await lastValueFrom(
			this.dataService.getLeerlingen<LeerlingRow>({
				sf,
				sp,
				col: [['ll_nm_achternaam'], ['ll_nm_voorvoegsel'], ['ll_nm_roepnaam'], ['ll_nr_leerling']],
			})
		);
		const dialogRef = this.dialog.open<RLeerlingSelectie>(LeerlingselectieBewerkenDialogComponent, {
			data: {
				leerlingen: leerlingen.rows.map(({ ll_nr_leerling, ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nm_achternaam }) => ({
					nummer: ll_nr_leerling,
					naam: [ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nm_achternaam].filter(Boolean).join(' '),
				})),
				selectie: {
					$type: 'RLeerlingSelectie',
					naam: '',
					instelling: account.instelling,
					account,
				},
			},
		});
		const rLeerlingSelectie = await lastValueFrom(dialogRef.closed);
		if (!rLeerlingSelectie) return;

		try {
			await lastValueFrom(this.restService.postLeerlingSelectie(rLeerlingSelectie));
			this.toastr.success(`Leerlingselectie ${rLeerlingSelectie.naam} is aangemaakt.`);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het opslaan van leerlingselectie ${rLeerlingSelectie.naam}.`);
		}
	}

	getLijstElementData(input: Leerling): LijstElementData {
		const title = [input.ll_nm_roepnaam, input.ll_nm_voorvoegsel, input.ll_nm_achternaam].filter(Boolean).join(' ');
		const alt = input.ll_nr_leerling.toString();

		return {
			title,
			subTitle: alt,
			alt,
			pasfoto: input.ll_pic_pasfoto,
		};
	}

	getDetailsElementData(input: LeerlingDetail): DetailsElementData {
		const details = <ElementDataDetail[]>[
			{ label: 'Opleiding', waarde: input.lb_nm_opleiding },
			{ label: 'Geboortedatum', waarde: convertDate(input.ll_d_geboortedatum) },
			{ label: 'Woonplaats', waarde: input.pc_nm_plaats },
			{ label: 'Nationaliteit', waarde: input.ll_nm_nationaliteit },
			{ label: 'School van herkomst', waarde: convert(input.ll_nm_svh) },
		];

		if (isNull(input.ll_nm_basisschooladvies_duo))
			details.push({ label: 'Basisschooladvies', waarde: convert(input.ll_nm_basisschooladvies_uni) });
		else details.push({ label: 'Basisschooladvies DUO', waarde: convert(input.ll_nm_basisschooladvies_duo) });

		if (!isNull(input.ll_nm_basisschooladvies_uni_herzien))
			details.push({ label: 'Heroverwogen basisschooladvies', waarde: input.ll_nm_basisschooladvies_uni_herzien });

		details.push({ label: 'Inschrijvingsdatum', waarde: convertDate(input.lb_d_inschrijving) });

		const datumUitschrijving = convertDate(input.lb_d_uitschrijving);
		if (datumUitschrijving != '-') {
			details.push(
				{ label: 'Uitschrijvingsdatum', waarde: datumUitschrijving },
				{ label: 'Uitschrijvingsreden', waarde: convert(input.ll_des_uitschrijving) },
				{ label: 'Vertrekstatus', waarde: convert(input.ll_des_vertrekstatus) }
			);
		}

		const title = [input.ll_nm_roepnaam, input.ll_nm_voorvoegsel, input.ll_nm_achternaam].filter(Boolean).join(' ');
		const alt = input.lb_nr_leerling.toString();

		return {
			title,
			subTitle: [alt, input.lb_nm_klas],
			alt,
			pasfoto: input.ll_pic_pasfoto,
			details,
		};
	}

	getLijstData(options: Partial<ListOptions>): Observable<TableResponse<Leerling>> {
		return this.dataService.getLeerlingen<Leerling>({ ...options, col: lijstDataCol });
	}

	getDetailsData(key: number): Observable<TableResponse<LeerlingDetail>> {
		return this.dataService.getLeerlingDetails<LeerlingDetail>(detailsDataCol, key);
	}

	getFilters(searchInput: string, globalFilters: boolean): CompoundFilterExpression | undefined {
		if (searchInput === '') return globalFilters ? new CompoundFilterExpression(this.filters) : undefined;

		let filters = new CompoundFilterExpression(
			[
				new BasicFilterExpression(['ll_nr_leerling'], `%${searchInput}%`, 'like'),
				new BasicFilterExpression(['ll_fun_volledige_naam'], `%${searchInput}%`, 'like_unaccent'),
			],
			'or'
		);

		return globalFilters ? new CompoundFilterExpression([new CompoundFilterExpression(this.filters), filters]) : filters;
	}
}
