import { Component, EventEmitter, Input, Output } from '@angular/core';

export type DialogSelection = {
	text: string;
	icon?: string;
};

@Component({
	selector: 'app-dialog-selection-bar',
	templateUrl: './dialog-selection-bar.component.html',
	styleUrls: ['./dialog-selection-bar.component.scss'],
	standalone: true,
})
export class DialogSelectionBarComponent {
	@Input()
	selection!: DialogSelection;

	@Input()
	selections: DialogSelection[] = [];

	@Output()
	selectionChange = new EventEmitter<DialogSelection>();
}
