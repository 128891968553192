import { Component, Input } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, ButtonComponent],
})
export class ConfirmDialogComponent {
	@Input()
	caption = '';

	@Input()
	action = 'Bevestigen';

	@Input()
	singleAction = false;

	constructor(public dialogRef: DialogRef<boolean>) {}
}
