import { flatMap, isUndefined, max, min, sortedUniq, sum } from 'lodash-es';
import { Attributes } from '../shared/dashboard/base-dashboard/base-dashboard-config';

type Agg<I, A, T> = {
	init: (i: I, keys: (string | null)[]) => T;
	combine: (as: A[], keys: (string | null)[], all?: T, allFiltered?: T) => T;
};
export type MultiAggregator<Ai extends keyof A, I, A extends { [x in Ai]: N }, N> = Agg<I, A, N> & { attribute: Ai };

export type SingleAggregator<I, T> = {
	init: (i: I, keys: (string | null)[]) => T;
	combine: (as: T[], keys: (string | null)[], all?: T, allFiltered?: T) => T;
};

export function sumOver<Ii extends keyof I, I, N extends number | null>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: N }, N> {
	return {
		init: (v) => v[ii],
		combine: (as) => <N>sum(as),
	};
}

export function sumIf<Ii extends keyof I, I>(
	ii: keyof I,
	predicate?: (i: I, keys: (string | null)[]) => boolean
): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v, keys: (string | null)[]) => (predicate?.(v, keys) ?? true ? v[ii] : 0),
		combine: (as) => sum(as),
	};
}

export function maxOver<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v) => v[ii],
		combine: (as) => max(as) ?? 0,
	};
}

export function maxOverIf<Ii extends keyof I, I>(
	ii: keyof I,
	predicate?: (i: I, keys: (string | null)[]) => boolean
): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v, keys: (string | null)[]) => (predicate?.(v, keys) ?? true ? v[ii] : 0),
		combine: (as) => max(as) ?? 0,
	};
}

export function maxOverMapped<I>(init: (v: I) => number): SingleAggregator<I, number> {
	return {
		init: (v) => init(v),
		combine: (as) => max(as) ?? 0,
	};
}

export function noAgg<Ii extends keyof I, I, T>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: T }, T | null> {
	return {
		init: (v) => v[ii],
		combine: (as, keys, all) => (all ? all : null),
	};
}

export function noAgg0<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v) => v[ii],
		combine: (as, keys, all) => (all ? all : 0),
	};
}

/**
 * Neem op hogere levels de _ALL_FILTERED als die bestaat, anders _ALL, en anders 0.
 */
export function noAggFiltered0<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v) => v[ii],
		combine: (as, keys, all, allFiltered) => allFiltered ?? all ?? 0,
	};
}

export function xAgg<Ii extends keyof I, I>(ii: keyof I, bitset?: number): SingleAggregator<I & { [ii in Ii]: number } & { xa: any }, number> {
	return {
		init: (v) => {
			if (v.xa === undefined) return;
			const xaggInit = bitset ? v.xa[bitset] : (Object.values(v.xa)?.[0] as any);
			return xaggInit[ii];
		},
		combine: (as) => as[0],
	};
}

export function countRecords<I extends { count_records: number }>(
	predicate: (i: I, keys: (string | null)[]) => boolean
): SingleAggregator<I, number> {
	return {
		init: (v, keys: (string | null)[]) => (predicate(v, keys) ? v.count_records : 0),
		combine: (as) => sum(as),
	};
}

export function countTrue<I>(predicate: (i: I, keys: (string | null)[]) => boolean): SingleAggregator<I, number> {
	return {
		init: (v, keys: (string | null)[]) => (predicate(v, keys) ? 1 : 0),
		combine: (as) => sum(as),
	};
}

export function countTrueAtLevel<I>(predicate: (i: I, keys: (string | null)[]) => boolean, level: number): SingleAggregator<I, number> {
	return {
		init: (v, keys: (string | null)[]) => (predicate(v, keys) ? 1 : 0),
		combine: (as, keys: (string | null)[]) => (keys.length > level ? Math.sign(sum(as)) : sum(as)),
	};
}

export function weightedAverage<A extends { count_records: number } & { [x in keyof A]: number | null }, I extends { [x in keyof I]: number | null }>(
	attribute: keyof A,
	initialAttribute: keyof I,
	weightAttribute: keyof A = 'count_records'
): MultiAggregator<keyof A, I, A, number | null> {
	return {
		attribute,
		init: (i) => i[initialAttribute],
		combine: (as) => {
			const teller = sum(as.map((a) => (a[attribute] !== null ? a[attribute]! * a[weightAttribute]! : 0)));
			const noemer = sum(as.map((a) => (a[attribute] !== null ? a[weightAttribute]! : 0)));
			return noemer ? teller / noemer : null;
		},
	};
}

export function weightedAverageAll<
	A extends { count_records: number } & { [x in keyof A]: number | null },
	I extends { [x in keyof I]: number | null },
>(attribute: keyof A, initialAttribute: keyof I): MultiAggregator<keyof A, I, A, number | null> {
	return {
		attribute,
		init: (i) => i[initialAttribute],
		combine: (as, keys, all) => {
			if (all !== undefined) return all;
			const teller = sum(as.map((a) => (a[attribute] !== null ? a[attribute]! * a.count_records : 0)));
			const noemer = sum(as.map((a) => (a[attribute] !== null ? a.count_records : 0)));
			return noemer ? teller / noemer : null;
		},
	};
}

export function maxAtLevel<Ai extends keyof A, A extends Attributes & { [x in Ai]: number }, I extends Attributes>(
	attribute: Ai,
	level: () => number // functie, omdat level afhankelijk kan zijn van huidig aantal groepen (beetje hack dus)
): MultiAggregator<Ai, I, A, number> {
	return {
		attribute,
		init: (i, keys) => (keys.length === level() ? i.count_records : 0),
		combine: (as, keys) => (keys.length === level() ? sum(as.map((a) => a.count_records)) : max(as.map((a) => a[attribute])) ?? 0),
	};
}

export function maxOverAtLevel<
	Ai extends keyof A,
	Oi extends keyof A & keyof I,
	A extends Attributes & { [x in Ai]: number } & { [x in Oi]: number },
	I extends Attributes & { [x in Oi]: number },
>(
	attribute: Ai,
	over: Oi,
	level: () => number // functie, omdat level afhankelijk kan zijn van huidig aantal groepen (beetje hack dus)
): MultiAggregator<Ai, I, A, number> {
	return {
		attribute,
		init: (i, keys) => (keys.length === level() ? i[over] : 0),
		combine: (as, keys) => (keys.length === level() ? sum(as.map((a) => a[over])) : max(as.map((a) => a[attribute])) ?? 0),
	};
}

export function minOver<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (v) => v[ii],
		combine: (as) => min(as) ?? 0,
	};
}

export function noAggString<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I, string> {
	return {
		init: (v) => {
			return <string>v[ii];
		},
		combine: (as, keys, all) => {
			return all ? all : '';
		},
	};
}

export function aggArrayString<Ii extends keyof I, I>(ii: keyof I): SingleAggregator<I, string> {
	return {
		init: (v) => {
			return <string>v[ii];
		},
		combine: (as) => {
			if (isUndefined(as)) return '';

			const stringArray = flatMap(as.map((val) => val?.split(', ') ?? [])).filter((a) => a);
			stringArray.sort((a, b) => a.localeCompare(b));
			return sortedUniq(stringArray).join(', ');
		},
	};
}

/**
 * Speciale aggregatie voor het aantal decimalen bij het middelen van cijfers.
 * Volgens de significantieregels is het aantal decimalen van een gemiddeld cijfer het
 * minimum van de aantallen decimalen van de onderliggende cijfers. Echter omdat het
 * nuttig is om te weten of bijv. een klassengemiddelde van afgeronde eindcijfers 5,5 of 6,4
 * is, hanteren we bij gemiddelde cijfers (count_records > 1) een minimum van 1 decimaal.
 */
export function aggDecimals<Ii extends keyof I, I extends Attributes>(ii: keyof I): SingleAggregator<I & { [ii in Ii]: number }, number> {
	return {
		init: (i) => (i.count_records === 1 ? i[ii] : Math.max(i[ii], 1)),
		combine: (as: number[]) => Math.max(min(as) ?? 0, 1),
	};
}
