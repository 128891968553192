<app-base-dialog sizeClass="size-25" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar">
	<ng-template #topBar>
		<app-dialog-header [title]="caption"></app-dialog-header>
	</ng-template>
	<ng-content></ng-content>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">{{ singleAction ? "Sluiten" : "Annuleren" }}</app-button>
		@if (!singleAction) {
			<app-button (clickit)="dialogRef.close(true)">{{ action }}</app-button>
		}
	</ng-template>
</app-base-dialog>
