import { Component, OnInit } from '@angular/core';
import { Sort } from '../../shared/components/table/table/table.component';
import { Observable } from 'rxjs';
import { maxOver, maxOverMapped, sumOver } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { att, att0, percOfTotal } from '../../services/measures';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { defaultDoorstroomActueelFilters, defaultDoorstroomHistorieFilters, FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { memoize } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, BaseDashboardConfig, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Axis, createYAxis } from '../../services/axis';
import { DashboardVariant } from '../../services/weergave-opties';
import { PsName } from '../../services/page-state.service';
import { BarInfo } from '../../services/stacked-bars';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface UitstroomI extends Attributes {
	ds_nr_weging: number;
	ds_is_prognose: string;
}

interface UitstroomA extends Attributes {
	max: number;
	weging: number;
	ds_is_prognose: number;
}

enum MeasureColumn {
	LEERLINGEN = 'Leerlingen',
	PERCENTAGE = 'Percentage',
}

@Component({
	selector: 'app-instroom',
	templateUrl: './uitstroom.component.html',
	styleUrls: ['./uitstroom.component.scss'],
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, VbarchartTableComponent],
})
export class UitstroomComponent extends BarchartTableConfig<UitstroomI, UitstroomA> implements OnInit {
	defaultGroups: AttrPath[] = [['ds_fk_ll', 'll_nm_svb']];

	groups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_co_brin_svh'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_fk_ll', 'll_nm_onderwijssoort_svb'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ll', 'll_nm_svb'],
		['ds_fk_ll', 'll_nm_svh'],
		['ds_fk_ll', 'll_nm_schoolsoort_svb'],
		['ds_fk_ll', 'll_des_uitschrijving'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_vs_naar', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = [...defaultDoorstroomActueelFilters, 'ds_nm_uitstroomtype_in_schooljaar', 'ds_nm_uitstroommoment_in_schooljaar'];

	historieFilters: FilterName[] = [
		...defaultDoorstroomHistorieFilters,
		'ds_nm_uitstroomtype_in_schooljaar',
		'ds_nm_uitstroommoment_in_schooljaar',
		'ds_fk_ll.ll_nm_svb',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [
		new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1),
		new BasicFilterExpression(['ds_nm_uitstroom_in_schooljaar'], null, '<>'),
	];

	variant!: DashboardVariant;

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant))
		);
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		max: maxOver('ds_nr_weging'),
		weging: sumOver<'ds_nr_weging', UitstroomI, number>('ds_nr_weging'),
		ds_is_prognose: maxOverMapped<UitstroomI>((v) => Number(v.ds_is_prognose)),
	};

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(): ColumnDef<DataRow<UitstroomA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			createMeasureColumn<UitstroomI, UitstroomA>(MeasureColumn.LEERLINGEN, att('weging'), { format: '1.0-2' }),
			createMeasureColumn(MeasureColumn.PERCENTAGE, percOfTotal('weging', 'weging'), { dataType: 'percentage' }),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	getBarchartQty(path: Path<UitstroomA, number[]>) {
		return att0('weging')(path);
	}

	createXAxis(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): Axis {
		return { min: 0, max: context.dataRoot!.a.max, ticks: [] };
	}

	createYAxis(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): Axis {
		if (!context.dataRoot) return super.createYAxis(context);
		return createYAxis([0, context.dataRoot.a.max]);
	}

	getDefaultSort(): Sort {
		return { active: MeasureColumn.LEERLINGEN, direction: 'desc' };
	}

	partitionMeasure: PartitionMeasure<UitstroomA> = { type: 'number', getValue: att('weging'), format: '1.0-2' };

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(UitstroomComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(UitstroomComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...fixedSubgroups];
	}

	enrichTableModel(tableModel: TableModel<DataRow<UitstroomA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	onContextCreated(context: DashboardContext<UitstroomI, UitstroomA, BaseDashboardConfig<UitstroomI, UitstroomA>>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	makeBar(
		attrs: UitstroomI,
		path: Path<UitstroomA, number[]>,
		context: DashboardContext<UitstroomI, UitstroomA, BaseDashboardConfig<UitstroomI, UitstroomA>>
	): BarInfo {
		const bar = super.makeBar(attrs, path, context);
		return { ...bar, className: Number(attrs.ds_is_prognose) ? 'dashboard-prognose' : bar.className };
	}
}
