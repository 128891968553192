import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { rolOrder, UserService } from '../../services/user.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { map } from 'rxjs/operators';
import { intersection } from 'lodash-es';
import { GedeeldePaginaPersoonService } from '../../services/gedeelde-pagina-persoon.service';
import { AsyncPipe } from '@angular/common';
import { GedeeldePaginaPersoonComponent } from './gedeelde-pagina-persoon.component';
import { PasfotoComponent } from '../../shared/components/pasfoto/pasfoto.component';
import { ToggleButtonsComponent } from '@cumlaude/shared-components-buttons';
import { RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { FavorietenListComponent } from '../../favorieten-list/favorieten-list.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	standalone: true,
	imports: [ToggleButtonsComponent, PasfotoComponent, GedeeldePaginaPersoonComponent, AsyncPipe, FavorietenListComponent],
})
export class DashboardComponent implements OnInit, OnDestroy {
	actieveRol?: RRol;
	account!: RCumLaudeAccount;

	subscriptions: Subscription[] = [];
	options: Observable<RRol[]> | undefined;

	createFavorietMap = new Subject<void>();

	constructor(
		private authService: AuthService,
		protected userService: UserService,
		protected restService: RestService,
		protected gppService: GedeeldePaginaPersoonService
	) {}

	getName(): string {
		return this.authService.getUserDisplayName();
	}

	changeRol(rol: RRol) {
		this.userService.changeRol(rol);
	}

	ngOnInit(): void {
		this.options = this.userService.rollen$.pipe(map((rollen) => intersection(rolOrder, rollen)));
		this.subscriptions.push(
			this.userService.rolChanged$.subscribe((rol) => (this.actieveRol = rol)),
			this.userService.myAccount$.subscribe((account) => (this.account = account))
		);
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	getRolDescription(rol: RRol | undefined): string {
		return this.userService.getAccountRolDescription(rol, this.account);
	}

	getRolName = (rol: RRol): string => {
		return this.userService.getRolName(rol);
	};
}
