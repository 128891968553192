import { range } from 'lodash-es';
import { formatDate } from '@angular/common';

/**
 * Vanwege corona neemt de inspectie schooljaar 2020/2021 niet mee in de beoordeling.
 * Om toch tot een beoordeling van 3 jaren te komen, nemen ze dan een extra jaar mee aan de voorkant.
 */
export const DRIE_SCHOOLJAREN_EXCL_2020 = 3.1;

/**
 * Vanwege corona neemt de inspectie schooljaar 2020/2021 niet mee in de beoordeling.
 * Om toch tot een beoordeling van 3 jaren te komen, nemen ze dan een extra jaar mee aan de voorkant.
 * Echter voor Onderwijspositie zien wij het inspectiejaar 2020/2021 als 2019/2020.
 */
export const DRIE_SCHOOLJAREN_EXCL_2019 = 3.2;

const DEFAULT_DATUM_FORMAT = 'YYYY-MM-dd';

export function addMonths(date: Date, aantal: number): Date {
	const result = new Date(date);
	result.setMonth(date.getMonth() + aantal);
	return result;
}

export function addDays(date: Date, aantal: number): Date {
	const result = new Date(date);
	result.setDate(date.getDate() + aantal);
	return result;
}

export function addYears(date: Date, aantal: number): Date {
	const result = new Date(date);
	result.setFullYear(date.getFullYear() + aantal);
	return result;
}

export function getISOWeekAndYear(d: Date): { week: number; year: number } {
	const date = new Date(d.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	// January 4 is always in week 1.
	const week1 = new Date(date.getFullYear(), 0, 4);
	return {
		// Adjust to Thursday in week 1 and count number of weeks from date to week1.
		week: 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7),
		year: date.getFullYear(),
	};
}

export function getSchooljaarTovHuidig(aantalJaren: number = 0): string {
	return getSchooljaarForDate(new Date(), aantalJaren);
}

export function getSchooljaarForDate(huidigeDatum: Date, aantalJaren: number = 0): string {
	const datum = addMonths(huidigeDatum, -7);
	const beginjaar = datum.getFullYear() + aantalJaren;
	const eindjaar = beginjaar + 1;
	return `${beginjaar}/${eindjaar}`;
}

export function getSchooljaarKort(schooljaar: string): string {
	const beginjaar = parseInt(schooljaar.substring(0, 4));
	const eindjaar = beginjaar + 1;
	return `${beginjaar % 100}/${eindjaar % 100}`;
}

export function getSchooljaar(schooljaar: string, delta: number): string {
	const beginjaar = getBeginjaar(schooljaar) + delta;
	return getSchooljaarString(beginjaar);
}

export function getSchooljaarString(beginjaar: number) {
	return `${beginjaar}/${beginjaar + 1}`;
}

export function getBeginjaar(schooljaar: string): number {
	return parseInt(schooljaar.substring(0, 4));
}

export function getEindjaar(schooljaar: string): number {
	return parseInt(schooljaar.substring(5));
}

function getPeiljaar(schooljaar: string): number {
	return Number(schooljaar.split('/')[1]) + 1;
}

/**
 * Geeft een array van `aantalJaren` schooljaren, oplopend gesorteerd t/m `eindjaar`.
 * Als `aantalJaren` gelijk is aan `DRIE_SCHOOLJAREN_EXCL_2020`, wordt 2020/2021 eruit gefilterd en krijg je indien nodig een extra jaar aan het begin.
 */
export function getSchooljarenRange(eindjaar: string, aantalJaren: number, inspectieOnderwijspositie = false): string[] {
	return range(aantalJaren) // 0 tot (exclusief) aantalJaren; bij DRIE_SCHOOLJAREN_EXCL_2020 (=3.1) is dit incl. de 3
		.map((j) => getSchooljaar(eindjaar, -j))
		.filter((jaar) => filterSchooljaar(getPeiljaar(eindjaar), aantalJaren, jaar)) // verwijder coronajaar indien nodig
		.map((schooljaar) => (inspectieOnderwijspositie ? getSchooljaar(schooljaar, 1) : schooljaar)) // voor benaming van de schooljaren door de Inspectie voor Onderwijspositie: tel er 1 bij op
		.slice(0, aantalJaren) // als coronajaar niet in de lijst voorkwam, hebben we mogelijk een element teveel; verwijder deze
		.reverse(); // oplopend gesorteerd
}

function filterSchooljaar(peiljaar: number, aantalJaren: number, jaar: string) {
	if (aantalJaren !== DRIE_SCHOOLJAREN_EXCL_2020 && aantalJaren !== DRIE_SCHOOLJAREN_EXCL_2019) return true;
	else if (aantalJaren === DRIE_SCHOOLJAREN_EXCL_2020 && jaar == '2020/2021' && [2023, 2024].includes(peiljaar)) return false;
	else if (aantalJaren === DRIE_SCHOOLJAREN_EXCL_2019 && jaar == '2019/2020' && [2023].includes(peiljaar)) return false;
	return true;
}

/**
 * @returns true als in het opgegeven eindschooljaar rekening moet worden gehouden met het coronajaar.
 * 		Voor Onderwijspositie is het coronajaar 2019/2020 en zou daarom al in 2022/2023 (=peiljaar 2024) vervallen, maar voor de andere indicatoren geldt dat niet.
 * 		Voor de user experience is het beter als over alle dashboards heen wel of niet rekening wordt gehouden met het coronajaar.
 */
export function isPeiljaarMetExclusief(eindschooljaar: string | undefined): boolean {
	return eindschooljaar !== undefined && [2023, 2024].includes(getPeiljaar(eindschooljaar));
}

export function getSchooljaarRangeString(eindjaar: string, aantalJaren: number, inspectieOnderwijspositie = false): string {
	return getSchooljarenRange(eindjaar, aantalJaren, inspectieOnderwijspositie).join(', ');
}

export function getSchooljaarBegin(schooljaar: string): Date {
	return stripTime(`${getBeginjaar(schooljaar)}-08-01`);
}

export function getSchooljaarEind(schooljaar: string): Date {
	return stripTime(`${getEindjaar(schooljaar)}-07-31`);
}

export function getDatum(format: string = DEFAULT_DATUM_FORMAT, daysFromToday: number = 0): string {
	const vandaag = today();
	const morgen = addDays(vandaag, daysFromToday);
	return formatDate(morgen, format, 'nl-NL');
}

export function stripTime(date: Date | string) {
	const result = new Date(date);
	result.setHours(0, 0, 0, 0);
	return result;
}

export function today(): Date {
	return stripTime(new Date());
}

export function getNow(): string {
	return new Date().toISOString();
}

export function getTimestamp(): string {
	return formatDate(new Date(), 'yyyyMMdd-HHmmss', 'nl-NL');
}

export function dutchDate(date: Date | number | string | undefined | null): string {
	if (!date || (typeof date === 'object' && !(date instanceof Date)) || !['object', 'number', 'string'].includes(typeof date)) return '';
	return formatDate(date, 'dd-MM-yyyy', 'nl-NL');
}

export function isoDate(date: Date | number | string | undefined | null): string {
	if (!date || (typeof date === 'object' && !(date instanceof Date)) || !['object', 'number', 'string'].includes(typeof date)) return '';
	return formatDate(date, 'yyyy-MM-dd', 'nl-NL');
}

export function dateDiffInDays(a: Date, b: Date): number {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateDiffInHours(a: Date, b: Date): number {
	const _MS_PER_HOUR = 1000 * 60 * 60;
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours());
	return Math.floor((utc2 - utc1) / _MS_PER_HOUR);
}

export function compareDates(a: Date | string, b: Date | string): boolean {
	return new Date(a).getTime() === new Date(b).getTime();
}

export function minDate(a: Date, b: Date): Date {
	return a < b ? a : b;
}

export function maxDate(a: Date, b: Date): Date {
	return a > b ? a : b;
}
