import { formatNumber, formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { isEmpty, last, memoize, sum } from 'lodash-es';
import { Observable } from 'rxjs';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { maxOver, MultiAggregator, weightedAverage } from '../../services/aggregation';
import { getLeafA, Level, Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { att } from '../../services/measures';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { UrlService } from '../../services/url.service';
import { Router } from '@angular/router';
import { UitstroomIqStatus } from '@cumlaude/metadata';
import { DataTreeTableConfig } from '../../shared/dashboard/data-tree-table/data-tree-table-config';
import { Axis } from '../../services/axis';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant, UitstroomIqWeergave } from '../../services/weergave-opties';
import { prestatieanalyseDsFilterExcludes } from '../../services/exportable-map';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface UitstroomIqI extends Attributes {
	'ds_fk_ll.ll_nr_iq_punt': number;
	'ds_fk_ll.ll_nr_uitstroompunt': number;
	ds_nr_verschilpunt: number;
	ds_nr_leerlingen: number;
}

interface UitstroomIqA extends Attributes {
	gemIQpunt: number | null;
	gemVerschilpunt: number;
	maxVerschilpunt: number;
	gemUitstroompunt: number;
	leerlingen: number;
	onbekend: number;
}

const uitstroomIqActueelFilters: FilterName[] = [
	'x_prestatieanalyse_ds_schooljaar',
	'ds_fk_br_vest_van.br_co_brin',
	'ds_nm_prestatieanalyse_vso_uitzondering',
];

interface Weergave extends Option<UitstroomIqWeergave> {
	subgroups: AttrPath[];
	attrI: keyof UitstroomIqI;
	getClassName?: (value: number) => string;
	getBarchartQty: (path: Path<UitstroomIqA, number[]>) => number;
	getMaxQty: (root: Level<UitstroomIqA, number[]>) => number;
	getMeasure: (path: Path<UitstroomIqA, number[]>) => number | null;
	measures: DoorstroomMeasure[];
	getLeerlingen: (attrs: UitstroomIqI) => number;
	uitzonderingen: UitstroomIqStatus[];
}

@Component({
	selector: 'app-uitstroom-iq',
	templateUrl: './uitstroom-iq.component.html',
	styleUrls: ['./uitstroom-iq.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
	],
})
export class UitstroomIqComponent extends BarchartTableConfig<UitstroomIqI, UitstroomIqA> implements OnInit {
	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	selectedGroups = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_nm_klas_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
	];

	actueelFilters: FilterName[] = uitstroomIqActueelFilters;

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters.slice(1),
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [
		// Voorkom dubbelingen door doorstroom-record van relevante plaatsing naar zowel niet- als wel-relevante plaatsing
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1),
		new BasicFilterExpression(['ds_nm_uitstroomtype_vso'], null, '<>'),
	];

	variant!: DashboardVariant;

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService,
		protected urlService: UrlService,
		protected router: Router
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)),
			this.qp.observe('uitstroomiqweergave').subscribe((weergave) => this.selectWeergaveOptie(weergave))
		);
	}

	weergaveOpties: Weergave[] = [
		{
			text: 'IQ-punt',
			value: 'IQ-punt',
			subgroups: [['ds_fk_ll', 'll_nr_iq_punt']],
			attrI: 'ds_fk_ll.ll_nr_iq_punt',
			getClassName: (value) => 'iqpunt-' + value,
			getBarchartQty(path) {
				return getLeafA(path).gemIQpunt ?? 0;
			},
			getMaxQty: (_root: Level<UitstroomIqA, number[]>) => 8,
			getMeasure: (path) => getLeafA(path).gemIQpunt ?? null,
			measures: [],
			getLeerlingen: (attrs) => (attrs['ds_fk_ll.ll_nr_iq_punt'] ? attrs.count_records : 0),
			uitzonderingen: [UitstroomIqStatus.ONBEKENDE_IQ_SCORE, UitstroomIqStatus.ONBEKENDE_IQ_SCORE_EN_UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN],
		},
		{
			text: 'Uitstroompunt',
			value: 'Uitstroompunt',
			subgroups: [['ds_fk_ll', 'll_nr_uitstroompunt']],
			attrI: 'ds_fk_ll.ll_nr_uitstroompunt',
			getClassName: (value) => generateCssClassForString('uitstroompunt-' + formatNumber(value, 'nl-NL', '1.1-1')),
			getBarchartQty(path) {
				return getLeafA(path).gemUitstroompunt ?? 0;
			},
			getMaxQty: (_root: Level<UitstroomIqA, number[]>) => 12,
			getMeasure: (path) => getLeafA(path).gemUitstroompunt ?? null,
			measures: [],
			getLeerlingen: (attrs) => (attrs['ds_fk_ll.ll_nr_uitstroompunt'] ? attrs.count_records : 0),
			uitzonderingen: [
				UitstroomIqStatus.UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN,
				UitstroomIqStatus.ONBEKENDE_IQ_SCORE_EN_UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN,
			],
		},
		{
			text: 'Verschilpunt',
			value: 'Verschilpunt',
			subgroups: [],
			attrI: 'ds_nr_verschilpunt',
			getBarchartQty(path) {
				return getLeafA(path).gemVerschilpunt ?? 0;
			},
			getMaxQty: (root: Level<UitstroomIqA, number[]>) => root.a.maxVerschilpunt,
			getMeasure: (path) => getLeafA(path).gemVerschilpunt ?? null,
			measures: [DoorstroomMeasure.VERSCHILPUNT],
			getLeerlingen: (attrs) => attrs.ds_nr_leerlingen,
			uitzonderingen: [
				UitstroomIqStatus.ONBEKENDE_IQ_SCORE,
				UitstroomIqStatus.UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN,
				UitstroomIqStatus.ONBEKENDE_IQ_SCORE_EN_UITSTROOMNIVEAU_NIET_AF_TE_LEIDEN,
			],
		},
	];

	weergaveOptie = this.weergaveOpties[0];

	selectWeergaveOptie(value: string) {
		this.weergaveOptie = this.weergaveOpties.find((o) => o.value === value)!;
		this.subgroups = this.weergaveOptie.subgroups;
	}

	subgroups: AttrPath[] = this.weergaveOptie.subgroups;

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData({ ...options, m: this.weergaveOptie.measures });
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		leerlingen: {
			init: (attrs: UitstroomIqI) => this.weergaveOptie.getLeerlingen(attrs),
			combine: (as: number[]) => sum(as),
		},
		onbekend: {
			init: (attrs: UitstroomIqI) => attrs.count_records - this.weergaveOptie.getLeerlingen(attrs),
			combine: (as: number[]) => sum(as),
		},
		maxVerschilpunt: maxOver('ds_nr_verschilpunt'),
	};

	protected multiAggregators: MultiAggregator<keyof UitstroomIqA, UitstroomIqI, UitstroomIqA, number | null>[] = [
		weightedAverage('gemIQpunt', 'ds_fk_ll.ll_nr_iq_punt', 'leerlingen'),
		weightedAverage('gemUitstroompunt', 'ds_fk_ll.ll_nr_uitstroompunt', 'leerlingen'),
		weightedAverage('gemVerschilpunt', 'ds_nr_verschilpunt', 'leerlingen'),
	];

	makeBar(
		attrs: UitstroomIqI,
		path: Path<UitstroomIqA, number[]>,
		context: DashboardContext<UitstroomIqI, UitstroomIqA, UitstroomIqComponent>
	): BarInfo {
		if (isEmpty(this.subgroups)) return super.makeBar(attrs, path, context);

		const punt = attrs[this.weergaveOptie.attrI];
		if (!punt) return { size: 0, linkData: {} };

		const className = this.weergaveOptie.getClassName?.(punt);
		const leerlingen = path[path.length - this.subgroups.length - 1].a.leerlingen;
		const tooltipElements: TooltipElement[] = [
			{
				label: this.weergaveOptie.text,
				value: formatNumber(punt, 'nl-NL', '1.0-1'),
			},
			{
				label: 'Leerlingen',
				value: `${attrs.count_records} van ${leerlingen} (${formatPercent(attrs.count_records / leerlingen, 'nl-NL', '1.0-0')})`,
			},
		];

		return {
			...super.makeBar(attrs, path, context),
			className,
			tooltip: tooltipElements,
		};
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<UitstroomIqI, UitstroomIqA, UitstroomIqComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	partitionMeasure: PartitionMeasure<UitstroomIqA> = { type: 'number', getValue: (path) => this.weergaveOptie.getMeasure(path), format: '1.1-1' };

	createMeasureColumns(): ColumnDef<DataRow<UitstroomIqA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			createMeasureColumn('Gemiddeld IQ-punt', att('gemIQpunt'), { format: '1.1-1', visible: () => this.weergaveOptie.value === 'IQ-punt' }),
			createMeasureColumn('Gemiddeld uitstroompunt', att('gemUitstroompunt'), {
				format: '1.1-1',
				visible: () => this.weergaveOptie.value === 'Uitstroompunt',
			}),
			createMeasureColumn('Verschilpunt', att('gemVerschilpunt'), {
				format: '1.1-1',
				visible: () => this.weergaveOptie.value === 'Verschilpunt',
			}),
			createMeasureColumn('Leerlingen', att('leerlingen')),
			createMeasureColumn('Onbekend', att('onbekend'), { clickHandler: (rowModel) => this.handleUitzonderingRedirect(rowModel) }),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.selectedGroups.length > 0;
	}

	private handleUitzonderingRedirect(rowModel: DataRow<UitstroomIqA>) {
		this.addUitzonderingFilters(rowModel);

		this.router.navigate(['/details/uitzondering/uitstroom-iq'], {
			queryParams: {
				from: this.urlService.getFrom(),
			},
		});
	}

	private addUitzonderingFilters(rowModel: DataRow<UitstroomIqA>) {
		const indexNiveau = this.selectedGroups.findIndex((value) => value.join('.') === 'ds_fk_ilt_van.ilt_nm_niveau');
		if (indexNiveau > -1) this.filterService.setFilterInput('ds_fk_ilt_van.ilt_nm_niveau', [rowModel._path[indexNiveau + 1].k]);

		const indexLeerjaar = this.selectedGroups.findIndex((value) => value.join('.') === 'ds_nr_leerjaar_van');
		if (indexLeerjaar > -1) this.filterService.setFilterInput('ds_nr_leerjaar_van', [Number(rowModel._path[indexLeerjaar + 1].k)]);

		this.filterService.setFilterInput('ds_fun_uitstroom_iq_status', this.weergaveOptie.uitzonderingen);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(UitstroomIqComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(UitstroomIqComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...fixedSubgroups];
	}

	getBarchartQty(path: Path<UitstroomIqA, number[]>) {
		return this.weergaveOptie.getBarchartQty(path);
	}

	createXAxis(context: DashboardContext<UitstroomIqI, UitstroomIqA, DataTreeTableConfig<UitstroomIqI, UitstroomIqA>>): Axis {
		return { min: 0, max: this.weergaveOptie.getMaxQty(context.dataRoot!), ticks: [] };
	}

	createYAxis(context: DashboardContext<UitstroomIqI, UitstroomIqA, DataTreeTableConfig<UitstroomIqI, UitstroomIqA>>): Axis {
		return { min: 0, max: this.weergaveOptie.getMaxQty(context.dataRoot!), ticks: [] };
	}

	getLegendaProperty() {
		return last(this.subgroups);
	}

	enrichTableModel(tableModel: TableModel<DataRow<UitstroomIqA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	filterExcludes(): FilterName[] {
		return prestatieanalyseDsFilterExcludes;
	}

	getDisplayOptions(): ExportFilter[] {
		return [{ label: 'Weergave', value: this.weergaveOptie.text }];
	}
}
