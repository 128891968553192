import { Categorie, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling } from './exportable-leerling';
import {
	loopbaan_examen,
	loopbaan_instroom_uitstroom,
	loopbaan_kenmerk,
	loopbaan_plaatsing,
	opleiding_kenmerk,
	opleiding_opleiding,
} from './exportable-loopbaan';
import { periode_datum } from './exportable-periode';

const aanwezigheid_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'aw_fk_lb.lb_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'aw_fk_lb.lb_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'aw_fk_lb.lb_is_succesvol', example: 'Ja, Nee' },
	],
};

const aanwezigheid_examen: Categorie = {
	label: 'Examen',
	atts: [...loopbaan_examen.atts.map((att) => modifyAtt(att, 'aw_fk_lb'))],
};

const aanwezigheid_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'aw_fk_lb')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'aw_fk_ll')),
	],
};

const aanwezigheid_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'aw_fk_lb')), ...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'aw_fk_ilt'))],
};

const aanwezigheid_leerling: Categorie = {
	label: 'Leerling',
	atts: [...leerling_leerling.atts.map((att) => modifyAtt(att, 'aw_fk_ll'))],
};

const aanwezigheid_aanwezigheid: Categorie = {
	label: 'Afwezigheid',
	atts: [
		{ att: 'aw_is_abs_geoorloofd', label: 'Geoorloofd', example: 'Ja, Nee' },
		{ att: 'aw_nm_abs_reden', example: 'Ziek, Spijbelen, ...' },
		// De volgende velden zijn niet beschikbaar als filter, omdat deze niet in alle aggregaties zitten
		...periode_datum.atts.map((att) => modifyAtt(att, 'aw_d_datum', { isFilter: false })),
		{ att: 'aw_nr_lesuur', isFilter: false, example: '1 - 8, ...' },
		{ att: 'aw_ts_begin', isFilter: false, example: '21-04-2023 08:20, ...' },
		{ att: 'aw_ts_eind', isFilter: false, example: '21-04-2023 09:10, ...' },
	],
};

const aanwezigheid_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [{ att: 'aw_nm_klas', example: '1V2, 2B3, 3H4, ...' }, ...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'aw_fk_ilt'))],
};

const aanwezigheid_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'aw_fun_plaatsing_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'aw_fun_plaatsing_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'aw_fun_plaatsing_lj3_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'aw_fun_plaatsing_lj3_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'aw_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'aw_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'aw_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'aw_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'aw_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'aw_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'aw_fk_lb')),
	],
};

const aanwezigheid_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'aw_fk_vs.vs_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'aw_fk_br_vest.br_co_brin', example: '01XX00' },
		{ att: 'aw_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
	],
};

const aanwezigheid_vak: Categorie = {
	label: 'Vak',
	atts: [
		// De volgende velden zijn niet beschikbaar als filter, omdat deze niet in alle aggregaties zitten
		// (aw_nm_vak is wel beschikbaar als voorgesteld filter bij Actueel)
		{ att: 'aw_nm_vak', isFilter: false, example: 'Engels, wiskunde A, ...' },
		{ att: 'aw_abb_vak', isFilter: false, example: 'entl, wisA, ...' },
		{ att: 'aw_fk_vk.vk_nm_vak', isFilter: false, label: 'Vak (officieel)', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'aw_fk_vk.vk_co_vak', isFilter: false, example: '11 - 9999' },
		{ att: 'aw_fk_vk.vk_nm_vakkengroep', isFilter: false, example: 'Engels, Frans, Duits; Wis-, Natuur-, Scheikunde en Biologie, ...' },
		{ att: 'aw_fk_vk.vk_nm_kernvak', isFilter: false, example: 'Nederlands, Engels, wiskunde, overige vakken' },
		{ att: 'aw_fk_lg.lg_nm_lesgroep', isFilter: false, example: '1een, h5cnetl, ...' },
		{ att: 'aw_fk_lg.lg_nm_docenten', isFilter: false, example: 'JSL, DKE, ...' },
	],
};

export const aanwezigheid_all = [
	aanwezigheid_aanwezigheid,
	aanwezigheid_doorstroom,
	aanwezigheid_examen,
	aanwezigheid_instroom_uitstroom,
	aanwezigheid_kenmerk,
	aanwezigheid_leerling,
	aanwezigheid_opleiding,
	aanwezigheid_plaatsing,
	aanwezigheid_school,
	aanwezigheid_vak,
];
