import { Component } from '@angular/core';
import { CellDataType, getFormattedValue, TableCellComponent } from '../../table/table.model';
import { isNumeric } from '@cumlaude/shared-utils';
import { TooltipDirective, TooltipType } from '@cumlaude/shared-components-overlays';

@Component({
	selector: 'app-data-cell',
	templateUrl: './data-cell.component.html',
	styleUrls: ['./data-cell.component.scss'],
	standalone: true,
	imports: [TooltipDirective],
})
export class DataCellComponent implements TableCellComponent<any> {
	data: any;
	dataType: CellDataType = 'number';
	format?: string;
	tooltip?: TooltipType;

	getFormattedValue(): string {
		return getFormattedValue(this.data, this.dataType, this.format);
	}

	getExportValue(data: any) {
		if ((this.dataType === 'number' || this.dataType === 'percentage') && isNumeric(data)) return Number(data);
		return data;
	}
}
