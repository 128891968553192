import { Attribute, Component, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { combineLatest, of, Subscription } from 'rxjs';
import { AppAuthenticationService, Menu } from '@cumlaude/shared-authentication';

@Component({
	selector: 'app-top-level-menu',
	templateUrl: './top-level-menu.component.html',
	styleUrls: ['./top-level-menu.component.scss'],
	standalone: true,
	imports: [RouterLink, RouterLinkActive],
})
export class TopLevelMenuComponent implements OnDestroy {
	menus!: Menu[];

	openSubMenu?: string;

	activeMenu?: Menu;

	@HostBinding('class.vertical')
	vertical: boolean;

	subscriptions: Subscription[] = [];

	removeFromQp = {};

	constructor(
		@Attribute('vertical') vertical: string,
		router: Router,
		activatedRoute: ActivatedRoute,
		protected appAuthenticationService: AppAuthenticationService
	) {
		this.vertical = vertical !== null;
		this.menus = appAuthenticationService.getMenus();
		this.removeFromQp = appAuthenticationService.getRemoveFromQp();
		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map((event) => (event as NavigationEnd).urlAfterRedirects),
					map((url) => ({
						url,
						route: activatedRoute.firstChild?.firstChild,
					})),
					filter(({ route }) => route?.outlet === 'primary'),
					mergeMap(({ url, route }) => combineLatest([route!.url, of(url)]))
				)
				.subscribe(([segments, url]) => {
					for (const menu of this.menus) {
						if (menu.conditie(url)) {
							this.activeMenu = menu;

							const firstUrlPart = segments[0]?.path;
							for (const menuItem of this.activeMenu.menus) {
								if (!menuItem.subMenus) continue;

								if (firstUrlPart === menuItem.subMenuName) this.open(firstUrlPart);
							}
						}
					}
				})
		);
	}

	openIfHorizontal(naam: string) {
		if (!this.vertical) this.open(naam);
	}

	private open(naam: string) {
		this.openSubMenu = naam;
	}

	closeIfHorizontal(naam: string) {
		if (!this.vertical && this.openSubMenu === naam) this.close();
	}

	close() {
		this.openSubMenu = undefined;
	}

	toggle(naam: string, event: Event) {
		this.cancelRouterIfVertical(event);
		if (this.openSubMenu === naam) this.close();
		else this.open(naam);
	}

	cancelRouterIfVertical(event: Event) {
		if (this.vertical) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	isUrlAllowed(url: string): boolean {
		return this.appAuthenticationService.isUrlAllowed(url);
	}
}
