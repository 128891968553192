/* tslint:disable */
/* eslint-disable */
export enum OnderbouwsnelheidUitzondering {
  REGULIERE_ONDERBOUWSNELHEID = 'Reguliere onderbouwsnelheid',
  GEEN_ONDERBOUW = 'Geen onderbouw',
  GEEN_1_OKTOBERPLAATSING = 'Geen 1-oktoberplaatsing',
  TUSSENTIJDSE_INSTROOM = 'Tussentijdse instroom',
  NIET_BEKOSTIGBAAR = 'Niet bekostigbaar',
  EMIGRATIE = 'Emigratie',
  UITSTROOM_GEEN_VO = 'Uitstroom/Geen VO',
  INTERNATIONALE_LEERLING = 'Internationale leerling',
  PR_O_LEERLING = 'PrO-leerling',
  VAVO_LEERLING = 'VAVO leerling',
  NIEUWKOMER = 'Nieuwkomer'
}
