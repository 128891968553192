import { Att, AttrPath } from './data.service';
import { attrLabel } from './labels';
import { flatMap } from 'lodash-es';
import { ExtraFilterNames, FilterName } from './filter-config';

export enum FactTable {
	aanwezigheid = 'aanwezigheid',
	basisvaardigheden = 'basisvaardigheden',
	doorstroom = 'doorstroom',
	cijfers = 'cijfers',
	cijferkolommen = 'cijferkolommen',
	examencijfers = 'examencijfers',
	lesregistratie = 'lesregistratie',
	loopbaan = 'loopbaan',
	onderwijsresultaten = 'onderwijsresultaten',
	prestatieanalyseVso = 'prestatieanalyseVso',
	vakkeuze = 'vakkeuze',
}

export interface Exportable {
	factTable: FactTable;
}

export type ExtAtt =
	| {
			att: Att;
			label?: string;
			example?: string;
			isExportable?: boolean; // default true
			isFilter?: boolean; // default true
	  }
	| {
			att: ExtraFilterNames;
			label?: string;
			example?: string;
			// ExtraFilterNames zijn niet exporteerbaar; dwing isExportable: false af
			isExportable: false;
			isFilter?: boolean; // default true
	  };

export type Categorie = { label: string; atts: (Att | ExtAtt)[] };

export function getAtt(att: Att | FilterName | ExtAtt): Att | FilterName {
	if (typeof att === 'string') return att;
	return att.att;
}

export function getAttLabel(att: Att | ExtAtt): string {
	if (typeof att === 'string') return attrLabel(<AttrPath>att.split('.'));
	return att.label ?? attrLabel(<AttrPath>att.att.split('.'));
}

export function modifyAtt(att: Att | ExtAtt, prefix: string, options?: Partial<ExtAtt>): ExtAtt {
	if (typeof att === 'string') return { att: <Att>`${prefix}.${att}` };
	if (att.att?.startsWith('x_')) return att;

	return { ...att, ...(options ?? {}), att: <Att>`${prefix}.${att.att}` };
}

export function getExportAtts(...cats: Categorie[]): Att[] {
	return flatMap(cats, (cat) =>
		cat.atts
			.filter((att) => typeof att === 'string' || (att.isExportable ?? true))
			// ExtraFilterNames zijn niet exporteerbaar dus we kunnen casten naar Att
			.map((att) => (typeof att === 'string' ? att : <Att>att.att))
	);
}

export function getFilterAtts(...cats: Categorie[]): FilterName[] {
	return flatMap(cats, (cat) =>
		cat.atts.filter((att) => typeof att === 'string' || (att.isFilter ?? true)).map((att) => (typeof att === 'string' ? att : att.att))
	);
}
