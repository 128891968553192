@if (data?.percentage) {
	<span
		[class]="getClass()"
		[title]="data?.tooltip"
		[routerLink]="urlService.getRouterLink(data?.linkData)"
		[queryParams]="urlService.getQueryParams(data?.linkData)"
		(contextmenu)="onContextMenu($event, data)"
	>
		{{ data?.percentage! | extendedPercent: format }}
	</span>
} @else {
	<span
		[class]="getClass()"
		[appTooltip]="data?.tooltip"
		[routerLink]="urlService.getRouterLink(data?.linkData)"
		[queryParams]="urlService.getQueryParams(data?.linkData)"
		(contextmenu)="onContextMenu($event, data)"
	>
		{{ data?.ds_nr_weging | extendedDecimal: format }}
	</span>
}
