/* tslint:disable */
/* eslint-disable */
export enum Prestatie {
  DOORSTROOM = 'Doorstroom',
  GESLAAGD = 'Geslaagd',
  OPSTROOM = 'Opstroom',
  AFSTROOM = 'Afstroom',
  DOUBLURE = 'Doublure',
  AFGEWEZEN = 'Afgewezen',
  GEEN = 'Geen'
}
