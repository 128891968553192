import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Path } from '../../services/data-tree';
import { AttrPath, BasicFilterExpression, DataOptions, DataResponse, DataService, FilterExpression } from '../../services/data.service';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow, getGroupAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, Filter, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Axis } from '../../services/axis';
import { Sort } from '../../shared/components/table/table/table.component';
import { maxOver, noAgg0 } from '../../services/aggregation';
import { UrlService } from '../../services/url.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface CijferkolommenI extends Attributes {
	count_resultaatkolom: number;
}

interface CijferkolommenA extends Attributes {
	count_resultaatkolom: number;
	max_count: number;
}

@Component({
	selector: 'app-cijferkolommen',
	templateUrl: './cijferkolommen.component.html',
	styleUrls: ['./cijferkolommen.component.scss'],
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent],
})
export class CijferkolommenComponent extends BarchartTableConfig<CijferkolommenI, CijferkolommenA> {
	selectedGroups: AttrPath[] = [['ck_abb_kolomkop']];

	fixedBeforeGroups = 1;

	defaultFilters: FilterName[] = ['ck_nm_schooljaar', 'ck_nm_vestiging'];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [new BasicFilterExpression(['ck_is_any_laatste_gemiddelde'], 1)];

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService,
		protected urlService: UrlService
	) {
		super(filterService, toastr);
	}

	factTable = FactTable.cijferkolommen;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijferkolommenData(options);
	}

	protected singleAggregators = {
		count_resultaatkolom: noAgg0('count_resultaatkolom'),
		max_count: maxOver('count_resultaatkolom'),
	};

	createLinkData(
		path: Path<CijferkolommenA, number[]>,
		context: DashboardContext<CijferkolommenI, CijferkolommenA, CijferkolommenComponent>
	): Partial<LinkData> {
		const { ck_abb_kolomkop } = getGroupAttributes(context, path);
		return {
			...super.createLinkData(path, context),
			redirect: false,
			filters: [{ name: 'ck_abb_kolomkop', value: ck_abb_kolomkop }],
		};
	}

	handleClick(filters: Filter[], _groups: AttrPath[]) {
		let params = {};
		filters.forEach((filter) => {
			Object.assign(
				params,
				params,
				this.filterService.encodeFilterValue(filter.name, this.filterService.isMultiSelect(filter.name) ? [filter.value] : filter.value)
			);
		});
		this.urlService.redirect(['beheer/cijferinstellingen/cijferkolommen-details'], params);
	}

	createMeasureColumns(): ColumnDef<DataRow<CijferkolommenA>>[] {
		return [
			createMeasureColumn<Attributes, CijferkolommenA>('Aantal kolommen', att<'count_resultaatkolom', CijferkolommenA>('count_resultaatkolom')),
		];
	}

	createXAxis(context: DashboardContext<CijferkolommenI, CijferkolommenA, CijferkolommenComponent>): Axis {
		return { min: 0, max: context.dataRoot!.a.max_count, ticks: [] };
	}

	getDefaultSort(): Sort {
		return { active: 'Aantal kolommen', direction: 'desc' };
	}

	getBarchartQty(path: Path<CijferkolommenA, number[]>) {
		return att<'count_resultaatkolom', CijferkolommenA>('count_resultaatkolom')(path);
	}

	enrichTableModel(tableModel: TableModel<DataRow<CijferkolommenA>>) {
		tableModel.showFooters = false;
	}
}
