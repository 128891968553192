import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';
import { Subscription } from 'rxjs';
import { AttrPath } from '../services/data.service';
import { LegendaEnumNames } from '../core/services/enum.service';
import { QueryParamStateService } from '../services/query-param-state.service';
import { DashboardVariant } from '../services/weergave-opties';
import { UrlService } from '../services/url.service';
import { AsyncPipe } from '@angular/common';
import { ToggleButtonsComponent, ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FilterLabelComponent } from '../filter-label/filter-label.component';
import { LegendaComponent, LegendaStyle } from '../legenda/legenda.component';

@Component({
	selector: 'app-dashboard-header',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.scss'],
	standalone: true,
	imports: [ButtonComponent, LegendaComponent, FilterLabelComponent, ToggleButtonsComponent, AsyncPipe],
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
	dashboardName!: string;

	@Input()
	legenda?: AttrPath | [LegendaEnumNames];

	@Input()
	legendaKeyIsClassName = false;

	@Input()
	legendaIncludeNull = false;

	@Input()
	legendaExclude: string[] = [];

	@Input()
	legendaStyle?: LegendaStyle;

	@Input()
	variant?: DashboardVariant;

	protected subscriptions: Subscription[] = [];

	filters: [FilterName, any][] = [];

	varianten: DashboardVariant[] = Object.values(DashboardVariant);

	constructor(
		protected filterService: FilterService,
		protected qp: QueryParamStateService,
		protected urlService: UrlService
	) {
		this.subscriptions.push(urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title)));
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.filterService.getFilledFilters().subscribe((filters) => {
				this.filters = filters;
			})
		);
	}

	openFilterPanel() {
		this.filterService.filterPanelOpened$.next(true);
	}

	resetDashboard() {
		this.qp.resetDashboard().then(() => this.filterService.clearActiveNonDefault());
	}

	trackByName(_index: number, item: [FilterName, any] | null) {
		return item?.[0];
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
