import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { combineLatestWith, Observable, Subscription } from 'rxjs';
import { HistoryService } from '@cumlaude/shared-services';
import { get, isEmpty } from 'lodash-es';
import { RestLoggingService } from './services/rest.logging.service';
import { HttpCancelService } from './core/services/http-cancel-service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { UserService } from './services/user.service';
import { ToastrService } from 'ngx-toastr';
import { messages } from './services/toastr-messages';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];

	isBeheerPage$?: Observable<boolean>;

	/**
	 * HistoryService moet in het hoofd component geinject worden zodat altijd de history getracked wordt tijdens het gebruiken van de applicatie.
	 * En op die manier dan de terug knoppen kunnen werken.
	 */
	historyService: HistoryService = inject(HistoryService);

	constructor(
		private router: Router,
		activatedRoute: ActivatedRoute,
		restLoggingService: RestLoggingService,
		private httpCancelService: HttpCancelService,
		protected userService: UserService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private toastr: ToastrService
	) {
		this.isBeheerPage$ = router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => activatedRoute.firstChild?.firstChild),
			filter((route) => route?.outlet === 'primary'),
			mergeMap((route) => route!.url),
			map((url) => url[0]?.path === 'beheer')
		);
		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof ResolveEnd),
					map((event) => (event as ResolveEnd).urlAfterRedirects)
				)
				.subscribe((url) => (restLoggingService.currentDashboard = this.getDashboard(url))),

			router.events.subscribe((event) => {
				// Bij wegnavigeren naar ander dashboard of aanpassen van queryparams (incl filters) worden alle lopende requests gecancelled
				// door zowel frontend als backend.
				if (event instanceof NavigationStart) {
					this.httpCancelService.cancelPendingRequests(restLoggingService.currentDashboard, this.getDashboard(event.url));
				}
			}),

			router.events.subscribe((event) => {
				if (event instanceof ResolveEnd) {
					const msg_key = (router.getCurrentNavigation()?.extras?.info as { toastr?: string })?.toastr;
					if (msg_key) {
						const { type, message } = messages[msg_key];
						if (message) toastr.show(message, undefined, undefined, toastr.toastrConfig.iconClasses[type]);
					}
				}
			})
		);
	}

	private getDashboard(url: string) {
		const queryStringIndex = url.indexOf('?');
		return queryStringIndex == -1 ? url : url.substring(0, queryStringIndex);
	}

	ngOnInit() {
		this.setGoogleTagManager();
		this.initAppcues();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	/**
	 * In principe wordt de config van het huidige "Live" environment ingeladen (tenzij je aan het previewen/debuggen bent via de Google Tag Assistant).
	 * De Google Analytics events gaan dan naar de "CL Prod" GA4 property.
	 *
	 * Als er echter gtm_auth/gtm_preview parameters meegegeven worden, wordt de config van een Custom Environment (horend bij dezelfde GTM Container)
	 * ingeladen. De events gaan dan naar de GA4 property die voor dat Environment geconfigureerd staat.
	 */
	private setGoogleTagManager() {
		const { gtmContainerId, gtmAuth, gtmPreview } = this.envConfig;
		if (isEmpty(gtmContainerId)) return;

		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.innerHTML =
			'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push' +
			"({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)" +
			"[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
			"'https://www.googletagmanager.com/gtm.js?id='+i+dl" +
			(isEmpty(gtmAuth) ? '' : `+'&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x'`) +
			';f.parentNode.insertBefore(j,f);})' +
			`(window,document,'script','dataLayer','${gtmContainerId}');`;
		const head = document.getElementsByTagName('head')[0];
		head.appendChild(s);
	}

	private initAppcues() {
		const { appcuesAccountId, environmentName } = this.envConfig;

		if (!isEmpty(appcuesAccountId)) {
			const scriptTag = document.createElement('script');
			scriptTag.src = `//fast.appcues.com/${appcuesAccountId}.js`;
			scriptTag.addEventListener('load', () => {
				this.addAppcuesEventListeners(environmentName);
			});
			const head = document.getElementsByTagName('head')[0];
			head.appendChild(scriptTag);
		}
	}

	private addAppcuesEventListeners(environmentName: string) {
		const appcues = get(window, 'Appcues');
		if (!appcues) return;

		this.subscriptions.push(
			this.userService.myAccount$.pipe(combineLatestWith(this.userService.instelling$)).subscribe(([account, instelling]) => {
				appcues.identify(account.id, {
					naam: account.naam,
					support: account.support,
					rollen: account.rollen,
					omgeving: environmentName,
				});

				appcues.group(instelling.id, {
					naam: instelling.naam,
					bron: instelling.bron,
					modules: instelling.modules,
				});
			}),
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				get(window, 'Appcues')?.page();
			})
		);
	}
}
