import { Component, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Att } from '../../../services/data.service';
import { flatMap, intersection, sortBy } from 'lodash-es';
import { Categorie, ExtAtt, getAtt, getAttLabel, getExportAtts } from '../../../services/exportable';
import { UserService } from '../../../services/user.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

type KolomRow = {
	kolomKey: Att;
	categorieKey: string;
	label: string;
	example?: string;
};

type CategorieRow = { categorieKey: string; label: string };

type Row = KolomRow | CategorieRow;

function isCategorie(row: Row): row is CategorieRow {
	return !('kolomKey' in row);
}

function isKolom(row: Row): row is KolomRow {
	return 'kolomKey' in row;
}

@Component({
	selector: 'app-kolommen-dialog',
	templateUrl: './kolommen-dialog.component.html',
	styleUrls: ['./kolommen-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, CheckboxComponent, InstantSearchBoxComponent, ButtonComponent, AsyncPipe],
})
export class KolommenDialogComponent {
	filter$ = new BehaviorSubject('');
	filtered$: Observable<Row[]>;

	// onveranderlijk
	allRows!: Row[];
	allKolomKeys!: Set<Att>;
	allCategorieKeys!: Set<string>;

	// component state
	showSelection = false;
	selection!: Set<Att>; // geselecteerde kolommen (keys)
	opened!: Set<string>; // opengeklapte categorieën (keys)

	isCategorie = isCategorie;
	isKolom = isKolom;

	constructor(
		@Inject(DIALOG_DATA) private dialogData: { categorieen: Categorie[]; selection: Att[] },
		protected dialogRef: DialogRef<Att[]>,
		userService: UserService
	) {
		this.determineRowsAndKeys(userService);
		this.setInitialState();

		this.filtered$ = this.filter$.pipe(map((zoek) => filterRows(this.allRows, zoek)));
	}

	determineRowsAndKeys(rolService: UserService): void {
		const { categorieen } = this.dialogData;
		this.allKolomKeys = new Set(getExportAtts(...categorieen).filter((att) => rolService.isAttAllowed(att)));
		this.allCategorieKeys = new Set(categorieen.map((cat) => cat.label));

		this.allRows = flatMap(categorieen, ({ label, atts }: Categorie) => this.generateCategorieRows(label, atts, rolService));
	}

	private generateCategorieRows(label: string, atts: (Att | ExtAtt)[], rolService: UserService) {
		const kolomRows = generateKolomRows(
			atts.filter((att) => rolService.isAttAllowed(getAtt(att))),
			label
		);

		if (kolomRows.length == 0) return [];

		return [{ categorieKey: label, label }, ...kolomRows];
	}

	setInitialState() {
		this.selection = new Set(intersection(this.dialogData.selection, [...this.allKolomKeys]));
		this.opened = new Set(this.allCategorieKeys);
	}

	setShowSelection(value: boolean) {
		this.showSelection = value;
		if (value) {
			this.filter$.next('');
			this.opened = new Set(this.allCategorieKeys);
		}
	}

	toggleKolomSelected(row: KolomRow) {
		if (this.selection.has(row.kolomKey)) this.selection.delete(row.kolomKey);
		else this.selection.add(row.kolomKey);
	}

	toggleCategorieOpened(row: CategorieRow) {
		if (this.opened.has(row.categorieKey)) this.opened.delete(row.categorieKey);
		else this.opened.add(row.categorieKey);
	}

	isCategorieSelected(categorieKey: string) {
		return this.allRows
			.filter((row) => this.isKolom(row) && this.selection.has(row.kolomKey))
			.some((row) => (<KolomRow>row).categorieKey === categorieKey);
	}

	getSelectionAtts(): Att[] {
		const selectedKolomRows = this.allRows.filter((row) => this.isKolom(row)).filter((row) => this.selection.has((<KolomRow>row).kolomKey));
		return sortBy(selectedKolomRows, 'label').map((row) => (<KolomRow>row).kolomKey);
	}

	isSelectionFull() {
		return this.selection.size === this.allKolomKeys.size;
	}

	selectAllOrNone() {
		if (this.isSelectionFull()) {
			this.selection = new Set();
			this.showSelection = false;
		} else {
			this.selection = new Set(this.allKolomKeys);
		}
	}
}

function generateKolomRows(atts: (Att | ExtAtt)[], categorieKey: string): KolomRow[] {
	const kolommen: KolomRow[] = atts
		.filter((att) => typeof att === 'string' || (att.isExportable ?? true))
		.map((att) => ({
			kolomKey: <Att>getAtt(att),
			categorieKey,
			label: getAttLabel(att) || `### ${typeof att === 'string' ? att : att.att}`,
			example: typeof att === 'object' ? att.example : undefined,
		}));
	return sortBy(kolommen, ['label']);
}

function filterRows(allRows: (KolomRow | CategorieRow)[], zoek: string) {
	if (zoek.length === 0) return allRows;
	const zoekLower = zoek.toLowerCase();

	const kolommenFilteredByContent = <KolomRow[]>allRows.filter((row) => isKolom(row) && kolomContentContains(row, zoekLower));
	const kolomKeysFilteredByContent = new Set(kolommenFilteredByContent.map(({ kolomKey }) => kolomKey));

	const categorieenFilteredByLabel = <CategorieRow[]>allRows.filter((row) => isCategorie(row) && row.label.toLowerCase().includes(zoekLower));
	const categorieKeysFilteredByLabel = new Set(categorieenFilteredByLabel.map(({ categorieKey }) => categorieKey));

	const categorieKeysFilteredByKolomContent = new Set(kolommenFilteredByContent.map(({ categorieKey }) => categorieKey));

	return allRows.filter(
		(row) =>
			(isCategorie(row) && (categorieKeysFilteredByLabel.has(row.categorieKey) || categorieKeysFilteredByKolomContent.has(row.categorieKey))) ||
			(isKolom(row) && (kolomKeysFilteredByContent.has(row.kolomKey) || categorieKeysFilteredByLabel.has(row.categorieKey)))
	);
}

function kolomContentContains({ label, example }: KolomRow, zoekLower: string) {
	return label.toLowerCase().includes(zoekLower) || example?.toLowerCase().includes(zoekLower);
}
