/* tslint:disable */
/* eslint-disable */
export enum TussentijdseUitstroomVsoUitzondering {
  INFORMATIE_VOLLEDIG = 'Informatie volledig',
  GEEN_VSO = 'Geen VSO',
  UITSTROOM_NIET_AF_TE_LEIDEN_UIT_SCHOOLSOORT_SV_B = 'Uitstroom niet af te leiden uit schoolsoort SvB',
  UITSTROOM_NIET_AF_TE_LEIDEN_UIT_UITSCHRIJVINGSREDEN = 'Uitstroom niet af te leiden uit uitschrijvingsreden',
  UITSTROOM_NIET_NAAR_ONDERWIJS = 'Uitstroom niet naar onderwijs',
  ONBEKENDE_SCHOOLSOORT_SV_B_EN_UITSCHRIJVINGSREDEN = 'Onbekende schoolsoort SvB en uitschrijvingsreden'
}
