import { Component, Inject } from '@angular/core';
import { CellDataType, TableCellComponent } from '../../table/table.model';
import { DataService } from '../../../../../services/data.service';
import { UrlService } from '../../../../../services/url.service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { CijferlijstData, getCijferClass, getCijferOfLabel, getExportValue } from '../../../../../services/cijfers';

@Component({
	selector: 'app-cijferlijst-cell',
	templateUrl: './cijferlijst-cell.component.html',
	styleUrls: ['./cijferlijst-cell.component.scss'],
	standalone: true,
})
export class CijferlijstCellComponent implements TableCellComponent<CijferlijstData | null> {
	data!: CijferlijstData | null;
	className?: string;
	dataType: CellDataType = 'number';
	format = '1.1-1';

	constructor(
		private dataService: DataService,
		private urlService: UrlService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {}

	getCijferClass = getCijferClass;
	getCijferOfLabel = getCijferOfLabel;
	getExportValue = getExportValue;

	onContextMenu($event: Event, cijferlijstData: CijferlijstData) {
		const { filter } = cijferlijstData.linkData;
		if (!this.envConfig.production) {
			$event.preventDefault();
			$event.stopPropagation();
			this.dataService.getSql('cijfers', { f: filter! }).subscribe((resp) => {
				navigator.clipboard.writeText(resp);
				console.log('SQL copied to clipboard.');
			});
		}
	}

	onClick(record: CijferlijstData) {
		this.urlService.navigate(record.linkData);
	}
}
