import { Categorie, modifyAtt } from './exportable';
import { leerling_instroom_uitstroom, leerling_leerling } from './exportable-leerling';
import {
	loopbaan_examen,
	loopbaan_instroom_uitstroom,
	loopbaan_kenmerk,
	loopbaan_plaatsing,
	opleiding_kenmerk,
	opleiding_opleiding,
} from './exportable-loopbaan';
import { periode_datum } from './exportable-periode';

const lesregistraties_doorstroom: Categorie = {
	label: 'Doorstroom',
	atts: [
		{ att: 'lr_fk_lb.lb_nm_idu', example: 'Doorstroom, Geslaagd, Afstroom, ...' },
		{ att: 'lr_fk_lb.lb_is_bevorderd', example: 'Ja, Nee' },
		{ att: 'lr_fk_lb.lb_is_succesvol', example: 'Ja, Nee' },
	],
};

const lesregistraties_examen: Categorie = {
	label: 'Examen',
	atts: [...loopbaan_examen.atts.map((att) => modifyAtt(att, 'lr_fk_lb'))],
};

const lesregistraties_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		...loopbaan_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'lr_fk_lb')),
		...leerling_instroom_uitstroom.atts.map((att) => modifyAtt(att, 'lr_fk_ll')),
	],
};

const lesregistraties_kenmerk: Categorie = {
	label: 'Kenmerk',
	atts: [...loopbaan_kenmerk.atts.map((att) => modifyAtt(att, 'lr_fk_lb')), ...opleiding_kenmerk.atts.map((att) => modifyAtt(att, 'lr_fk_ilt'))],
};

const lesregistraties_leerling: Categorie = {
	label: 'Leerling',
	atts: [...leerling_leerling.atts.map((att) => modifyAtt(att, 'lr_fk_ll'))],
};

const lesregistraties_lesregistratie: Categorie = {
	label: 'Lesregistratie',
	atts: [
		...periode_datum.atts.map((att) => modifyAtt(att, 'lr_d_datum')),
		{ att: 'lr_des_lesregistratie', example: 'Te laat, ...' },
		{ att: 'lr_fun_geoorloofd', example: 'Geoorloofd, Ongeoorloofd' },
		{ att: 'lr_fk_lg.lg_nm_lesgroep', example: '1een, h5cnetl, ...' },
		{ att: 'lr_nm_lesregistratie', example: 'Te laat, ...' },
		{ att: 'lr_nr_lesuur', example: '1-8' },
		{ att: 'lr_ts_begin', example: '21-04-2023 8:20', isFilter: false },
		{ att: 'lr_ts_eind', example: '21-04-2023 9:05', isFilter: false },
	],
};

const lesregistraties_opleiding: Categorie = {
	label: 'Opleiding',
	atts: [
		{ att: 'lr_fk_lb.lb_nm_opleiding', example: 'Brugklas, HAVO 3, VWO NT & NG, ...' },
		{ att: 'lr_fk_lb.lb_nr_leerjaar', example: '1, 2, 3, 4, 5, 6' },
		{ att: 'lr_nm_klas', example: '1V2, 2B3, 3H4, ...' },
		...opleiding_opleiding.atts.map((att) => modifyAtt(att, 'lr_fk_ilt')),
	],
};

const lesregistraties_plaatsing: Categorie = {
	label: 'Plaatsing',
	atts: [
		{ att: 'lr_fun_plaatsing_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'lr_fun_plaatsing_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'lr_fun_plaatsing_lj3_advies_score', example: '-1; 0; 0,5; 1' },
		{ att: 'lr_fun_plaatsing_lj3_advies_verschil', example: '0; -1,5; 2; -0,5; ...' },
		{ att: 'lr_fk_ll.ll_nm_niveau_lj3', example: 'VMBO b, PrO, HAVO / VWO, ...' },
		{ att: 'lr_fun_is_plaatsing_peildatum_1april', example: 'Ja, Nee' },
		{ att: 'lr_fun_is_plaatsing_peildatum_1feb', example: 'Ja, Nee' },
		{ att: 'lr_fun_is_plaatsing_peildatum_1jan', example: 'Ja, Nee' },
		{ att: 'lr_fun_is_plaatsing_peildatum_1juli', example: 'Ja, Nee' },
		{ att: 'lr_fun_is_plaatsing_peildatum_1okt', example: 'Ja, Nee' },
		...loopbaan_plaatsing.atts.map((att) => modifyAtt(att, 'lr_fk_lb')),
	],
};

const lesregistraties_school: Categorie = {
	label: 'School',
	atts: [
		{ att: 'lr_fk_vs.vs_nm_vestiging', example: 'Locatie CumLaude' },
		{ att: 'lr_fk_br_vest.br_co_brin', example: '01XX00' },
		{ att: 'lr_fk_inst.inst_nm_instelling', example: 'CumLaude College' },
	],
};

const lesregistraties_vak: Categorie = {
	label: 'Vak',
	atts: [
		{ att: 'lr_fks_mw.mw_nm_medewerker', example: 'Jansen, L. (54321); Dijk, E van (65432)' },
		{ att: 'lr_fk_vk.vk_nm_vak', label: 'Vak (officieel)', example: 'Engelse taal en literatuur, wiskunde A, ...' },
		{ att: 'lr_fk_vk.vk_co_vak', example: '11 - 9999' },
		{ att: 'lr_fk_vk.vk_nm_vakkengroep', example: 'Engels, Frans, Duits; Wis-, Natuur-, Scheikunde en Biologie, ...' },
		{ att: 'lr_fk_vk.vk_nm_kernvak', example: 'Nederlands, Engels, wiskunde, overige vakken' },
		{ att: 'lr_fun_nm_vak_uni', example: 'Engels, wiskunde, ...' },
		{ att: 'lr_nm_vak', example: 'Engels, wiskunde, ...' },
	],
};

export const lesregistraties_all = [
	lesregistraties_doorstroom,
	lesregistraties_examen,
	lesregistraties_instroom_uitstroom,
	lesregistraties_kenmerk,
	lesregistraties_leerling,
	lesregistraties_lesregistratie,
	lesregistraties_opleiding,
	lesregistraties_plaatsing,
	lesregistraties_school,
	lesregistraties_vak,
];
