import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		return this.handleCanActivate(state.url);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		return this.handleCanActivate(state.url);
	}

	private handleCanActivate(url: string): boolean | UrlTree {
		this.authService.storeRequestedUrl(url);
		if (this.authService.isLoggedIn()) {
			return true;
		} else {
			return this.authService.oauthUrlTree;
		}
	}
}
