<div class="selection-bar">
	@for (row of selections; track row.text) {
		<div class="element" [class.active]="row.text === selection.text">
			<a (click)="selectionChange.emit(row)">
				{{ row.text }}
			</a>
			@if (row.icon) {
				<div [class]="row.icon"></div>
			}
		</div>
	}
</div>
