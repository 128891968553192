import { Component, Inject } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';
import { Prestatie } from '@cumlaude/metadata';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { DataService } from '../../../../../services/data.service';
import { LinkData } from '../../../../dashboard/base-dashboard/base-dashboard-config';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { UrlService } from '../../../../../services/url.service';
import { ExtendedDecimalPipe, ExtendedPercentPipe } from '@cumlaude/shared-pipes';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { RouterLink } from '@angular/router';

export type DoorstroomData = {
	count_records: number;
	ds_nr_weging: number;
	percentage?: number;
	ds_is_prognose: '1' | '0';
	ds_nm_idu: Prestatie | null;
	tooltip: string;
	linkData: LinkData;
};

@Component({
	selector: 'app-doorstroom-cell',
	templateUrl: './doorstroom-cell.component.html',
	styleUrls: ['./doorstroom-cell.component.scss'],
	standalone: true,
	imports: [RouterLink, TooltipDirective, ExtendedPercentPipe, ExtendedDecimalPipe],
})
export class DoorstroomCellComponent implements TableCellComponent<DoorstroomData | null> {
	data!: DoorstroomData | null;
	format = '1.0-0';

	constructor(
		private dataService: DataService,
		protected urlService: UrlService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {}

	getClass() {
		if (this.data === null) return 'empty';
		return `${generateCssClassForString(this.data.ds_nm_idu ?? null) ?? 'onbekend'}${Number(this.data.ds_is_prognose) ? '-prognose' : ''}`;
	}

	onContextMenu($event: Event, record: DoorstroomData | null) {
		const { dataProvider, filter } = record?.linkData ?? {};
		if (!this.envConfig.production && dataProvider) {
			$event.preventDefault();
			$event.stopPropagation();
			this.dataService.getSql(dataProvider, { f: filter! }).subscribe((resp) => {
				navigator.clipboard.writeText(resp);
				console.log('SQL copied to clipboard.');
			});
		}
	}

	getExportValue(data: DoorstroomData | null): any {
		if (data?.percentage) return data.percentage;
		return data?.ds_nr_weging;
	}
}
