import { Component, OnInit } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { Observable } from 'rxjs';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, attParent, percOfParent, percOfRow } from '../../services/measures';
import { ExamenStatusMetPrognose } from '@cumlaude/metadata';
import { countRecords, maxOverMapped } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import { formatPercent } from '@angular/common';
import { FilterName } from '../../services/filter-config';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { memoize } from 'lodash-es';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, BaseDashboardConfig, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface GeslaagdI extends Attributes {
	ds_map_examenstatus_met_prognose: ExamenStatusMetPrognose;
	ds_is_prognose: string;
}

interface GeslaagdA extends Attributes {
	geslaagd: number;
	ds_is_prognose: number;
}

@Component({
	selector: 'app-geslaagd',
	templateUrl: './geslaagd.component.html',
	styleUrls: ['./geslaagd.component.scss'],
	standalone: true,
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, VbarchartTableComponent],
})
export class GeslaagdComponent extends BarchartTableConfig<GeslaagdI, GeslaagdA> implements OnInit {
	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	groups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_fk_ll', 'll_nm_basisschooladvies_uni'],
		['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['ds_fk_ll', 'll_nm_basisschooladvies_duo'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = ['ds_nm_schooljaar_van', 'ds_fk_vs_van.vs_nm_vestiging', 'ds_map_examenstatus_met_prognose'];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression<number>(['ds_fk_lb_van', 'lb_is_examenopleiding'], 1),
		new BasicFilterExpression<number>(['ds_is_relevante_doorstroom'], 1),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_internationaal'], 0),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_vakmanschap'], 0),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_vavo'], 0),
		new BasicFilterExpression<number>(['ds_map_pro_volgend_sj'], 0),
		new BasicFilterExpression<number>(['ds_map_uitstroom_hoger_onderwijs_geen_uitslag'], 0),
		new BasicFilterExpression<number>(['ds_map_uitstroom_speciaal_onderwijs'], 0),
	];

	filterExpressions?: FilterExpression[];

	subgroups: AttrPath[] = [['ds_map_examenstatus_met_prognose']];

	variant!: DashboardVariant;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups))
		);
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	makeBar(attrs: GeslaagdI, path: Path<GeslaagdA, number[]>, context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): BarInfo {
		const { count_records, ds_map_examenstatus_met_prognose } = attrs;
		const percentage = formatPercent(percOfParent('count_records')(path), 'nl-NL');
		const total = attParent('count_records')(path);

		const tooltipElements: TooltipElement[] = [
			{ label: `Examenstatus:`, value: `${this.displayService.display(ds_map_examenstatus_met_prognose)}` },
			{ label: `Leerlingen:`, value: `${count_records} van ${total}` },
			{ label: `Leerlingen (%):`, value: `${percentage}` },
		];

		return {
			...super.makeBar(attrs, path, context),
			className: generateCssClassForString(ds_map_examenstatus_met_prognose),
			tooltip: tooltipElements,
		};
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	protected singleAggregators = {
		geslaagd: countRecords(
			({ ds_map_examenstatus_met_prognose }: GeslaagdI) =>
				ds_map_examenstatus_met_prognose === ExamenStatusMetPrognose.GESLAAGD ||
				ds_map_examenstatus_met_prognose === ExamenStatusMetPrognose.GESLAAGD_PROGNOSE
		),
		ds_is_prognose: maxOverMapped<GeslaagdI>((v) => Number(v.ds_is_prognose)),
	};

	createMeasureColumns(context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): ColumnDef<DataRow<GeslaagdA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			createMeasureColumn('Percentage', percOfRow('geslaagd'), {
				dataType: 'percentage',
				clickHandler: (rowModel) => this.handleGeslaagdRedirect(rowModel, context),
			}),
			createMeasureColumn('Geslaagd', att('geslaagd'), { clickHandler: (rowModel) => this.handleGeslaagdRedirect(rowModel, context) }),
			createMeasureColumn('Leerlingen', att('count_records')),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	private handleGeslaagdRedirect(rowModel: DataRow<GeslaagdA>, context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>) {
		const linkData = this.createLinkData(rowModel._path, context);
		const { filter } = linkData;
		const newFilter = new CompoundFilterExpression([<FilterExpression>filter!, new BasicFilterExpression(['ds_is_geslaagd'], 1, '=')]);

		this.urlService.navigate({ ...linkData, filter: newFilter });
	}

	partitionMeasure: PartitionMeasure<GeslaagdA> = { type: 'percentage', getValue: percOfRow('geslaagd') };

	enrichTableModel(tableModel: TableModel<DataRow<GeslaagdA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(GeslaagdComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(groups: AttrPath[]) {
		return groups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(GeslaagdComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([groups, stacks]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...groups.slice(-1), ['ds_nm_schooljaar_van'], ...stacks];
	}

	onContextCreated(context: DashboardContext<GeslaagdI, GeslaagdA, BaseDashboardConfig<GeslaagdI, GeslaagdA>>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}
}
