<app-dashboard-container
	class="dashboard-uitstroom-iq"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="getLegendaProperty()" [legendaKeyIsClassName]="true"></app-dashboard-header>
		<div class="groepering">
			<div class="weergave">
				Weergave
				<dt-form-dropdown
					[opties]="weergaveOpties"
					[selectedOptie]="weergaveOptie"
					(onOptieClicked)="qp.dispatch('uitstroomiqweergave', $event)"
				></dt-form-dropdown>
			</div>
		</div>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups"
					[subgroups]="subgroups"
					[config]="this"
				></app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(selectedGroups)"
					[subgroups]="getHistorieSubgroups([selectedGroups, subgroups])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[partitionMeasure]="partitionMeasure"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
