import { AttrPath } from './data.service';
import { attrLabel } from './labels';
import { DoorstroomExt } from '@cumlaude/metadata';
import { generateCssClassForString } from '@cumlaude/shared-utils';

export function generateCssClassForAttrPath(input: AttrPath): string | undefined {
	return generateCssClassForString(attrLabel(input));
}

export function generateCssClassForCohortdetails(idu: string | null): { className: string; blocky: boolean } {
	const blocky =
		idu === DoorstroomExt.INSTROOM || idu === DoorstroomExt.GESLAAGD || idu === 'Geslaagd (prognose)' || (idu?.endsWith('(extern)') ?? false);
	const className = `${generateCssClassForString(idu).replace('-extern', '').replace('onbekend', 'onbekend-alt')}${blocky ? ' blocky' : ''}`;
	return { className, blocky };
}
