import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-pasfoto',
	templateUrl: './pasfoto.component.html',
	styleUrls: ['./pasfoto.component.scss'],
	standalone: true,
})
export class PasfotoComponent {
	@Input()
	pasfoto!: string | null;

	@Input()
	alt!: string;

	@Input()
	size!: 'size-2' | 'size-2-5' | 'size-3' | 'size-3-5';

	constructor(private sanitizer: DomSanitizer) {}

	getAlt(): string {
		return this.alt;
	}

	hasPasfoto(): boolean {
		return !!this.pasfoto;
	}

	getSrc(): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;charset=utf-8;base64, ' + this.pasfoto);
	}
}
