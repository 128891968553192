import { Component, signal } from '@angular/core';
import { ToetsA, ToetsComponent, ToetsI } from '../../../dashboards/toets/toets.component';
import { AttrPath, BasicFilterExpression, DataService, FilterExpression } from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { DashboardMedewerker, MedewerkerDetail } from '../../Details';
import { isNil, memoize } from 'lodash-es';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { attrLabel } from '../../../services/labels';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { getSchooljaarForDate, getSchooljaarTovHuidig } from '@cumlaude/shared-utils';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { FilterName } from '../../../services/filter-config';
import { take } from 'rxjs/operators';
import { ExportOptions } from '../../../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { Path } from '../../../services/data-tree';
import { DashboardContext } from '../../../shared/dashboard/base-dashboard/dashboard-context';
import { LinkData } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { AsyncPipe } from '@angular/common';
import { PivotTableComponent } from '../../../shared/dashboard/pivot-table/pivot-table.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { CijferkolomKeuze } from '../../../services/weergave-opties';

@Component({
	selector: 'app-docent-toets-details-panel',
	templateUrl: './docent-toets-details-panel.component.html',
	styleUrls: ['./docent-toets-details-panel.component.scss'],
	standalone: true,
	imports: [FilterPanelComponent, DashboardHeaderComponent, FormDropdownComponent, PivotTableComponent, AsyncPipe],
})
export class DocentToetsDetailsPanelComponent extends ToetsComponent implements DetailsDashboard<MedewerkerDetail> {
	filterExpressions?: FilterExpression[];

	defaultGroup: AttrPath = ['cf_nm_vak'];

	fixedBeforeGroups = 1;

	flexibleMaxGroups = 1;

	flexibleGroupsRemovable = false;

	selectedGroup: AttrPath = this.defaultGroup;

	medewerker$ = new ReplaySubject<DashboardMedewerker>(1);

	availableGroups: AttrPath[] = [['cf_nm_lesgroep'], ['cf_nm_vak']];

	toetsFilters: FilterName[] = [
		'cf_nm_schooljaar', //
		'x_cf_is_alternatievenormering',
		'x_details_periode',
		'cf_co_kolom',
		'cf_nm_vak',
	];

	cijferkolomKeuzeOpties = signal<Option<CijferkolomKeuze>[]>(
		[CijferkolomKeuze.ALLE, CijferkolomKeuze.TOETS, CijferkolomKeuze.GEMIDDELDE].map((val) => new Option(val))
	);

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(dataService, filterService, qp, toastr);
	}

	createLinkData(_path: Path<ToetsA, number[]>, _context: DashboardContext<ToetsI, ToetsA, ToetsComponent>): Partial<LinkData> {
		return {};
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
		this.subscriptions.push(this.filterService.observe('cf_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.selectedGroup = groups ? groups[0] : this.defaultGroup)));
		super.subscribeToQueryParams();
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.medewerker$.pipe(take(1))).then((medewerker) =>
			this.doExport(
				this.filterExpressions!,
				this.getDashboardFilters(this.getPermanentFilters(this.cijferkolomKeuze, this.dossier, []), medewerker.medewerker),
				[],
				exportOptions
			)
		);
	}

	getGroups = memoize(DocentToetsDetailsPanelComponent._getGroups, (g) => JSON.stringify([g]));

	private static _getGroups(selectedGroup: AttrPath) {
		return [selectedGroup];
	}

	getDashboardFilters = memoize(DocentToetsDetailsPanelComponent._getDashboardFilters, (f, m) => JSON.stringify([f, m]));

	private static _getDashboardFilters(filters: FilterExpression[], medewerker: MedewerkerDetail): FilterExpression[] {
		return [...filters, new BasicFilterExpression(['cf_fks_mw'], medewerker.mw_pk_key, 'any')];
	}

	getSchooljaarFilterOverride = memoize(DocentToetsDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { cf_nm_schooljaar: schooljaarInfo };
	}

	setSelected(selected: MedewerkerDetail, schooljaar?: string): void {
		let targetSchooljaar = schooljaar;
		if (!targetSchooljaar) {
			if (isNil(selected.mw_d_uitdienst)) targetSchooljaar = getSchooljaarForDate(new Date(selected.mw_d_uitdienst));
			else targetSchooljaar = getSchooljaarTovHuidig();
		}

		this.medewerker$.next({
			medewerker: selected,
			schooljaarInfo: targetSchooljaar,
		});
	}
}
