import {
	Component,
	computed,
	effect,
	ElementRef,
	EventEmitter,
	OnDestroy,
	OnInit,
	Signal,
	signal,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { FilterComponent } from '../../services/data.service';
import { DateRangeComponentParams, FilterName, PeriodeFilterValue } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, startWith, Subscription } from 'rxjs';
import {
	addDays,
	addMonths,
	addYears,
	dateDiffInDays,
	getBeginjaar,
	getISOWeekAndYear,
	getSchooljaarBegin,
	getSchooljaarEind,
	getSchooljaarTovHuidig,
	getSchooljarenRange,
	maxDate,
	minDate,
	today,
} from '@cumlaude/shared-utils';
import { toObservable } from '@angular/core/rxjs-interop';
import { Option, FormDropdownComponent, SingleSelectListComponent } from '@cumlaude/shared-components-inputs';
import { first, isArray, last, range, sortBy } from 'lodash-es';
import { AsyncPipe, formatDate, NgTemplateOutlet } from '@angular/common';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CalendarComponent, Day } from '../../calendar/calendar.component';
import { SchooljaarHistorieOption } from '../../schooljaar-historie-filter/schooljaar-historie-option';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { DashboardVariant } from '../../services/weergave-opties';

export enum DateRangeView {
	MAAND = 'Maand',
	WEEK = 'Week',
	DAG = 'Dag',
	AFGELOPEN = 'Afgelopen...',
	AANGEPAST = 'Aangepast',
}

export interface DateRange {
	from: Date;
	to: Date;
}

export interface DateRangeState {
	range?: DateRange;
	view?: DateRangeView;
	relative?: number; // Als AFGELOPEN is gekozen, encodeer het aantal dagen zodat we dit dynamisch kunnen aanpassen
}

type CalendarData = { range?: DateRange; hoverRange?: DateRange };

@Component({
	selector: 'app-date-range-filter',
	templateUrl: './date-range-filter.component.html',
	styleUrl: './date-range-filter.component.scss',
	standalone: true,
	imports: [FormDropdownComponent, SingleSelectListComponent, NgTemplateOutlet, CalendarComponent, AsyncPipe],
})
export class DateRangeFilterComponent implements OnInit, OnDestroy, FilterComponent<DateRangeState> {
	// Velden voor FilterComponent
	filterName!: FilterName;
	inDropdown = false;
	searchInput: string | undefined;
	searchInputChange$: EventEmitter<string> | undefined;

	view = signal<DateRangeView | undefined>(undefined);
	range = signal<DateRange | undefined>(undefined);
	relative = signal<number | undefined>(undefined);

	hoverRange = signal<DateRange | undefined>(undefined);
	customRangeFrom = signal<Date | undefined>(undefined);
	calendarOpen = signal(false);
	calendarMonth$ = new BehaviorSubject(today());

	// Data die CalendarComponent doorgeeft aan generateDay om de kleuren van de cellen te bepalen
	calendarData$: Observable<CalendarData> = toObservable(computed(() => ({ range: this.range(), hoverRange: this.hoverRange() })));

	protected viewOptions: Option<DateRangeView>[] = Object.values(DateRangeView).map((value) => new Option(value));
	protected getViewOption = (view?: DateRangeView) => this.viewOptions.find((o) => o.value === view);

	private subscriptions: Subscription[] = [];

	aantalSchooljaren = signal(5);
	eindSchooljaar = signal(getSchooljaarTovHuidig());

	protected monthOptions: Signal<Option<DateRange>[]> = computed(() =>
		getSchooljarenRange(this.eindSchooljaar(), this.aantalSchooljaren())
			.flatMap((schooljaar) =>
				range(0, 12)
					.map((month) => addMonths(getSchooljaarBegin(schooljaar), month))
					.map((from) => ({ from, to: addDays(addMonths(from, 1), -1) }))
					.map((range) => new Option(range, formatDate(range.from, 'MMMM yyyy', 'nl-NL')))
			)
			.reverse()
	); // aflopend gesorteerd
	protected getMonthOption = (range?: DateRange) =>
		this.monthOptions().find((o) => range && o.value.from <= range.from && o.value.to >= range.from);

	protected relativeDateOptions: Option<number>[] = [7, 14, 30, 60, 90].map((dagen) => new Option(dagen, `${dagen} dagen`));
	protected getRelativeDateOption = (dagen?: number) => this.relativeDateOptions.find(({ value }) => value === dagen);

	private overlayRef: OverlayRef | undefined;

	protected prettyFormat = prettyFormat;

	@ViewChild('calendar')
	calendar!: TemplateRef<any>;

	/** Anchor voor de calendar dropdown */
	@ViewChild('anchor')
	anchor!: ElementRef<HTMLElement>;

	constructor(
		protected filterService: FilterService,
		private qp: QueryParamStateService,
		private readonly overlay: Overlay,
		private overlayPositionBuilder: OverlayPositionBuilder,
		private viewContainerRef: ViewContainerRef
	) {
		effect(() =>
			this.filterService.setFilterInput(this.filterName, <DateRangeState>{ view: this.view(), range: this.range(), relative: this.relative() })
		);
		effect(() => this.calendarMonth$.next(this.range()?.from ?? minDate(getSchooljaarEind(this.eindSchooljaar()), today())));
	}

	ngOnInit(): void {
		const schooljaarFilters = this.filterService.configs[this.filterName]?.componentParams;
		if (schooljaarFilters) this.subscribeSchooljaarFilters(<DateRangeComponentParams>schooljaarFilters);

		this.subscriptions.push(
			this.filterService.observeAsInput(this.filterName).subscribe((value: DateRangeState | undefined) => {
				this.view.set(value?.view);
				this.range.set(value?.range);
				this.relative.set(value?.relative);
			})
		);
	}

	private subscribeSchooljaarFilters(schooljaarFilters: DateRangeComponentParams) {
		const { schooljaarActueelFilterName, periodeAangepastFilterName, periodeFilterName, schooljaarIndicatorFilterName } = schooljaarFilters;
		if (!schooljaarActueelFilterName) return;

		this.subscriptions.push(
			combineLatest([
				this.qp.observe('variant'),
				this.qp.observe('indicator-over'),
				...[schooljaarActueelFilterName, periodeFilterName, periodeAangepastFilterName, schooljaarIndicatorFilterName].map((filterName) =>
					filterName ? this.filterService.observeAsInput(filterName).pipe(startWith(undefined), distinctUntilChanged()) : of(undefined)
				),
			]).subscribe(([variant, indicatorOver, schooljaarActueel, periode, periodeAangepast, schooljaarIndicator]) =>
				this.updateSchooljaren(variant, indicatorOver, schooljaarActueel, periode, periodeAangepast, schooljaarIndicator)
			)
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((s) => s.unsubscribe());
		this.closeCalendar();
	}

	private updateSchooljaren(
		variant: DashboardVariant,
		indicatorOver: number,
		schooljaarActueel: string,
		periode: PeriodeFilterValue,
		periodeAangepast: string[],
		schooljaarIndicator: string
	) {
		if (schooljaarIndicator && indicatorOver) {
			this.eindSchooljaar.set(schooljaarIndicator);
			this.aantalSchooljaren.set(Math.ceil(indicatorOver));
		} else if (
			variant === DashboardVariant.HISTORIE &&
			periode?.option === SchooljaarHistorieOption.AANGEPAST &&
			isArray(periodeAangepast) &&
			periodeAangepast.length
		) {
			const sorted = sortBy(periodeAangepast);
			const eerste = first(sorted)!;
			const laatste = last(sorted)!;
			this.eindSchooljaar.set(laatste);
			this.aantalSchooljaren.set(getBeginjaar(laatste) - getBeginjaar(eerste));
		} else if (variant === DashboardVariant.HISTORIE && periode) {
			this.eindSchooljaar.set(getSchooljaarTovHuidig(periode.inclHuidig ? 0 : -1));
			const matches = periode.option.match(/Afgelopen (\d+) schooljaren/);
			if (matches) this.aantalSchooljaren.set(Number(matches[1]));
		} else if (schooljaarActueel) {
			this.eindSchooljaar.set(schooljaarActueel);
			this.aantalSchooljaren.set(1);
		}

		// Als de huidige range buiten het schooljaar valt, maak dan de selectie leeg
		const range = this.range();
		const eind = getSchooljaarEind(this.eindSchooljaar());
		if (range && (range.from > eind || range.to <= addYears(eind, -this.aantalSchooljaren() - 1))) this.range.set(undefined);
	}

	protected setView(view: DateRangeView) {
		const convert = (range: DateRange) => {
			switch (view) {
				case DateRangeView.MAAND:
					return this.getMonthOption(range)?.value;
				case DateRangeView.WEEK:
					return {
						from: addDays(range.from, -(range.from.getDay() + 6) % 7),
						to: addDays(range.from, 6 - ((range.from.getDay() + 6) % 7)),
					};
				case DateRangeView.DAG:
					return { from: range.from, to: range.from };
				default:
					return range;
			}
		};

		this.view.set(view);
		if (view === DateRangeView.AFGELOPEN) {
			const range = this.range();
			const option =
				this.relativeDateOptions.find(({ value }) => range && range.from >= addDays(today(), -value + 1)) ?? this.relativeDateOptions[0];
			this.setRelative(option.value);
		} else {
			this.range.update((current) => (current ? convert(current) : convert(defaultDateRange())));
			this.relative.set(undefined);
		}
	}

	protected setRelative(dagen: number) {
		this.relative.set(dagen);
		this.range.set(getRelativeDateRange(dagen));
	}

	toggleOpenCalendar(event: Event) {
		if (!this.calendarOpen()) {
			this.overlayRef = this.overlay.create({
				positionStrategy: this.getPositionStrategy(),
				hasBackdrop: true,
				backdropClass: 'cdk-overlay-transparent-backdrop',
			});
			this.overlayRef.attach(new TemplatePortal(this.calendar, this.viewContainerRef));
			this.overlayRef.backdropClick().subscribe(() => this.closeCalendar());
			this.calendarOpen.set(true);
		} else this.closeCalendar();
		this.hoverRange.set(undefined);
		event.preventDefault();
		event.stopPropagation();
	}

	closeCalendar() {
		this.calendarOpen.set(false);
		this.overlayRef?.dispose();
	}

	private getPositionStrategy() {
		return this.overlayPositionBuilder
			.flexibleConnectedTo(this.anchor)
			.withFlexibleDimensions(false)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
			]);
	}

	generateDay(date: Date, { range, hoverRange }: CalendarData): Day {
		return {
			date: new Date(date),
			classNames: {
				selected: range && range.from <= date && range.to >= date,
				hovered: hoverRange && hoverRange.from <= date && hoverRange.to >= date,
				clickable: true,
			},
		};
	}

	selectDate(date: Date) {
		if (this.view() === DateRangeView.AANGEPAST) {
			const from = this.customRangeFrom();
			if (from) {
				if (date >= from) {
					this.range.set({ from, to: date });
					this.customRangeFrom.set(undefined);
					this.closeCalendar();
				} else {
					this.customRangeFrom.set(date);
				}
			} else {
				this.customRangeFrom.set(date);
			}
		} else {
			this.range.set(this.getRange(date));
			this.closeCalendar();
		}
	}

	hoverDate(date: Date | null) {
		if (this.view() === DateRangeView.AANGEPAST) {
			const from = this.customRangeFrom();
			if (!date) return;
			this.hoverRange.set(from ? { from, to: maxDate(from, date) } : { from: date, to: date });
		} else {
			this.hoverRange.set(date ? this.getRange(date) : undefined);
		}
	}

	private getRange(date: Date) {
		if (this.view() === DateRangeView.WEEK) {
			// zondag = 0, dus +6 % 7 => 0 = maandag
			const dagenNaMaandag = (date.getDay() + 6) % 7;
			return { from: addDays(date, -dagenNaMaandag), to: addDays(date, 6 - dagenNaMaandag) };
		}
		return { from: date, to: date };
	}

	nextMonth = () => this.calendarMonth$.next(addMonths(this.calendarMonth$.value, 1));
	previousMonth = () => this.calendarMonth$.next(addMonths(this.calendarMonth$.value, -1));

	isNextMonthEnabled = this.calendarMonth$.pipe(map((value) => addMonths(value, 1) <= getSchooljaarEind(this.eindSchooljaar())));
	isPreviousMonthEnabled = this.calendarMonth$.pipe(
		map((value) => addMonths(value, -1) > addYears(getSchooljaarEind(this.eindSchooljaar()), -this.aantalSchooljaren()))
	);

	protected readonly DateRangeView = DateRangeView;
}

function defaultDateRange() {
	const from = today();
	from.setDate(1);
	const to = addDays(addMonths(from, 1), -1);
	return { from, to };
}

function prettyFormat(range: DateRange | undefined, showWeek = false) {
	if (!range?.from || !range.to) return undefined;

	if (showWeek) {
		const { week: fromWeek, year: fromYear } = getISOWeekAndYear(range.from);
		const { week: toWeek, year: toYear } = getISOWeekAndYear(range.to);
		// zelfde week en precies 6 dagen verschil => range beslaat de hele week
		if (fromWeek === toWeek && fromYear === toYear && dateDiffInDays(range.from, range.to) === 6) return `Wk ${fromWeek} ${fromYear}`;
	}

	const toFormatted = formatDate(range.to, 'd MMM yyyy', 'nl-NL');
	if (range.from.getFullYear() === range.to.getFullYear()) {
		if (range.from.getMonth() === range.to.getMonth()) {
			if (range.from.getDate() === range.to.getDate()) return toFormatted;
			return `${range.from.getDate()} t/m ${toFormatted}`;
		}
		return `${formatDate(range.from, 'd MMM', 'nl-NL')} t/m ${toFormatted}`;
	}

	return `${formatDate(range.from, 'd MMM yyyy', 'nl-NL')} t/m ${toFormatted}`;
}

export function formatDateRangeState(state: DateRangeState): string | undefined {
	if (!state?.view || !state?.range?.from || !state?.range?.to) return undefined;
	if (state.view === DateRangeView.MAAND) {
		return formatDate(state.range.from, 'MMMM yyyy', 'nl-NL');
	} else if (state.view === DateRangeView.AFGELOPEN) {
		return `Afgelopen ${state.relative} dagen`;
	}
	return prettyFormat(state.range, state.view === DateRangeView.WEEK);
}

export function getRelativeDateRange(dagen: number) {
	return { from: addDays(today(), -dagen + 1), to: today() };
}
