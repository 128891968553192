import {
	ColumnDef,
	createDefaultCellDef,
	createDefaultFooterCellDef,
	createDefaultHeaderCellDef,
	DEFAULT_FORMATS,
	TableModel,
} from '../../components/table/table/table.model';
import { Sort } from '../../components/table/table/table.component';
import { ColumnOptions, ColumnType, DataRow, DataTreeTableComponent, TOTAAL_LABEL } from './data-tree-table';
import { Attributes, BaseDashboardConfig } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { FilterService } from '../../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AttrPath, ExportDataOptions } from '../../../services/data.service';
import { Directive, inject, ViewChild } from '@angular/core';
import { VPartitionData } from '../vbarchart-table/vbar-series/vbar-series.component';
import { VbarSeriesData } from '../vbarchart-table/vbar-batch/vbar-batch.component';
import { VBarExportColumnDef } from '../vbarchart-table/vbarchart-table.component';
import { attrLabel } from '../../../services/labels';
import { isFirstAtLevel } from '../../../services/data-tree';
import { DisplayService } from '../../../services/display.service';
import { switchMap } from 'rxjs/operators';
import { LabelCellComponent } from '../../components/table/cells/label-cell/label-cell.component';
import { isFunction } from 'lodash-es';
import { PageStateService } from '../../../services/page-state.service';

function getFirstGroupColumnName(model: TableModel<DataRow<any>>): string | undefined {
	return model?.columnDefs[0].type === ColumnType.GROUP ? model.columnDefs[0].column : undefined;
}

const defaultGroupColumnOptions: ColumnOptions<any, any> = {
	component: LabelCellComponent,
	dataType: 'string',
};

@Directive()
export abstract class DataTreeTableConfig<I extends Attributes, A extends Attributes> extends BaseDashboardConfig<I, A> {
	protected displayService: DisplayService = inject(DisplayService);

	protected pageStateService: PageStateService = inject(PageStateService);

	protected constructor(
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	createGroupingColumns(context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		const groupNames = context.groupNames;

		const flexStart = this.getFixedBeforeGroups();
		const flexEnd = groupNames.length - this.getFixedAfterGroups();
		const flexibleGroups = flexEnd - flexStart;

		const isFlexible = (ix: number) => flexStart <= ix && ix < flexEnd;
		const columns = groupNames.map((attr, ix) => this.createGroupingColumn(attr, ix + 1, isFlexible(ix)));

		if (Math.max(flexibleGroups, 0) < this.flexibleMaxGroups && !this.isHistorieBatchVariant())
			columns.splice(flexEnd, 0, this.createAddGroupColumn());

		return columns;
	}

	/**
	 * Geeft aan of dashboard van het sub-type historie batch is. Bepaald waar de plus icoon komt.
	 */
	isHistorieBatchVariant() {
		return false;
	}

	/**
	 * Geeft het aantal vaste groeperingen aan het einde van de groeperingskolommen aan.
	 * Gebruik deze om de waarde te wijzigen op basis van extra randvoorwaarden.
	 */
	protected getFixedAfterGroups() {
		return this.fixedAfterGroups;
	}

	/**
	 * Geeft het aantal vaste groeperingen aan het begin van de groeperingskolommen aan.
	 * Gebruik deze om de waarde te wijzigen op basis van extra randvoorwaarden.
	 */
	protected getFixedBeforeGroups() {
		return this.fixedBeforeGroups;
	}

	/**
	 * NB moet een stabiele lijst opleveren (dus zo nodig memoizen)
	 */
	public getAvailableGroups() {
		return this.availableGroups;
	}

	private createAddGroupColumn(): ColumnDef<DataRow<A>> {
		return {
			column: 'addGroup',
			header: {
				...createDefaultHeaderCellDef('addGroup'),
				getValue: () => undefined,
				headerType: 'addGroup',
			},
			body: {
				...createDefaultCellDef('addGroup'),
			},
			footer: {
				...createDefaultFooterCellDef(),
			},
			sortable: false,
			visible: true,
			sticky: false,
		};
	}

	protected createGroupingColumn(
		group: string,
		ix: number,
		groeperingMenu: boolean,
		options: Partial<ColumnOptions<I, A>> = {}
	): ColumnDef<DataRow<A>> {
		const { component, format, header, headerClass, clickHandler, dataType } = { ...defaultGroupColumnOptions, ...options };
		const attrPath = <AttrPath>group.split('.');
		return {
			column: group,
			header: {
				...createDefaultHeaderCellDef<DataRow<A>>(group, header ?? attrLabel(attrPath)),
				class: headerClass ?? 'group-column',
				headerType: groeperingMenu ? 'groepering' : 'default',
			},
			body: {
				...createDefaultCellDef<DataRow<A>>(group),
				component,
				getValue: ({ _path }) => this.displayService.display(_path[ix].k, attrPath),
				isVisible: ({ _path }) => isFirstAtLevel(_path, ix),
				getClassName: (row) => (row._path[ix].k === null ? 'empty' : undefined),
				format: isFunction(format) ? (row) => format(row._path) : format,
				dataType: isFunction(dataType) ? (row) => dataType(row._path) : dataType,
				clickHandler,
			},
			footer: {
				...createDefaultFooterCellDef<DataRow<A>>(),
				getValue: () => (ix === 1 && this.showTotaalFooter() ? TOTAAL_LABEL : null),
			},
			type: ColumnType.GROUP,
			sortable: true,
			visible: true,
			sticky: false,
		};
	}

	createMeasureColumns(_context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		return [];
	}

	enrichTableModel(_tableModel: TableModel<DataRow<A>>) {
		//Override voor child
	}

	getDefaultSort(tableModel: TableModel<DataRow<A>>): Sort {
		return { active: getFirstGroupColumnName(tableModel)!, direction: 'asc' };
	}

	columnOrder: ColumnType[] = [ColumnType.GROUP, ColumnType.BARCHART, ColumnType.MEASURE];

	/**
	 * Beschikbare groeperingen waar een gebruiker uit kan kiezen in de groeperingsdropdown.
	 */
	availableGroups: AttrPath[] = [];

	/**
	 * Aantal vaste groepen aan het begin van de lijst met groeperingskolommen.
	 */
	fixedBeforeGroups: number = 0;

	/**
	 * Aantal vaste groepen aan het einde van de lijst met groeperingskolommen.
	 */
	fixedAfterGroups: number = 0;

	/**
	 * Het maximale aantal groeperingen dat gekozen mag worden.
	 */
	flexibleMaxGroups: number = Number.MAX_VALUE;

	/**
	 * Mogen groeperingen verwijderd worden of altijd verplicht aanwezig.
	 */
	flexibleGroupsRemovable: boolean = true;

	getAlternatingGroupLevel() {
		return 1;
	}

	showTotaalFooter() {
		return true;
	}

	// Inject het gebruikte DataTreeTableComponent zodat we dit kunnen gebruiken voor de tabel-export.
	// NB subclasses van DataTreeTableComponent moeten met "providers" in hun decorator aangeven dat ze een DataTreeTableComponent zijn,
	// want de dependency injection van Angular heeft geen weet van inheritance.
	@ViewChild(DataTreeTableComponent)
	protected dataTreeTableComponent?: DataTreeTableComponent<I, A, DataTreeTableConfig<I, A>>;

	exportAsTable(options: ExportDataOptions): Observable<Blob> {
		if (this.dataTreeTableComponent) return this.dataTreeTableComponent.exportAsTable(options);
		return super.exportAsTable(options);
	}

	exportToPng(options: ExportDataOptions): Observable<Blob> {
		return this.dataTreeTableComponent!.forceTotalRender().pipe(switchMap(() => super.exportToPng(options)));
	}

	exportToPdf(options: ExportDataOptions): Observable<Blob> {
		return this.dataTreeTableComponent!.forceTotalRender().pipe(switchMap(() => super.exportToPdf(options)));
	}

	getExportTablePartitionColumns(partition: VPartitionData, ix: number, getValue: (rowModel: DataRow<A>) => any): VBarExportColumnDef<A>[] {
		return [
			{
				name: partition.label,
				type: partition.measure.type,
				format: partition.measure.format ?? DEFAULT_FORMATS[partition.measure.type],
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) => seriesData.series[ix].measure.value,
			},
		];
	}
}
