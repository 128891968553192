import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestLoggingService } from '../services/rest.logging.service';

@Injectable()
export class DashboardNameInterceptor implements HttpInterceptor {
	constructor(private restLoggingService: RestLoggingService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let currentDashboard = this.restLoggingService.currentDashboard;
		if (currentDashboard !== null) {
			let clone = req.clone({
				headers: req.headers.set('X-Dashboard', currentDashboard),
			});

			return next.handle(clone);
		}

		return next.handle(req);
	}
}
