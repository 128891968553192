import { inject, Injectable } from '@angular/core';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { VERSION } from '@cumlaude/version-generator';

@Injectable({
	providedIn: 'root',
})
export abstract class BugsnagService {
	protected readonly envConfig: EnvConfiguration = inject(ENV_CONFIG);

	notify(error: NotifiableError) {
		if (!this.envConfig.bugsnagApiKey) return;

		Bugsnag.notify(error);
	}
}

function safeParseJSON<T>(value: string | undefined | null) {
	if (!value) {
		return null;
	}

	let result: any;
	try {
		result = JSON.parse(value);
	} catch (e) {
		// Invalid JSON
	}
	return result as T;
}

interface FailedRequestInformation {
	url: string;
	statusCode: number;
	response: string;
}

export type AppType = 'User Frontend' | 'Admin Frontend';

export function enableBugsnag(envConfig: EnvConfiguration, appType: AppType) {
	if (!envConfig.bugsnagApiKey) return;

	Bugsnag.start({
		apiKey: envConfig.bugsnagApiKey,
		appType: appType,
		appVersion: VERSION || '0.0.0',
		releaseStage: envConfig.environmentName,
		enabledReleaseStages: ['production', 'test', 'acceptance', 'development', 'nightly', 'lokaal'],
		collectUserIp: false,
		onError: (event) => {
			event.clearMetadata('angular', 'component');
			event.clearMetadata('angular', 'context');
			if (event.request.url) event.request.url = stripFiltersFrontend(event.request.url);

			return !event.errors.some((error) => safeParseJSON<FailedRequestInformation>(error.errorMessage)?.statusCode === 401);
		},
		onBreadcrumb: (breadcrumb) => {
			switch (breadcrumb.type) {
				case 'request':
					breadcrumb.metadata['request'] = stripFiltersBackend(breadcrumb.metadata['request']);
					break;
				case 'navigation':
					if (breadcrumb.metadata['to']) breadcrumb.metadata['to'] = stripFiltersFrontend(breadcrumb.metadata['to']);
					if (breadcrumb.metadata['from']) breadcrumb.metadata['from'] = stripFiltersFrontend(breadcrumb.metadata['from']);
					break;
			}
			return true;
		},
	});
}

const allowlist = [
	'g',
	'adviesType',
	'aspect',
	'afwijkingpercentiel',
	'brin-overgang',
	'cijfersseceweergave',
	'cijfertype',
	'cohortrendementtype',
	'cohortrendementweergave',
	'col',
	'doorstroomweergave',
	'dossier',
	'eenheid',
	'indicator-over',
	'inspectie-data',
	'interval',
	'kenmerk',
	'or-uitsluiten',
	'selectie',
	'schooljaar',
	'sortOrder',
	'tekortpunten',
	'tijdseenheid',
	'threshold',
	'toon-trend',
	'uitstroomiqweergave',
	'vakOrder',
	'variant',
];

function stripFiltersFrontend(input: string) {
	const [path, query] = input.split('?');
	if (!query) return path;

	const redacted = query
		.split('&')
		.map((kv) => {
			const [key, val] = kv.split('=');
			if (allowlist.includes(key)) return `${key}=${val}`;
			else return `${key}=REDACTED`;
		})
		.join('&');

	return `${path}?${redacted}`;
}

function stripFiltersBackend(input: string) {
	return input
		.replaceAll(/\bf=[^&]*/g, 'f=REDACTED')
		.replaceAll(/\bof=[^&]*/g, 'of=REDACTED')
		.replaceAll(/\bpf=[^&]*/g, 'pf=REDACTED');
}
