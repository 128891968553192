import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isUndefined } from 'lodash-es';
import { map } from 'rxjs/operators';
import { Attr, DataService } from '../../../../services/data.service';
import { AsyncPipe } from '@angular/common';
import { PasfotoComponent } from '../../../components/pasfoto/pasfoto.component';

interface Data {
	[key: string]: string;
}

@Component({
	selector: 'app-card-cell-header',
	templateUrl: './card-cell-header.component.html',
	styleUrls: ['./card-cell-header.component.scss'],
	standalone: true,
	imports: [PasfotoComponent, AsyncPipe],
})
export class CardCellHeaderComponent implements OnInit {
	@Input()
	rowKey: string | undefined;

	@Input()
	fotoKey: Attr[][] | undefined;

	@Input()
	title!: string;

	@Input()
	subtitle: string | undefined;

	@Input()
	alt!: string;

	pasfoto!: Observable<string | null>;

	constructor(private dataService: DataService) {}

	ngOnInit() {
		if (!isUndefined(this.rowKey)) {
			const key = Number(this.rowKey);
			// TODO pasfoto's worden hier expres niet meer opgehaald, fix in CL-1165
			if (0 && this.fotoKey) {
				this.pasfoto = this.dataService.getMedewerkerDetails<Data>(this.fotoKey!, key).pipe(
					map((value) => {
						const row = value.rows[0];
						if (isUndefined(row)) return null;
						return row[this.fotoKey!.join('.')]!;
					})
				);
			} else {
				this.pasfoto = of(null);
			}
		}
	}
}
