export const removeFromQp = {
	sortOrder: null,
	selectie: null,
	schooljaar: null,
	subject: null,
	prognose: null,
	sf: null,
	sp: null,
	from: null,
	g: null,
	ds_nm_bbs_uitzondering_van: null,
	ds_nm_obs_uitzondering_van: null,
	ds_nm_op_uitzondering_van: null,
	ds_nm_plaatsing_uitzondering: null,
	ds_nm_prestatieanalyse_vso_uitzondering: null,
	ds_nm_einduitstroom_vso_uitzondering: null,
	ds_nm_tussentijdse_uitstroom_vso_uitzondering: null,
	ds_fun_uitstroom_iq_status: null,
};

export const keepSelectie = {
	sortOrder: null,
	prognose: null,
	g: null,
	ds_nm_bbs_uitzondering_van: null,
	ds_nm_obs_uitzondering_van: null,
	ds_nm_op_uitzondering_van: null,
	ds_nm_plaatsing_uitzondering: null,
	ds_nm_prestatieanalyse_vso_uitzondering: null,
	ds_nm_einduitstroom_vso_uitzondering: null,
	ds_nm_tussentijdse_uitstroom_vso_uitzondering: null,
	ds_fun_uitstroom_iq_status: null,
};
