import { last, sortBy, union } from 'lodash-es';
import { Level, PartialLevel, PartialPath, Path } from './data-tree';

/**
 * Input "root" is een (sub)boom geproduceerd door unnest met pivot.
 * Deze heeft in "r" de rijen van de tabel, van de vorm:
 * [L0, L1, ..., Lp]
 * waarbij Lp het pivot level is.
 *
 * Elke rij heeft op zijn laagste level in "xr" de kolommen, van de vorm:
 * [L0, L1, ..., Lp, ..., Lq]
 *
 * Tabulate zorgt ervoor dat deze kolommen uitlijnen, dwz elke kolom met dezelfde waardes voor
 * [Lp+1, ..., Lp+c]
 * krijgt dezelfde "i" positie op het diepste level (Lq).
 * Hierbij wordt c bepaald door colKeys:
 * - als colKeys niet ingevuld is, resulteren alle verschillende [Lp+1,...,Lq] waarden in een kolom
 * - als colKeys een getal is (c), resulteren alle verschillende [Lp+1,...,Lp+c] waarden in een kolom
 * - als colKeys een functie is, wordt deze functie achtereenvolgens aangeroepen op
 *   - ['root']
 *   - ['root',Lp+1]
 *   - ['root,Lp+1,Lp+2]
 *   - ... net zo lang tot deze true oplevert
 * De ontwikkelaar geeft hiermee te kennen dat deze waardes bepalend zijn voor alles wat daarna volgt,
 * dwz voor elke rij is er precies 1 record met die waardes in de Lp+1,...Lp+c attributen.
 *
 * Bijvoorbeeld:
 * - De rijen hebben attributen [huidig niveau, huidig leerjaar].
 * - De overige attributen zijn [volgend niveau, volgend schooljaar, IDU].
 * In principe is het zo dat binnen elke rij [volgend niveau, volgend schooljaar] bepalend zijn voor IDU.
 * Dit kan je meegeven door te zeggen colKeys=2. Er komt dan binnen de kolommen niet ook nog eens een
 * onderverdeling naar IDU.
 * MAAR als volgend niveau of volgend schooljaar NULL zijn geldt het niet dat IDU vastligt; er zouden dan
 * best meerdere records kunnen zijn voor verschillende IDU. Om dit aan te kunnen geven is er de
 * mogelijkheid voor een custom "colKeys" functie.
 *
 * Om de "i" posities te bepalen worden alle [Lp+1, ..., Lp+c] paden samengevoegd tot één boom; deze krijgt
 * opeenvolgende getallen op de leaves.
 * (eventueel kan een voorberekende boom aan de functie meegegeven worden in "sort", zo niet berekent de functie hem zelf
 * door stap voor stap bomen te mergen, waarbij optioneel een sorteerfunctie meegegeven kan worden).
 * Deze boom wordt ook als output teruggegeven.

 * LET OP: van inputboom "root" (en evt. "sort") worden de "i" waarden aangepast.
 */
export function tabulate<A>(
	root: Level<A, number[]>,
	sort?: Level<A, number[]> | ColSortFunction, // if it's a Level, modifies leaves!
	colKeys?: number | ColKeyFunction,
	aggregateCombine?: (c: Level<A, number[]>[], path: PartialPath<A, number[]>, all: A | undefined, allFiltered: A | undefined) => A | undefined
): Level<A, number[]> {
	const isColKey = getColKeyFunction(colKeys);
	if (!aggregateCombine) aggregateCombine = () => undefined;
	let order: Level<A, number[]>;
	if (typeof sort === 'undefined' || typeof sort === 'function') {
		const first = last(root.r[0])!;
		order = { k: 'root', i: 0, c: first.c, a: first.a, r: [], v: new Map() };
		top([order], order, isColKey);
		for (const row of root.r.slice(1)) {
			mergeTrees([order], order, last(row)!, isColKey, sort, aggregateCombine);
		}
	} else {
		order = sort;
	}
	numberLeaves(order);

	for (const row of root.r) {
		// houdt per rij vast welke posities al in gebruik zijn, voor foutdetectie
		const seen: { [pos: number]: string } = {};

		// alleen in gebruik voor error message
		const rowKeys = row
			.map((lvl) => lvl.k)
			.slice(1)
			.join(';');

		for (const col of last(row)!.xr!) {
			const path = getColKeyPath([{ k: 'root', c: [], v: [], i: 0, r: [], a: undefined }, ...col.slice(row.length)], isColKey).slice(1);
			const pos = lookup(order, path)?.i;
			if (pos === undefined) throw new Error(`path does not exist in tree (could not find [${path.map((lvl) => lvl.k).join(';')}])`);

			// start foutdetectie
			const colKeys = col
				.slice(row.length)
				.map((lvl) => lvl.k)
				.join(';');
			if (pos in seen) {
				const msg = `in row [${rowKeys}]: column position ${pos} for [${colKeys}] already taken by [${seen[pos]}]`;
				// Voorlopig alleen loggen, zodat we beter kunnen analyseren waar dit zoal voorkomt.
				// In de toekomst (of op alles behalve productie?) is een error gooien beter, zodat we het snel opmerken.
				console.error(`ERROR: ${msg}`);
			}
			seen[pos] = colKeys;
			// end foutdetectie

			last(col)!.i = pos;
		}
	}
	return order;
}

export type ColKeyFunction = (colPath: (string | null)[]) => boolean;

export type ColSortFunction = (colPath: (string | null)[], keys0: (string | null)[], keys1: (string | null)[]) => (string | null)[];

function getColKeyFunction(colKeys?: number | ColKeyFunction): ColKeyFunction {
	switch (typeof colKeys) {
		case 'undefined':
			return () => false;
		case 'number':
			return (colPath) => colPath.length >= colKeys + 1; // path always starts with "root" (not counted in key size)
		default:
			return colKeys;
	}
}

function getColKeyPath<A, D>(colPath: Path<A, D>, isColKey: ColKeyFunction): Path<A, D> {
	const keys = [];
	const ret = [];
	for (const lvl of colPath) {
		if (isColKey(keys)) return ret;
		keys.push(lvl.k);
		ret.push(lvl);
	}
	return ret;
}

/**
 * kapt een boom af
 */
function top<A, D>(ret: PartialPath<A, D>, input: Level<A, D>, isColKey: ColKeyFunction): void {
	const colPath = ret.map((lvl) => lvl.k);
	const lvl = last(ret)!;
	lvl.a = input.a;
	if (isColKey(colPath) || input.c.length == 0) {
		lvl.r = [<Path<A, D>>ret];
		return;
	}

	const c: Level<A, D>[] = [];
	const r: Path<A, D>[] = [];
	for (const child of input.c) {
		const newchild = { ...child, c: [], r: [] };
		top([...ret, newchild], child, isColKey);
		c.push(newchild);
		r.push(...newchild.r);
	}
	lvl.c = c; // hier pas doen omdat input hetzelfde object kan zijn als lvl
	lvl.r = r; // idem
}

/**
 * Vindt een leaf (of subtree) op basis van de keys van het pad ernaartoe.
 * (Dit pad kan uit een andere boom komen.)
 */
export function lookup<A, D>(tree: Level<A, D>, path: Path<unknown, unknown>): Level<A, D> | undefined {
	const [first, ...rest] = path;
	if (first === undefined) return tree;

	const child = tree.c.find((lvl) => lvl.k === first.k);
	if (!child) return;

	return lookup(child, rest);
}

export function lookupByKeys<A, D>(tree: Level<A, D>, keys: string[]): Level<A, D> | undefined {
	const [first, ...rest] = keys;
	if (first === undefined) return tree;

	const child = tree.c.find((lvl) => lvl.k === first);
	if (!child) return;

	return lookupByKeys(child, rest);
}

export function lookupPath<A, D>(tree: Level<A, D>, path: Path<unknown, unknown>): Path<A, D> | undefined {
	const [first, ...rest] = path;
	if (first === undefined) return [];

	const child = tree.c.find((lvl) => lvl.k === first.k);
	if (!child) return;

	const childPath = lookupPath(child, rest);
	if (childPath === undefined) return;

	return [child, ...childPath];
}

function* integers(): Generator<number, number> {
	let i = 0;
	while (true) yield i++;
}

/**
 * Vul de "i" van de leaves met opeenvolgende getallen.
 */
function numberLeaves<A, D>(tree: Level<A, D>, numbers = integers()): void {
	if (tree.c.length === 0) tree.i = numbers.next().value;
	else tree.c.forEach((child) => numberLeaves(child, numbers));
}

/**
 * Voegt alle paden van de twee input-bomen samen tot een nieuwe boom.
 * Bij aanwezige maxDepth worden deze paden afgekapt.
 * Resultaat heeft [] als leaves.
 */
function mergeTrees<A, D>(
	ret: PartialPath<A, D>,
	in0: Level<A, D>,
	in1: Level<A, D>,
	isColKey: ColKeyFunction,
	sortFunction: ColSortFunction | undefined,
	aggregateCombine: (c: Level<A, D>[], path: PartialPath<A, D>, all?: A | undefined, allFiltered?: A | undefined) => A | undefined
): void {
	const colPath = ret.map((lvl) => lvl.k);
	const lvl = last(ret)!;
	if (isColKey(colPath) || (in0.c.length == 0 && in1.c.length == 0)) {
		lvl.r = [<Path<A, D>>ret];
		lvl.a = aggregateCombine([in0, in1], ret);
		return;
	}

	const in0keys = in0.c.map((lvl) => lvl.k);
	const in1keys = in1.c.map((lvl) => lvl.k);

	if (!sortFunction) sortFunction = (colPath, keys0, keys1) => sortBy(union(keys0, keys1));
	const allKeysSorted = sortFunction(colPath, in0keys, in1keys);

	let i = 0;
	const c = [];
	const r = [];
	for (const k of allKeysSorted) {
		const newchild: PartialLevel<A, D> = { k, i: i++, c: [], r: [], v: new Map() };
		if (in0keys.includes(k) && in1keys.includes(k)) {
			mergeTrees(
				[...ret, newchild],
				in0.c.find((lvl) => lvl.k === k)!,
				in1.c.find((lvl) => lvl.k === k)!,
				isColKey,
				sortFunction,
				aggregateCombine
			);
		} else if (in0keys.includes(k)) {
			top([...ret, newchild], in0.c.find((lvl) => lvl.k === k)!, isColKey);
		} else if (in1keys.includes(k)) {
			top([...ret, newchild], in1.c.find((lvl) => lvl.k === k)!, isColKey);
		}
		c.push(<Level<A, D>>newchild);
		r.push(...newchild.r!);
	}
	lvl.c = c; // hier pas doen omdat in0/in1 hetzelfde object kan zijn als lvl
	lvl.r = r; // idem
	lvl.a = aggregateCombine(lvl.c, ret);
}
