import { Component, computed, input, OnInit, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { Level, Path, pathTo } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { ColumnDef, createColumnDef, createDefaultFooterCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { MultiAggregator, SingleAggregator, sumIf, sumOver, weightedAverage } from '../../services/aggregation';
import { FilterName } from '../../services/filter-config';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow, DataTreeTableComponent } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { last, memoize } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { DashboardAspect } from '../../services/weergave-opties';
import { VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { ScoreHbarSeriesData, ScoreSeriesHbarComponent } from './score-series-hbar/score-series-hbar.component';
import { ScoreSchaalverdelingComponent } from './score-schaalverdeling/score-schaalverdeling.component';
import { ReferentieNiveau } from '../../services/label-enums';
import { formatPercent } from '@angular/common';
import { deelVeilig } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { ToggleButtonsComponent } from '@cumlaude/shared-components-buttons';
import { att, count_records, percOfParent, percOfRow } from '../../services/measures';
import { getNiveauLabel, getNiveaus, vaardigheid2route } from '../../services/vaardigheden';
import { Vaardigheid } from '@cumlaude/metadata';

interface BasisvaardigheidI extends Attributes {
	bv_nm_toetsmoment: string;
	bv_fun_referentieniveau_bin: string;
	bv_nr_referentieniveau: number | null;
	bv_nr_verbeterd: number;
	bv_nr_niet_verbeterd: number;
}

interface BasisvaardigheidA extends Attributes {
	bv_nr_referentieniveau: number | null;
	bv_nr_verbeterd: number;
	bv_nr_niet_verbeterd: number;
	aantal_0f: number;
	aantal_1f: number;
	aantal_2f: number;
	aantal_3f: number;
	aantal_4f: number;
}

function aggAantal(score: number): SingleAggregator<BasisvaardigheidI, number> {
	return sumIf<'count_records', BasisvaardigheidI>(
		'count_records',
		(attrs: BasisvaardigheidI) => attrs.bv_fun_referentieniveau_bin === score.toString()
	);
}

function createPercentageExportColumn<Ai extends keyof A, A extends BasisvaardigheidA & { [ai in Ai]: number }>(attribute: Ai, header: string) {
	return createMeasureColumn<BasisvaardigheidI, A>(header, percOfParent(attribute), {
		dataType: 'percentage',
		visible: false,
		exportable: true,
	});
}

function isNotNull<T>(x: T | null): x is T {
	return x !== null;
}

@Component({
	selector: 'app-basisvaardigheid-overzicht',
	templateUrl: './basisvaardigheid-overzicht.component.html',
	styleUrls: ['./basisvaardigheid-overzicht.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		DataTreeTableComponent,
		VbarchartTableComponent,
		ToggleButtonsComponent,
	],
})
export class BasisvaardigheidOverzichtComponent extends BarchartTableConfig<BasisvaardigheidI, BasisvaardigheidA> implements OnInit {
	defaultGroups: AttrPath[] = [['bv_fk_ilt', 'ilt_nm_niveau'], ['bv_nr_leerjaar'], ['bv_fk_vs', 'vs_nm_vestiging']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['bv_fk_ilt', 'ilt_nm_niveau'],
		['bv_nr_leerjaar'],
		['bv_fk_vs', 'vs_nm_vestiging'],
		['bv_nm_aanbieder'],
		['bv_nm_toets'],
		['bv_fk_lb', 'lb_nm_klas'],
	];

	fixedSubgroups: AttrPath[] = [['bv_nm_toetsmoment'], ['bv_fun_referentieniveau_bin']];

	fixedSubgroupsVerdeling: AttrPath[] = [['bv_fun_referentieniveau_bin']];

	protected getFixedAfterGroups(): number {
		return this.aspect() === DashboardAspect.GEMIDDELDE ? 0 : 1;
	}

	actueelFilters: FilterName[] = [
		'bv_nm_schooljaar',
		'bv_fk_vs.vs_nm_vestiging',
		'bv_fk_ilt.ilt_nm_niveau',
		'bv_nr_leerjaar',
		'bv_nm_aanbieder',
		'bv_nm_toets',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = computed(() => [
		new BasicFilterExpression(['bv_nm_vaardigheid'], this.bv_nm_vaardigheid()),
		new BasicFilterExpression(['bv_is_eindtoets_po'], 0),
	]);

	aspect: Signal<DashboardAspect>;

	bv_nm_vaardigheid = input.required<Vaardigheid>();

	legendaExclude = computed(() => {
		let exclude = [ReferentieNiveau.GEMIDDELDE_LEERFASE];

		const vaardigheid = this.bv_nm_vaardigheid();
		if (vaardigheid === 'Rekenen') exclude.push(ReferentieNiveau.R4F);

		const aspect = this.aspect();
		if (aspect === 'Verdeling') exclude.push(ReferentieNiveau.NIVEAU_GEMIDDELDE);

		return exclude;
	});

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.aspect = qp.signal('aspect');
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)));
	}

	factTable = FactTable.basisvaardigheden;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getBasisvaardighedenData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getBasisvaardighedenExportData(options);
	}

	protected multiAggregators = <MultiAggregator<'bv_nr_referentieniveau', BasisvaardigheidI, BasisvaardigheidA, number | null>[]>[
		weightedAverage<BasisvaardigheidA, { bv_nr_referentieniveau: number | null }>('bv_nr_referentieniveau', 'bv_nr_referentieniveau'),
	];

	protected singleAggregators = {
		bv_nr_verbeterd: sumOver<'bv_nr_verbeterd', BasisvaardigheidI, number>('bv_nr_verbeterd'),
		bv_nr_niet_verbeterd: sumOver<'bv_nr_niet_verbeterd', BasisvaardigheidI, number>('bv_nr_niet_verbeterd'),
		aantal_0f: aggAantal(0),
		aantal_1f: aggAantal(1),
		aantal_2f: aggAantal(2),
		aantal_3f: aggAantal(3),
		aantal_4f: aggAantal(4),
	};

	createMeasureColumns(
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): ColumnDef<DataRow<BasisvaardigheidA>>[] {
		const exportColumns = [
			createPercentageExportColumn('aantal_0f', '<1F'),
			createPercentageExportColumn('aantal_1f', '1F'),
			createPercentageExportColumn('aantal_2f', '2F'),
			createPercentageExportColumn('aantal_3f', '3F'),
			this.bv_nm_vaardigheid() !== Vaardigheid.REKENEN ? createPercentageExportColumn('aantal_4f', '4F') : null,
			createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('Gemiddeld referentieniveau', att('bv_nr_referentieniveau'), {
				visible: false,
				exportable: true,
				format: '1.2-2',
			}),
		].filter(isNotNull);

		if (this.aspect() == DashboardAspect.GEMIDDELDE) {
			return [this.createGraphColumn(context), ...exportColumns];
		} else {
			return [
				...exportColumns,
				createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('Leerlingen', count_records),
				createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('% verbeterd', percOfRow('bv_nr_verbeterd'), {
					dataType: 'percentage',
					clickHandler: (row) => this.handleRedirect(row._path, context, [new BasicFilterExpression(['bv_nr_verbetering'], 0, '>')]),
					tooltip: (path) => {
						const verbeterd = percOfRow('bv_nr_verbeterd')(path);
						const niet_verbeterd = percOfRow('bv_nr_niet_verbeterd')(path);
						const onbekend = 1 - (verbeterd + niet_verbeterd);
						return [
							{ label: 'Verbeterd', value: formatPercent(verbeterd, 'nl_NL') },
							{ label: 'Onbekend', value: formatPercent(onbekend, 'nl_NL') },
							{ label: 'Niet verbeterd', value: formatPercent(niet_verbeterd, 'nl_NL') },
						];
					},
				}),
			];
		}
	}

	createGraphColumn(context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>) {
		// NB de naam "graph" zorgt voor extra styling in table.component.scss
		const graphColumn = createColumnDef(
			'graph',
			'',
			false,
			(row: DataRow<BasisvaardigheidA>) => this.getCellData(row._path, context, Boolean(Number(row._id) % 2)) as any
		);

		graphColumn.exportable = false;

		graphColumn.body.component = ScoreSeriesHbarComponent;

		graphColumn.header.component = ScoreSchaalverdelingComponent;
		graphColumn.header.getValue = () => getNiveaus(this.bv_nm_vaardigheid()).map((nr) => getNiveauLabel(nr));

		graphColumn.footer = {
			...createDefaultFooterCellDef(),
			component: ScoreSchaalverdelingComponent,
			getValue: () => getNiveaus(this.bv_nm_vaardigheid()).map((nr) => getNiveauLabel(nr)),
		};

		return graphColumn;
	}

	getCellData(
		path: Path<BasisvaardigheidA, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>,
		odd: boolean
	): ScoreHbarSeriesData {
		const scores = last(path)!.c.map((toetsmomentLvl) => {
			const bv_nm_toetsmoment = toetsmomentLvl.k;
			const { bv_nr_referentieniveau, count_records } = toetsmomentLvl.a;
			const tooltip = [
				...getNiveaus(this.bv_nm_vaardigheid()).map((nr) => ({
					label: getNiveauLabel(nr),
					value: this.getPercentage(toetsmomentLvl, nr),
				})),
				{
					label: '# lln',
					value: `${count_records}`,
				},
			];
			const linkData = this.createLinkData(pathTo(toetsmomentLvl), context);
			return { qty: bv_nr_referentieniveau, label: bv_nm_toetsmoment ?? '', tooltip, linkData };
		});

		return { lineHeightPx: 36, widthPx: 496, hoogsteNiveau: (getNiveaus(this.bv_nm_vaardigheid()).length - 1) as 3 | 4, scores, darker: !odd };
	}

	getPercentage(toetsmomentLvl: Level<BasisvaardigheidA, number[]>, niveau: number): string {
		const niveauLvl = toetsmomentLvl.c.find((niveauLvl) => niveauLvl.k === String(niveau));
		if (niveauLvl === undefined) return '-';

		const fraction = deelVeilig(niveauLvl.a.count_records, toetsmomentLvl.a.count_records);
		return formatPercent(fraction, 'nl_NL');
	}

	enrichTableModel(tableModel: TableModel<DataRow<BasisvaardigheidA>>) {
		tableModel.showFooters = this.aspect() === DashboardAspect.GEMIDDELDE;
	}

	getVerdelingGroups = memoize(BasisvaardigheidOverzichtComponent._getVerdelingGroups, JSON.stringify);

	private static _getVerdelingGroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups, ['bv_nm_toetsmoment']];
	}

	showTotaalFooter(): boolean {
		return false;
	}

	makeBar(
		attrs: BasisvaardigheidI,
		path: Path<BasisvaardigheidA, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): BarInfo {
		const { bv_fun_referentieniveau_bin, count_records } = attrs;
		const className = bv_fun_referentieniveau_bin == null ? 'onbekend' : `r${bv_fun_referentieniveau_bin}f`;
		const niveauLabel = bv_fun_referentieniveau_bin == null ? 'Onbekend' : getNiveauLabel(Number(bv_fun_referentieniveau_bin));
		const fraction = percOfParent('count_records')(path);
		return {
			...super.makeBar(attrs, path, context),
			className,
			tooltip: [
				{ label: 'Niveau', value: niveauLabel },
				{ label: '# lln', value: `${count_records}` },
				{ label: '% lln', value: formatPercent(fraction, 'nl_NL') },
			],
		};
	}

	createLinkData(
		path: Path<unknown, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): Partial<LinkData> {
		return {
			dashboard: `/details/leerling/basisvaardigheden/${vaardigheid2route(this.bv_nm_vaardigheid())}`,
			dataProvider: 'basisvaardigheden',
			...super.createLinkData(path, context),
		};
	}

	protected handleRedirect(
		path: Path<BasisvaardigheidA, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>,
		extraFilters: BasicFilterExpression<any>[]
	) {
		const linkData = this.createLinkData(path, context);
		const compoundFilter = linkData.filter as CompoundFilterExpression;
		const newFilter = new CompoundFilterExpression([...compoundFilter.filters, ...extraFilters]);
		this.urlService.navigate({ ...linkData, filter: newFilter });
	}

	protected readonly DashboardAspect = DashboardAspect;
}
