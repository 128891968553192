/* tslint:disable */
/* eslint-disable */
export enum EinduitstroomVsoUitzondering {
  INFORMATIE_VOLLEDIG = 'Informatie volledig',
  GEEN_VSO = 'Geen VSO',
  UITSTROOM_NIET_AF_TE_LEIDEN_UIT_SCHOOLSOORT_SV_B = 'Uitstroom niet af te leiden uit schoolsoort SvB',
  EXAMENSTATUS_NIET_CORRECT_IN_SYSTEEM = 'Examenstatus niet correct in systeem',
  UITSTROOM_NIET_NAAR_VERVOLGONDERWIJS_ARBEID = 'Uitstroom niet naar vervolgonderwijs/arbeid',
  ONBEKENDE_SCHOOLSOORT_SV_B_EN_UITSCHRIJVINGSREDEN = 'Onbekende schoolsoort SvB en uitschrijvingsreden'
}
