import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { isEmpty } from 'lodash-es';
import { Option } from '../utils/option';
import { MultiSelectCheckboxComponent } from '../multi-select-checkbox/multi-select-checkbox.component';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective, NgClickOutsideExcludeDirective } from 'ng-click-outside2';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-multi-select-dropdown',
	templateUrl: './multi-select-dropdown.component.html',
	styleUrls: ['./multi-select-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClickOutsideExcludeDirective, NgClickOutsideDirective, MultiSelectCheckboxComponent, NgClickOutsideDelayOutsideDirective],
})
export class MultiSelectDropdownComponent<T> implements OnChanges {
	@ViewChild('dropdown', { read: ElementRef }) dropdownRef!: ElementRef;

	@Input()
	placeholder?: string;

	@Input()
	allOptions: Option<T>[] = [];

	@Input()
	value: T[] = [];

	@Input()
	showSearchInput: boolean = false;

	@Input()
	showSearchInputInside: boolean = true;

	searchInput?: string;

	@Input()
	matchesSearch: (option: Option<T>, searchInput?: string) => boolean = (option, searchInput) =>
		!searchInput || includesIgnoreCaseAndDiacritics(option.text, searchInput);

	@Input()
	summaryAll?: string;

	@Output()
	valueChange = new EventEmitter<T[]>();

	@Input()
	enabled = true;

	public showDropdown = false;

	constructor(private changeDetector: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if ('allOptions' in changes) this.searchInput = '';
	}

	isEmpty() {
		return isEmpty(this.value);
	}

	getSelectedOptions(): Option<T>[] {
		return this.getAllOptions(true).filter((o) => this.value.includes(o.value));
	}

	setSelectedOptions(options: Option<T>[]): void {
		const value = options.map((o) => o.value);
		this.value = value;
		this.valueChange.emit(value);
		this.changeDetector.markForCheck();
	}

	getSummary(): string {
		if (this.summaryAll && this.value.length === this.allOptions.length) return this.summaryAll;
		return this.allOptions
			.filter((o) => this.value.includes(o.value))
			.map((o) => o.text)
			.join(', ');
	}

	closeDropDown(event: Event) {
		this.showDropdown = false;
		event.preventDefault();
		event.stopPropagation();
	}

	getAllOptions(ignoreSearch?: boolean) {
		if (ignoreSearch) return this.allOptions;
		return this.allOptions.filter((option) => this.matchesSearch(option, this.searchInput));
	}
}
