import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '@cumlaude/shared-services';
import { isUndefined } from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class SharedMatcher {
	constructor(
		private restService: RestService,
		private router: Router
	) {}

	canMatch(_route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
		if (segments.length < 2 || segments[0].path !== 'shared') return of(true);

		return this.restService.getGedeeldePaginaUrl(segments[1].path).pipe(
			map((gedeeldePaginaUrl) => {
				if (isUndefined(gedeeldePaginaUrl)) return this.router.createUrlTree(['shared']);

				return this.router.createUrlTree([gedeeldePaginaUrl.state]);
			})
		);
	}
}
