@if (elementen.length == 0) {
	<ul class="elementen no-elementen">
		<li class="element">
			<div class="element__text">
				<span class="secondary">Geen resultaten gevonden</span>
			</div>
		</li>
	</ul>
}
<cdk-virtual-scroll-viewport #scroller [itemSize]="56">
	<ul class="elementen">
		<li *cdkVirtualFor="let element of elementen" class="element" [class.selected]="isSelected(element)" (click)="setSelected(element)">
			<app-pasfoto [pasfoto]="getData(element).pasfoto" [alt]="getData(element).alt" size="size-2"></app-pasfoto>
			<div class="element__text">
				<span class="primary">{{ getData(element).title }}</span>
				<br />
				<span class="secondary">{{ getData(element).subTitle }}</span>
			</div>
		</li>
	</ul>
</cdk-virtual-scroll-viewport>
