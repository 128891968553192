<div class="card-cell-header">
	@if (jaren_onvoldoende_class) {
		<div [class]="jaren_onvoldoende_class"></div>
	}
	<span class="card-cell-title">{{ title }}</span>
</div>
@if (rows.length > 0) {
	<div [class.card-cell-column]="variant === 'Actueel'" [class.card-cell-row]="variant === 'Historie'">
		@for (row of rows; track row) {
			<div [appTooltip]="row.actueelRow?.tooltip">
				<ng-container
					[ngTemplateOutlet]="template"
					[ngTemplateOutletContext]="{
						cellRow: row
					}"
				></ng-container>
			</div>
		}
	</div>
} @else {
	@switch (title) {
		@case ('Examencijfers') {
			<span>Geen examencijfers beschikbaar</span>
		}
		@default {
			<span>Geen score beschikbaar</span>
		}
	}
}
