import { Component, Input, OnInit } from '@angular/core';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { FilterService } from '../services/filter.service';
import { DRIE_SCHOOLJAREN_EXCL_2019, DRIE_SCHOOLJAREN_EXCL_2020, isPeiljaarMetExclusief } from '@cumlaude/shared-utils';
import { QueryParamStateService } from '../services/query-param-state.service';
import { memoize } from 'lodash-es';
import { delay, Subscription } from 'rxjs';
import { FilterName } from '../services/filter-config';

export const indicatorOverOpties: { [key: number]: string } = {
	1: '1 schooljaar',
	2: '2 schooljaren',
	3: '3 schooljaren',
	3.1: '3 schooljaren excl. coronajaar',
	3.2: '3 schooljaren excl. coronajaar',
};

@Component({
	selector: 'app-schooljaar-indicator-over',
	templateUrl: './schooljaar-indicator-over.component.html',
	styleUrls: ['./schooljaar-indicator-over.component.scss'],
	standalone: true,
	imports: [FormDropdownComponent],
})
export class SchooljaarIndicatorOverComponent implements OnInit {
	@Input()
	hideExclusief: number = DRIE_SCHOOLJAREN_EXCL_2019;

	metExclusief: boolean = true;

	aantalJaren!: number;

	@Input()
	schooljaarFilterName: FilterName = 'x_onderwijsresultaten_ds_schooljaar';

	jaarOptions = [1, 2, 3, DRIE_SCHOOLJAREN_EXCL_2020, DRIE_SCHOOLJAREN_EXCL_2019].map(
		(value) => new Option<number>(value, indicatorOverOpties[value])
	);

	protected subscriptions: Subscription[] = [];

	constructor(
		private filterService: FilterService,
		private qp: QueryParamStateService
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.filterService
				.observe(this.schooljaarFilterName)
				.pipe(delay(0)) // Wacht tot een eventuele switch van equivalent schooljaar-filter heeft plaatsgevonden
				.subscribe(([schooljaar, aantalJaren]) => this.applySettings(aantalJaren, schooljaar))
		);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	/**
	 * Werkt this.aantalJaren bij en pas eventueel de queryparameter aan als een ongeldige waarde is geselecteerd.
	 * Uitgangspunt: als de huidige keuze de defaultkeuze is (gegeven het schooljaar), dan wordt de queryparameter gewist.
	 * - 3.2 (excl 2019) is nooit de default, want deze moet in de queryparameter staan omdat het schooljaarfilter deze nodig heeft om te filteren.
	 * - 3.1 (excl 2020) is (mits zichtbaar) altijd de default, dus die zou je nooit als queryparameter mogen zien.
	 * - 3 is de default als een schooljaar is gekozen waarvoor het coronajaar niet relevant is.
	 * - 1 en 2 zijn nooit de default.
	 * Als de defaultkeuze actief is en de gebruiker switcht naar een ander schooljaar waardoor het coronajaar wel of niet relevant wordt,
	 * dan wordt automatisch geswitcht naar wel en niet excl. coronajaar.
	 * NB als de queryparameter leeg is, dan krijgen we hier altijd 3.1 (excl. 2020) binnen.
	 */
	private applySettings(aantalJaren: number, schooljaar: string): void {
		this.metExclusief = isPeiljaarMetExclusief(schooljaar);
		if (this.metExclusief && aantalJaren === this.hideExclusief && aantalJaren === DRIE_SCHOOLJAREN_EXCL_2020) {
			// Onderwijspositie-dashboard: switch naar juiste coronajaar
			this.qp.dispatch('indicator-over', DRIE_SCHOOLJAREN_EXCL_2019, { replaceUrl: true });
		} else if (
			(!this.metExclusief && aantalJaren === DRIE_SCHOOLJAREN_EXCL_2019) ||
			(this.metExclusief && aantalJaren === this.hideExclusief && aantalJaren === DRIE_SCHOOLJAREN_EXCL_2019)
		) {
			// De default is gekozen: verwijder de queryparameter
			this.qp.dispatch('indicator-over', undefined, { replaceUrl: true });
		} else {
			// De queryparameter is correct: toon de juiste waarde in de dropdown
			this.aantalJaren = this.metExclusief || aantalJaren <= 3 ? aantalJaren : 3;
		}
	}

	getSchooljaarOptions = memoize(this._getSchooljaarOptions, (h, m) => JSON.stringify([h, m]));

	private _getSchooljaarOptions(hideExclusief: number, metExclusief: boolean) {
		return this.jaarOptions
			.filter((value) => metExclusief || ![DRIE_SCHOOLJAREN_EXCL_2019, DRIE_SCHOOLJAREN_EXCL_2020].includes(value.value))
			.filter((value) => value.value !== hideExclusief);
	}

	getSelectedJaarOption(): Option<number> {
		return this.jaarOptions.find(({ value }) => value === this.aantalJaren)!;
	}

	selectJaarOption(optie: number) {
		// Als de default is gekozen: verwijder de queryparameter
		if ((!this.metExclusief && optie === 3) || (this.metExclusief && optie > 3 && optie !== this.hideExclusief))
			this.qp.dispatch('indicator-over', undefined);
		else this.qp.dispatch('indicator-over', optie);
	}
}
