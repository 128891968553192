import { ReferentieNiveau } from './label-enums';
import { capitalize, range } from 'lodash-es';
import { Vaardigheid } from '@cumlaude/metadata';

export const vaardigheden: Vaardigheid[] = Object.values(Vaardigheid);

export function vaardigheid2route(vaardigheid: Vaardigheid): string {
	return vaardigheid.toLowerCase();
}

export function route2vaardigheid(route: string): Vaardigheid {
	return capitalize(route) as Vaardigheid;
}

export function getNiveaus(vaardigheid: Vaardigheid) {
	const hoogsteNiveau = vaardigheid === 'Rekenen' ? 3 : 4;
	return range(0, hoogsteNiveau + 1);
}

export function getNiveauLabel(nr_referentieniveau: number | null) {
	if (nr_referentieniveau === null) return 'Onbekend';
	return [ReferentieNiveau.R0F, ReferentieNiveau.R1F, ReferentieNiveau.R2F, ReferentieNiveau.R3F, ReferentieNiveau.R4F][nr_referentieniveau];
}
