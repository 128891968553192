import { Component, ContentChild, Input, Output } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { Observable, Subject } from 'rxjs';
import { ExportOptions, ExportService, ExportType } from '../../services/export.service';
import { switchMap } from 'rxjs/operators';
import { FactTable } from '../../services/exportable';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { LegendaComponent } from '../../legenda/legenda.component';
import { AsyncPipe } from '@angular/common';
import { SvgSpinnerComponent } from '../svg-spinner/svg-spinner.component';
import { TabMenuComponent } from '../tab-menu/tab-menu.component';

@Component({
	selector: 'app-dashboard-container',
	templateUrl: './dashboard-container.component.html',
	styleUrls: ['./dashboard-container.component.scss'],
	standalone: true,
	imports: [TabMenuComponent, SvgSpinnerComponent, AsyncPipe],
})
export class DashboardContainerComponent {
	sidebarOpened$: Observable<boolean>;
	startExport$ = new Subject<void>();
	showSpinner$: Observable<boolean>;

	@Input()
	factTable?: FactTable;

	@Input()
	shareVisible: boolean = true;

	@Input()
	exportTypes = Object.values(ExportType);

	@ContentChild(LegendaComponent)
	private legendaComponent?: LegendaComponent;

	/**
	 * emit wanneer de gebruiker de export-dialog met bevestigen heeft afgesloten
	 */
	@Output()
	doExport: Observable<ExportOptions>;

	constructor(
		private filterService: FilterService,
		exportService: ExportService,
		protected loadingService: LoadingService
	) {
		this.sidebarOpened$ = this.filterService.filterPanelOpened$;
		this.doExport = this.startExport$.pipe(
			switchMap(() => exportService.startExport(this.factTable!, this.exportTypes, this.legendaComponent?.options))
		);
		this.showSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.TABLE);
	}

	closeSidebar() {
		this.filterService.filterPanelOpened$.next(false);
	}
}
