import { AfterViewInit, Component } from '@angular/core';
import { delay } from 'rxjs/operators';
import { sumOver, weightedAverage } from '../../services/aggregation';
import { AttrPath, BasicFilterExpression, CompoundFilterExpression, DataService, ExportFilter, FilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { att } from '../../services/measures';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { getSchooljaarRangeString } from '@cumlaude/shared-utils';
import { CijferA, CijferI, CijfersOverzichtComponent } from '../cijfers-overzicht/cijfers-overzicht.component';
import { memoize } from 'lodash-es';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Path } from '../../services/data-tree';
import { createLinkData, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { UrlService } from '../../services/url.service';
import { DashboardVariant } from '../../services/weergave-opties';
import { ExamenStatus } from '@cumlaude/metadata';
import { indicatorOverOpties, SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { OnderwijsresultatenUitsluitenFilterComponent } from '../../onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { UserService } from '../../services/user.service';

const permanentTable: AttrPath[] = [['cf_fk_ilt', 'ilt_nm_niveau']];

// aantal CE-cijfers voor het betreffende schooljaar voor de betreffende bekostigingings-BRIN moet >= 30 zijn
const uitsluitenFilter = new BasicFilterExpression(['cf_fk_or_exccl', 'or_q_vakken'], 30, '>=');

const permanentFilters = [
	new BasicFilterExpression(['cf_is_diplomavak'], 1),
	// Combinatiecijfervakken HAVO/VWO niet tonen, beroepsgerichte vakken VMBO wel
	new CompoundFilterExpression(
		[
			new BasicFilterExpression(['cf_is_combinatiecijfervak'], 0),
			new BasicFilterExpression(['cf_fk_ilt', 'ilt_nm_niveau'], ['HAVO', 'VWO'], 'not in'),
		],
		'or'
	),
	new BasicFilterExpression(['cf_is_hoogste_ce'], 1),
	new BasicFilterExpression(['cf_map_examenstatus_met_prognose'], [ExamenStatus.GESLAAGD, ExamenStatus.AFGEWEZEN], 'in'),
	new CompoundFilterExpression(
		[new BasicFilterExpression(['cf_fk_vk', 'vk_co_vak'], 1107, '<>'), new BasicFilterExpression(['cf_nm_schooljaar'], '2015/2016', '<')],
		'or'
	),
	new BasicFilterExpression(['cf_nr_cijfer'], null, '<>'),
];

@Component({
	selector: 'app-examencijfers',
	templateUrl: './examencijfers.component.html',
	styleUrls: ['./examencijfers.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		SchooljaarIndicatorOverComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		BarchartTableComponent,
	],
})
export class ExamencijfersComponent extends CijfersOverzichtComponent implements AfterViewInit {
	table: AttrPath[] = permanentTable;

	defaultGroup: AttrPath = ['cf_fk_vk', 'vk_nm_kernvak'];

	fixedBeforeGroups = 1;

	flexibleMaxGroups = 1;

	flexibleGroupsRemovable = false;

	selectedGroup: AttrPath = this.defaultGroup;

	availableGroups: AttrPath[] = [
		['cf_fk_vk', 'vk_nm_kernvak'], //
		['cf_nm_vak'],
		['cf_fk_vk', 'vk_nm_vak'],
		['cf_nm_lesgroep_docenten'],
		['cf_fks_mw', 'mw_nm_medewerker'],
	];

	actueelFilters: FilterName[] = [
		'x_onderwijsresultaten_cf_schooljaar',
		'cf_fk_br_vest.br_co_brin',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nm_vak',
		'cf_nm_excijf_uitzondering',
	];

	filterExpressions?: FilterExpression[];

	variant!: DashboardVariant;

	schooljaar?: string;

	aantalJaren!: number;

	uitsluiten!: boolean;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected activatedRoute: ActivatedRoute,
		protected toastr: ToastrService,
		protected urlService: UrlService,
		userService: UserService
	) {
		super(dataService, filterService, qp, activatedRoute, toastr, urlService, userService);
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.selectedGroup = groups ? groups[0] : this.defaultGroup)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe('or-uitsluiten').subscribe((meetellen) => (this.uitsluiten = meetellen))
		);
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_cf_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	protected getFixedAfterGroups(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 0 : 1;
	}

	// memoize, otherwise new array keeps triggering change detection
	getGroups = memoize(ExamencijfersComponent._getGroups, (a, b) => JSON.stringify([a, b]));

	private static _getGroups(variant: DashboardVariant, selectedGroup: AttrPath): AttrPath[] {
		return variant === DashboardVariant.ACTUEEL ? [selectedGroup] : [selectedGroup, ['cf_nm_schooljaar']];
	}

	protected multiAggregators = [weightedAverage('cijfer', 'cf_nr_cijfer')];

	protected singleAggregators = {
		cf_nr_label_onvoldoendes: sumOver('cf_nr_label_onvoldoendes'),
	};

	createTooltip(_path: Path<CijferA, number[]>, _sizeLabel: string, _size: number): TooltipElement[] {
		return [];
	}

	createMeasureColumns(context: DashboardContext<CijferI, CijferA, ExamencijfersComponent>): ColumnDef<DataRow<CijferA>>[] {
		return [
			createMeasureColumn<CijferI, CijferA>('CE', att('cijfer'), { context, format: '1.2-2' }),
			createMeasureColumn<CijferI, CijferA>('Cijfers', att('count_records'), { context }),
		];
	}

	enrichTableModel(tableModel: TableModel<DataRow<CijferA>>) {
		tableModel.showFooters = true;
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getSchooljaarRangeString(this.schooljaar, this.aantalJaren) : '';
	}

	getAlternatingGroupLevel(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 1 : 2;
	}

	createLinkData(path: Path<CijferA, number[]>, context: DashboardContext<CijferI, CijferA, ExamencijfersComponent>): Partial<LinkData> {
		return { ...createLinkData<CijferI, CijferA>(path, context), dashboard: '/details/leerling/cijferlijst', dataProvider: 'cijfers' };
	}

	getPermanentExpressions = memoize(this._getPermanentExpressions, JSON.stringify);

	private _getPermanentExpressions(uitsluiten: boolean) {
		return uitsluiten ? [...permanentFilters, uitsluitenFilter] : permanentFilters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			{ label: 'Indicator over', value: indicatorOverOpties[this.aantalJaren] },
			{ label: 'Meetellende vestigingen', value: this.uitsluiten ? 'Minimaal 30 cijfers' : 'Alle' },
		];
	}
}
