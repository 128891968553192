<app-dashboard-container
	class="dashboard-instroom"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant"></app-dashboard-header>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[subgroups]="fixedSubGroups"
					[config]="this"
				></app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups)"
					[subgroups]="getHistorieSubgroups([groups, fixedSubGroups])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
					[partitionMeasure]="partitionMeasure"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
