<svg
	[attr.viewBox]="getViewBox()"
	[style.width]="data.widthPx + 10 | px"
	[style.height]="getHeight() | px"
	preserveAspectRatio="none"
	(click)="blockEvent($event)"
>
	<g>
		<rect [attr.x]="0" y="0" [attr.width]="16 + getNiveauWidth()" [attr.height]="getHeight()" class="bg niv0"></rect>
		<rect [attr.x]="getNiveauLeft(1)" y="0" [attr.width]="getNiveauWidth()" [attr.height]="getHeight()" class="bg niv1"></rect>
		<rect [attr.x]="getNiveauLeft(2)" y="0" [attr.width]="getNiveauWidth()" [attr.height]="getHeight()" class="bg niv2"></rect>
		@if (data.hoogsteNiveau === 3) {
			<rect [attr.x]="getNiveauLeft(3)" y="0" [attr.width]="16" [attr.height]="getHeight()" class="bg niv3"></rect>
		} @else {
			<rect [attr.x]="getNiveauLeft(3)" y="0" [attr.width]="getNiveauWidth()" [attr.height]="getHeight()" class="bg niv3"></rect>
			<rect [attr.x]="getNiveauLeft(4)" y="0" [attr.width]="16" [attr.height]="getHeight()" class="bg niv4"></rect>
		}
	</g>
	@if (data.darker) {
		<rect class="darkener" x="0" y="0" [attr.width]="data.widthPx" [attr.height]="getHeight()"></rect>
	}
	@for (niv of getNiveaus(); track niv) {
		<line [attr.x1]="getNiveauLeft(niv)" [attr.y1]="0" [attr.x2]="getNiveauLeft(niv)" [attr.y2]="getHeight()"></line>
	}
	@for (score of data.scores; track score) {
		@if (score.qty !== null) {
			<g class="score" [attr.transform]="getScoreTransform(score, $index)" [appTooltip]="score.tooltip" (click)="onScoreClick(score)">
				<text [attr.y]="-2" text-anchor="middle" class="score-label">{{ score.label }}</text>
				<circle [attr.cx]="0" [attr.cy]="5" r="4"></circle>
				<rect class="hover-target" x="-24" y="-12" width="48" height="24"></rect>
			</g>
		}
	}
</svg>
