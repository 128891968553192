<app-base-dialog [bottomBarTemplate]="bottomBar" [sizeClass]="'size-44'" [topBarTemplate]="topBar">
	<ng-template #topBar>
		<app-dialog-header [title]="'Help'"></app-dialog-header>
		@if (selections$ | async; as selections) {
			<app-dialog-selection-bar (selectionChange)="currentSelection.set($event)" [selection]="currentSelection()!" [selections]="selections">
			</app-dialog-selection-bar>
		}
	</ng-template>
	@switch (currentSelection()?.text) {
		@case ("Support") {
			<div class="content">
				Voor handleidingen en de technische toelichting van CumLaude verwijzen we je naar
				<a [href]="envConfig.zendeskLink" target="_blank">Zendesk</a>.
				<br />
				Ook kun je daar analysevragen en/of opmerkingen indienen.
				<br />
				Voor technische vragen kun je contact opnemen met de CumLaude-beheerder van je school.
				<br />
				De beheerder kan een ticket indienen via Zendesk of telefonisch contact opnemen met CumLaude Support via
				<b>{{ envConfig.supportPhone }}</b
				>.
			</div>
		}
		@case ("Details") {
			<div class="content">
				<div>Applicatie versie {{ latestVersion$ | async }}</div>
				<div>Data laatst ververst op {{ etlLaatsteSuccesvolleRunDateTime$ | async | date: "d-M-yyyy 'om' H:mm" }}</div>
				@if (versionService.succesvolETLMoreThan24$ | async) {
					<div class="warning">Let op! De laatste verversing is langer dan 24 uur geleden. De data is mogelijk niet actueel.</div>
				}
			</div>
		}
	}
	<ng-template #bottomBar>
		<app-button (clickit)="dialogRef.close()">Sluiten</app-button>
	</ng-template>
</app-base-dialog>
