import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ENV_CONFIG, ENV_CONFIG_URL, EnvConfiguration } from '@cumlaude/shared-configuration';
import { enableBugsnag } from '@cumlaude/bugsnag';

fetch(ENV_CONFIG_URL)
	.then((config) => config.json())
	.then((envConfig: EnvConfiguration) => {
		if (envConfig.production) {
			enableProdMode();
		}

		enableBugsnag(envConfig, 'User Frontend');

		platformBrowserDynamic([{ provide: ENV_CONFIG, useValue: envConfig }])
			.bootstrapModule(AppModule)
			.catch(console.error);
	});
