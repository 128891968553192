import { Component, Inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { DialogRef } from '@angular/cdk/dialog';
import { VersionService } from '../../../services/version.service';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DialogSelectionBarComponent, DialogHeaderComponent, BaseDialogComponent, DialogSelection } from '@cumlaude/shared-components-dialogs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-help-dialog',
	templateUrl: './help-dialog.component.html',
	styleUrls: ['./help-dialog.component.scss'],
	standalone: true,
	imports: [BaseDialogComponent, DialogHeaderComponent, DialogSelectionBarComponent, ButtonComponent, DatePipe, AsyncPipe],
})
export class HelpDialogComponent {
	currentSelection = signal<DialogSelection | undefined>(undefined);

	latestVersion$: Observable<string>;
	etlLaatsteSuccesvolleRunDateTime$: Observable<string | undefined>;
	selections$: Observable<DialogSelection[]>;

	constructor(
		protected versionService: VersionService,
		@Inject(ENV_CONFIG) protected readonly envConfig: EnvConfiguration,
		public dialogRef: DialogRef<void>
	) {
		this.latestVersion$ = versionService.latestVersion$;
		this.etlLaatsteSuccesvolleRunDateTime$ = versionService.restVersionInfo$.pipe(
			map((restVersionInfo) => restVersionInfo.etlLaatsteSuccesvolleRunDateTime)
		);
		this.selections$ = versionService.succesvolETLMoreThan24$.pipe(
			map((succesvolETLMoreThen24) => {
				const selections = [{ text: 'Support' }, { text: 'Details', ...(succesvolETLMoreThen24 ? { icon: 'svg-warning' } : {}) }];
				this.currentSelection.set(selections[0]);
				return selections;
			})
		);
	}
}
